import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import {
  CommandsManager,
  ExtensionManager,
  ServicesManager,
  HotkeysManager,
  UINotificationService,
  UIModalService,
  UIDialogService,
  LoggerService,
  Firebase,
} from '@platform/core';
import {
  SnackbarProvider,
  ModalProvider,
  DialogProvider,
  LoggerProvider,
  BaseModal,
} from '@platform/ui';
import i18n from '@platform/i18n';
/** Extensions */
import CornerstoneExtension from '@platform/extension-cornerstone';
import DicomRTExtension from '@platform/extension-dicom-rt';
import DicomTagBrowserExtension from '@platform/extension-dicom-tag-browser';
/** Platform */
import { Auth as FirebaseAuth } from './cloud/auth/FirebaseAuth';
import { AuthRouting as FirebaseAuthRouting } from './cloud/auth/FirebaseAuthRouting';
import Timer from './cloud/auth/Timer.js';
import { API, APIContext } from './context/APIContext.js';
/** Viewer */
import Routes from './routes/Routes';
/** Store */
import { getActiveContexts } from './store/selectors.js';
import store from './store';
/** Contexts */
import { AppProvider, useAppContext, CONTEXTS } from './context/AppContext';

/** Managers */
const commandsManagerConfig = {
  getAppState: () => store.getState(),
  getActiveContexts: () => getActiveContexts(store.getState()),
};
const commandsManager = new CommandsManager(commandsManagerConfig);
const servicesManager = new ServicesManager();
const hotkeysManager = new HotkeysManager(commandsManager, servicesManager);
let extensionManager;

function App({ config }) {
  const appConfig = initConfig(config);
  _initFirebase(appConfig);
  _initServices();
  _initExtensions(appConfig);
  _initHotkeys(appConfig.hotkeys);

  const Viewer = () => {
    const {
      UINotificationService,
      UIDialogService,
      UIModalService,
      LoggerService,
    } = servicesManager.services;
    return (
      <Provider store={store}>
        <AppProvider config={appConfig}>
          <I18nextProvider i18n={i18n}>
            <LoggerProvider service={LoggerService}>
              <SnackbarProvider service={UINotificationService}>
                <DialogProvider service={UIDialogService}>
                  <ModalProvider modal={BaseModal} service={UIModalService}>
                    <Routes />
                  </ModalProvider>
                </DialogProvider>
              </SnackbarProvider>
            </LoggerProvider>
          </I18nextProvider>
        </AppProvider>
      </Provider>
    );
  };

  /** Firebase: add authentication and platform routes */
  const isFirebaseAuthentication = appConfig.firebaseConfig.apiKey;
  if (isFirebaseAuthentication) {
    return (
      <Router basename={appConfig.routerBasename}>
        <FirebaseAuth config={appConfig.firebaseConfig}>
          {({
            firebaseUser: user,
            firebaseToken: token,
            firebaseTokenExpiredAt: tokenExpiredAt,
            isAuthStateChanged,
          }) => {
            return user && token && tokenExpiredAt ? (
              <APIContext.Provider value={new API()}>
                <Timer>{Viewer()}</Timer>
              </APIContext.Provider>
            ) : (
              <FirebaseAuthRouting isAuthStateChanged={isAuthStateChanged} />
            );
          }}
        </FirebaseAuth>
      </Router>
    );
  }

  return <Router basename={appConfig.routerBasename}>{Viewer()}</Router>;
}
App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};
App.defaultProps = { config: { routerBasename: '/' } };

function initConfig(config) {
  return typeof config === 'function' ? config({ servicesManager }) : config;
}

function _initFirebase(appConfig) {
  if (appConfig.firebaseConfig?.apiKey) Firebase.init(appConfig.firebaseConfig);
}

function _initServices() {
  const services = [
    UINotificationService,
    UIModalService,
    UIDialogService,
    LoggerService,
  ];
  servicesManager.registerServices(services);
}

function _initExtensions(appConfig) {
  const extensions = [
    { enabled: true, extension: CornerstoneExtension },
    { enabled: true, extension: DicomRTExtension },
    { enabled: true, extension: DicomTagBrowserExtension },
  ].reduce((extensions, option) => {
    return option.enabled ? [...extensions, option.extension] : extensions;
  }, []);
  extensionManager = new ExtensionManager({
    commandsManager,
    servicesManager,
    appConfig,
    api: { contexts: CONTEXTS, hooks: { useAppContext } },
  });
  extensionManager.registerExtensions(extensions);
}

function _initHotkeys(appConfigHotkeys) {
  /*
   * Must run after extension commands are registered
   * if there is no hotkeys from localStorage set up from config.
   */
  hotkeysManager.setHotkeys(appConfigHotkeys);
  hotkeysManager.setDefaultHotKeys(appConfigHotkeys);
}

export default App;
export { commandsManager, extensionManager, hotkeysManager, servicesManager };
