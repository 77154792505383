import getROIMeasurements from './getROIMeasurements';
import getROIContours from './getROIContours';

export class Timepoint {
  constructor(metadata) {
    this.ClinicalTrialTimePointID = metadata.ClinicalTrialTimePointID;
    this.PatientID = metadata.PatientID;
    this.PatientName = metadata.PatientName[0].Alphabetic;
    this.StudyInstanceUID = metadata.StudyInstanceUID;
    this.StudyDate = metadata.StudyDate;
    this.StudyDescription = metadata.StudyDescription;
    this.SeriesInstanceUID = metadata.SeriesInstanceUID;
    /** define series date */
    this.SeriesDate =
      metadata.SeriesDate ||
      metadata.ContentDate ||
      metadata.AcquisitionDate ||
      '';
    this.SeriesDescription = metadata.SeriesDescription;
    this.Modality = metadata.Modality;
    this.SliceThickness = metadata.SliceThickness;
    this.SpacingBetweenSlices = metadata.SpacingBetweenSlices;
    this.Columns = metadata.Columns;
    this.Rows = metadata.Rows;
  }
}

export default function getTimepoint(instances, rtstruct) {
  const timepoint = new Timepoint(instances[0]);
  const rois = getROIContours(rtstruct);
  timepoint.ROIMeasurements = getROIMeasurements(instances, rois);
  return timepoint;
}
