import getDistance3D from '../utils/math/getDistance3D';

function getVolumeFromSlices(slices) {
  const _slices = [...slices];
  _slices.sort((a, b) => a.InstanceNumber - b.InstanceNumber);
  return _slices.reduce((acc, cur, idx, slices) => {
    if (
      cur.ImagePositionPatient &&
      cur.ImagePositionPatient[0] &&
      cur.ImagePositionPatient[1] &&
      cur.ImagePositionPatient[2]
    ) {
      const prevPoint = slices[Math.max(0, idx - 1)].ImagePositionPatient;
      const nextPoint =
        slices[Math.min(idx + 1, slices.length - 1)].ImagePositionPatient;
      const spaceBetweenSlices =
        getDistance3D(
          { x: prevPoint[0], y: prevPoint[1], z: prevPoint[2] },
          { x: nextPoint[0], y: nextPoint[1], z: nextPoint[2] }
        ) / 2;
      const area = cur.area || 0;
      return acc + area * spaceBetweenSlices;
    } else if (cur.SliceThickness) {
      /** slice thickness */
      const area = cur.area || 0;
      const thickness = cur.SliceThickness || 0;
      return acc + area * thickness;
    } else {
      /** error case */
      return acc + 0;
    }
  }, 0);
}

export default getVolumeFromSlices;
