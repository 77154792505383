import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cornerstone from 'cornerstone-core';

import { Grid, Input, Select, P, Range } from '@platform/ui';
import { getEnabledElement } from '../state';

/** reference: https://radiopaedia.org/articles/windowing-ct */
const presetOptions = [
  { name: '------', width: '------', center: '------' },
  { name: 'Brain', width: 80, center: 40 },
  { name: 'Subdural', width: 300, center: 100 },
  { name: 'Stroke', width: 40, center: 40 },
  { name: 'Temporal Bones', width: 2800, center: 600 },
  { name: 'Soft Tissues', width: 400, center: 60 },
  { name: 'Lungs', width: 1500, center: -600 },
  { name: 'Liver', width: 150, center: 30 },
  { name: 'Bone', width: 1800, center: 400 },
];

const setViewport = (
  index,
  { scale, rotation, x, y, windowWidth, windowCenter }
) => {
  const enabledElement = getEnabledElement(index);

  if (enabledElement) {
    try {
      let viewport = cornerstone.getViewport(enabledElement);
      viewport.scale = Number(scale) || viewport.scale;
      viewport.rotation = Number(rotation) || viewport.rotation;
      viewport.translation = {
        x: Number(x) || viewport.translation.x,
        y: Number(y) || viewport.translation.y,
      };
      viewport.voi = {
        windowWidth: Number(windowWidth) || viewport.voi.windowWidth,
        windowCenter: Number(windowCenter) || viewport.voi.windowCenter,
      };
      cornerstone.setViewport(enabledElement, viewport);
    } catch (err) {
      return;
    }
  }
};

const setViewports = (
  viewports,
  { scale, rotation, x, y, windowWidth, windowCenter }
) => {
  Object.keys(viewports).forEach(index => {
    setViewport(index, { scale, rotation, x, y, windowWidth, windowCenter });
  });
};

const refreshViewports = () => {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
};

const setOpacity = (index, opacity) => {
  const enabledElement = getEnabledElement(index);
  const layers = cornerstone.getLayers(enabledElement);
  const petLayer = layers.find(l => l.options.name === 'PET');
  if (petLayer) {
    petLayer.options.opacity = opacity;
    refreshViewports();
  }
};

const setPetWindowing = (index, { windowWidth, windowCenter }) => {
  const enabledElement = getEnabledElement(index);
  const layers = cornerstone.getLayers(enabledElement);
  const petLayer = layers.find(l => l.options.name === 'PET');
  if (petLayer) {
    petLayer.viewport.voi = {
      windowWidth: Number(windowWidth) || petLayer.viewport.voi.windowWidth,
      windowCenter: Number(windowCenter) || petLayer.viewport.voi.windowCenter,
    };
    refreshViewports();
  }
};

export const ViewportController = ({ viewports, activeIndex }) => {
  const [scale, setScale] = useState('');
  const [allScale, setAllScale] = useState('');
  const [windowWidth, setWindowWidth] = useState('');
  const [allWindowWidth, setAllWindowWidth] = useState('');
  const [petWindowWidth, setPetWindowWidth] = useState('');
  const [windowCenter, setWindowCenter] = useState('');
  const [allWindowCenter, setAllWindowCenter] = useState('');
  const [petWindowCenter, setPetWindowCenter] = useState('');
  const [optionIndex, setOptionIndex] = useState(0);
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);
  const [layerOpacity, setLayerOpacity] = useState(0);
  const defaultEnabledElement = cornerstone.getEnabledElements()[activeIndex];
  const presetDefault = {
    width: defaultEnabledElement?.viewport?.voi.windowWidth || 0,
    center: defaultEnabledElement?.viewport?.voi.windowCenter || 0,
  };

  useEffect(() => {
    const defaultEnabledElement = cornerstone.getEnabledElements()[activeIndex];
    if (defaultEnabledElement?.element) {
      const layers = cornerstone.getLayers(defaultEnabledElement.element);
      const petLayer = layers.find(l => l.options.name === 'PET');
      if (petLayer) {
        setLayerOpacity(petLayer.options?.opacity || 1);
        setPetWindowWidth(petLayer.viewport?.voi.windowWidth);
        setPetWindowCenter(petLayer.viewport?.voi.windowCenter);
      }
    }
  }, [activeIndex]);

  return (
    <div style={{ margin: '20px 10px 0 0' }}>
      <div style={{ margin: '20px 0' }}>
        <P style={{ color: 'var(--default-color)' }}>Viewports:</P>
        <div style={{ margin: '5px 0' }}>
          <Input
            data-cy="viewports-scale"
            value={allScale}
            placeholder={'Scale'}
            onChange={evt => {
              setViewports(viewports, { scale: evt.target.value });
              setScale(evt.target.value);
              setAllScale(evt.target.value);
            }}
          />
        </div>
        <div style={{ margin: '5px 0' }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                data-cy="viewports-window-width"
                value={allWindowWidth}
                placeholder={'Window Width'}
                onChange={evt => {
                  setViewports(viewports, { windowWidth: evt.target.value });
                  setWindowWidth(evt.target.value);
                  setAllWindowWidth(evt.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                data-cy="viewports-window-level"
                value={allWindowCenter}
                placeholder={'Window Level'}
                onChange={evt => {
                  setViewports(viewports, { windowCenter: evt.target.value });
                  setWindowCenter(evt.target.value);
                  setAllWindowCenter(evt.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: '5px 0' }}>
          <Select
            value={optionIndex}
            onChange={evt => {
              const width =
                evt.target.value === 0
                  ? presetDefault.width
                  : presetOptions[evt.target.value].width;
              const center =
                evt.target.value === 0
                  ? presetDefault.center
                  : presetOptions[evt.target.value].center;
              Object.keys(viewports).forEach(index => {
                setViewport(index, {
                  windowWidth: width,
                  windowCenter: center,
                });
              });
              setWindowWidth(width);
              setWindowCenter(center);
              setAllWindowWidth(width);
              setAllWindowCenter(center);
              setOptionIndex(evt.target.value);
              setActiveOptionIndex(evt.target.value);
            }}
          >
            {presetOptions.map((option, index) => (
              <option
                key={`${option.name}-${option.width}-${option.center}`}
                value={index}
              >{`${option.name} W:${option.width} / L:${option.center}`}</option>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ margin: '20px 0' }}>
        <P style={{ color: 'var(--default-color)' }}>Active Viewport:</P>
        <div style={{ margin: '5px 0' }}>
          <Input
            data-cy="active-viewport-scale"
            value={scale}
            placeholder={'Scale'}
            onChange={evt => {
              setViewport(activeIndex, { scale: evt.target.value });
              setScale(evt.target.value);
            }}
          />
        </div>
        <div style={{ margin: '5px 0' }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                data-cy="active-viewport-window-width"
                value={windowWidth}
                placeholder={'Window Width'}
                onChange={evt => {
                  setViewport(activeIndex, { windowWidth: evt.target.value });
                  setWindowWidth(evt.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                data-cy="active-viewport-window-level"
                value={windowCenter}
                placeholder={'Window Level'}
                onChange={evt => {
                  setViewport(activeIndex, { windowCenter: evt.target.value });
                  setWindowCenter(evt.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: '5px 0' }}>
          <Select
            value={activeOptionIndex}
            onChange={evt => {
              const width =
                evt.target.value === 0
                  ? presetDefault.width
                  : presetOptions[evt.target.value].width;
              const center =
                evt.target.value === 0
                  ? presetDefault.center
                  : presetOptions[evt.target.value].center;
              setViewport(activeIndex, {
                windowWidth: width,
                windowCenter: center,
              });
              setWindowWidth(width);
              setWindowCenter(center);
              setActiveOptionIndex(evt.target.value);
            }}
          >
            {presetOptions.map((option, index) => (
              <option
                key={`${option.name}-${option.width}-${option.center}`}
                value={index}
              >{`${option.name} W:${option.width} / L:${option.center}`}</option>
            ))}
          </Select>
        </div>
      </div>
      {viewports?.[activeIndex]?.isFusion && (
        <>
          <div style={{ margin: '20px 0' }}>
            <P style={{ color: 'var(--default-color)' }}>
              (PET-CT) Opacity {layerOpacity}:
            </P>
            <div style={{ margin: '20px 0 0' }}>
              <Range
                step={1}
                min={0}
                max={100}
                value={layerOpacity * 100}
                onChange={(evt) => {
                  setOpacity(activeIndex, evt.target.value / 100);
                  setLayerOpacity(evt.target.value / 100);
                  evt.target.blur();
                }}
              />
            </div>
          </div>
          <div style={{ margin: '5px 0' }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Input
                  data-cy='pet-layer-window-width'
                  value={petWindowWidth}
                  placeholder={'Window Width'}
                  onChange={(evt) => {
                    setPetWindowing(activeIndex, { windowWidth: evt.target.value });
                    setPetWindowWidth(evt.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  data-cy='pet-layer-window-level'
                  value={petWindowCenter}
                  placeholder={'Window Level'}
                  onChange={(evt) => {
                    setPetWindowing(activeIndex, { windowCenter: evt.target.value });
                    setPetWindowCenter(evt.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
ViewportController.propTypes = {
  viewports: PropTypes.object,
  activeIndex: PropTypes.number,
};
