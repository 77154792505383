import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import TOOL_NAMES from './constants/toolNames';
import { math } from '../modules/dicom-measurement/src';
const { pointInPolygon } = math;

const BaseTool = importInternal('base/BaseTool');

/**
 * @extends cornerstoneTools.BaseTool
 */
export default class SelectTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      supportedInteractionTypes: ['Mouse'],
      mixins: ['renderBrushMixin'],
      configuration: {},
      name: TOOL_NAMES.SELECT_TOOL,
    };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps);
    this._module = cornerstoneTools.getModule('rtstruct-edit');
  }

  preMouseDownCallback(evt) {
    const { image, currentPoints } = evt.detail;
    const painter = this._module.getters.peekPainter(image.imageId);
    const { data } = painter.getState();
    const selected = [];
    for (const d of data) {
      const { points } = d.handles;
      const polygon = points.map((v) => [v.x, v.y]);
      const p = currentPoints.image;
      if (pointInPolygon(polygon, [p.x, p.y])) {
        selected.push(d);
      }
    }
    if (selected.length > 0) {
      const uid = selected[0].structureSetSeriesInstanceUid;
      const number = selected[0].ROINumber;
      this._module.setters.selectedROIContour(uid, number);
      this._module.setters.highlights([
        { SeriesInstanceUID: uid, ROINumber: number },
      ]);
      const event = new CustomEvent('refresh_selected_roi', {});
      this._module.dispatchEvent(event);
    }
  }
}
