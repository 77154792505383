import { MEASUREMENT_TECHNIQUE, TARGET_RESPONSE } from './guideline';

function a(num) {
  return Number(num) > 0 ? `+${num}` : num;
}

function getTargetResponses(targetStats, criteria) {
  return targetStats.map((stat) => getTargetResponse(stat, criteria));
}

/**
 * @param {String} criteria.measurementTechnique
 * @param {Number} criteria.minDiameterIncrease
 * @param {Number} criteria.minDiameterDecrease
 * @param {Number} criteria.minVolumeIncrease
 * @param {Number} criteria.minVolumeDecrease
 */
function getTargetResponse(stat, criteria) {
  const measurementTechnique =
    criteria?.measurementTechnique || MEASUREMENT_TECHNIQUE.BIDIMENSIONAL;
  const keyMap = {
    [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: {
      sum: 'sumDiameter',
      change: 'sumDiameter',
      increase: 'minDiameterIncrease',
      decrease: 'minDiameterDecrease',
    },
    [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: {
      sum: 'sumVolume',
      change: 'sumVolume',
      increase: 'minVolumeIncrease',
      decrease: 'minVolumeDecrease',
    },
  };
  const key = keyMap[measurementTechnique];
  const minIncrease = criteria[key.increase] * 100;
  const minDecrease = criteria[key.decrease] * 100;
  const changeFromBaseline = stat.fromBaseline[key.change];
  const changeFromNadir = stat.fromNadir[key.change];
  const existAbsoluteDiameterChange =
    stat.fromNadir.existAbsoluteDiameterChange;
  const existAbsoulteSODChange =
    stat.fromNadir.existAbsoluteSumOfDiameterChange;

  let result;
  let message;
  const noChange =
    changeFromNadir < minIncrease
      ? ''
      : criteria.absoluteSumOfDiameterChange && !existAbsoulteSODChange
      ? `, sum of change in target lesions is not greater than ${criteria['absoluteSumOfDiameterChange']}mm from ${stat.fromNadir.baseline_timepoint_id} to ${stat.fromNadir.timepoint_id}`
      : criteria.absoluteDiameterChange && !existAbsoluteDiameterChange
      ? `, no change in target lesion greater than ${criteria['absoluteDiameterChange']}mm from timepoint ${stat.fromNadir.baseline_timepoint_id} to ${stat.fromNadir.timepoint_id}`
      : '';

  if (
    changeFromNadir >= minIncrease &&
    existAbsoluteDiameterChange &&
    existAbsoulteSODChange
  ) {
    /** progressive disease */
    result = TARGET_RESPONSE.PROGRESSIVE_DISEASE;
    message = `Target Lesion(PD): ${a(changeFromNadir)}% change from timepoint ${stat.fromNadir.baseline_timepoint_id} to ${stat.fromNadir.timepoint_id}`;
  } else if (changeFromBaseline === -100) {
    /** complete response */
    result = TARGET_RESPONSE.COMPLETE_RESPONSE;
    message = `Target Lesion(CR): ${a(changeFromBaseline)}% change from timepoint ${stat.fromBaseline.baseline_timepoint_id} to ${stat.fromBaseline.timepoint_id}`;
  } else if (changeFromBaseline <= -minDecrease) {
    /** partial response */
    result = TARGET_RESPONSE.PARTIAL_RESPONSE;
    message = `Target Lesion(PR): ${a(changeFromBaseline)}% change from timepoint ${stat.fromBaseline.baseline_timepoint_id} to ${stat.fromBaseline.timepoint_id}${noChange}`;
  } else {
    /** stable disease */
    result = TARGET_RESPONSE.STABLE_DISEASE;
    message = `Target Lesion(SD): ${a(changeFromBaseline)}% change from timepoint ${stat.fromBaseline.baseline_timepoint_id} to ${stat.fromBaseline.timepoint_id}${noChange}`;
  }

  const sum = stat.summation[key.sum];
  const { timepoint_id, baseline_timepoint_id } = stat.fromBaseline;
  if (timepoint_id !== baseline_timepoint_id && sum === 0) {
    /** complete response */
    result = TARGET_RESPONSE.COMPLETE_RESPONSE;
    message = `Target Lesion(CR): no lesion found at timepoint ${stat.fromBaseline.timepoint_id}`;
  }

  if (stat.existLesions && !stat.existROIs) {
    /** complete response */
    result = TARGET_RESPONSE.COMPLETE_RESPONSE;
    message = `Target Lesion(CR): no lesion found at timepoint ${stat.fromBaseline.timepoint_id}`;
  }

  if (!stat.existLesions) {
    /** no lesion */
    result = TARGET_RESPONSE.NONE;
    message = `Target Lesion(NED): no target lesion selected`;
  }

  return {
    fromBaseline: changeFromBaseline,
    fromNadir: changeFromNadir,
    result: result,
    message: message,
  };
}

export { getTargetResponses, getTargetResponse };
