import init from './modules/init';
import sopClassHandlerModule from './modules/sopClassHandlerModule/DicomRTStructSopClassHandler';
import { getPanelModule } from './modules/panelModule/getPanelModule';
import { getCommandsModule } from './modules/commandsModule/getCommandsModule';
import toolbarModule from './modules/toolbarModule/toolbarModule';
import dicomMeasurementModule from './modules/dicom-measurement/src/index';

export default {
  id: 'dicom-rt',
  /**
   * @param {object} [configuration={}]
   * @param {object|array} [configuration.csToolsConfig] - Passed directly to `initCornerstoneTools`
   */
  preRegistration({ servicesManager, configuration = {} }) {
    init({ servicesManager, configuration });
  },
  getSopClassHandlerModule() {
    return sopClassHandlerModule;
  },
  getToolbarModule({ commandsManager }) {
    return toolbarModule({ commandsManager });
  },
  getCommandsModule,
  getPanelModule,
  getMeasurementModule(){
    return dicomMeasurementModule;
  }
};
