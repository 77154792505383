const guideline = {
  guidelineId: 'RECIST11',
  guidelineName: 'RECIST 1.1',
  measurementTechnique: 'BIDIMENSIONAL',
  maxNumberOfTargets: 5,
  maxNumberOfTargetsPerOrgan: 2,
  measurableLongAxis: 10,
  measurableShortAxis: 0,
  measurableVolume: 0,
  measurableLongAxisOfLymphNode: 15,
  measurableShortAxisOfLymphNode: 15,
  measurableVolumeOfLymphNode: 0,
  measurableLongAxisOfNewLesion: 5,
  measurableShortAxisOfNewLesion: 0,
  measurableVolumeOfNewLesion: 0,
  minDiameterIncrease: 0.2,
  minDiameterDecrease: 0.3,
  minVolumeIncrease: 0.65,
  minVolumeDecrease: 0.65,
  smallDiameterChange: 0,
  smallVolumeChange: 0,
  absoluteDiameterChange: 0,
  absoluteSumOfDiameterChange: 5,
  absoluteVolumeChange: 0,
  absoluteSumOfVolumeChange: 0,
};

export default guideline;
