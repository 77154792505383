import {
  TARGET_RESPONSE,
  NON_TARGET_RESPONSE,
  NEW_LESION_RESPONSE,
  OVERALL_RESPONSE,
  STEROIDS_USE,
  CLINICAL_STATUS,
} from './guideline';

/**
 * @param {String} targetResponse.result
 * @param {String} nonTargetResponse.result
 * @param {String} newLesionResponse.result
 * @param {String} steroidsUse.result
 * @param {String} clinicalStatus.result
 */
function getOverallResponse({
  targetResponse,
  nonTargetResponse,
  newLesionResponse,
  steroidsUse,
  clinicalStatus,
}) {
  /** progressive disease */
  if (targetResponse.result === TARGET_RESPONSE.PROGRESSIVE_DISEASE) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: targetResponse.message || '',
    };
  }
  if (nonTargetResponse.result === NON_TARGET_RESPONSE.PROGRESSIVE_DISEASE) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: nonTargetResponse.message || '',
    };
  }
  if (newLesionResponse.result === NEW_LESION_RESPONSE.PRESENT) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: newLesionResponse.message || '',
    };
  }
  if (steroidsUse.result === STEROIDS_USE.INCREASED) {
    return {
      result: OVERALL_RESPONSE.NONE,
      message: '',
    };
  }
  if (clinicalStatus.result === CLINICAL_STATUS.WORSE) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: clinicalStatus.message || '',
    };
  }

  /** complete response */
  if (
    targetResponse.result === TARGET_RESPONSE.COMPLETE_RESPONSE &&
    (nonTargetResponse.result === NON_TARGET_RESPONSE.COMPLETE_RESPONSE ||
      nonTargetResponse.result === NON_TARGET_RESPONSE.NONE)
  ) {
    return {
      result: OVERALL_RESPONSE.COMPLETE_RESPONSE,
      message: targetResponse.message || '',
    };
  }
  if (
    targetResponse.result === TARGET_RESPONSE.NONE &&
    nonTargetResponse.result === NON_TARGET_RESPONSE.COMPLETE_RESPONSE
  ) {
    return {
      result: OVERALL_RESPONSE.COMPLETE_RESPONSE,
      message: nonTargetResponse.message || '',
    };
  }

  /** partial response */
  if (
    targetResponse.result === TARGET_RESPONSE.COMPLETE_RESPONSE ||
    targetResponse.result === TARGET_RESPONSE.PARTIAL_RESPONSE
  ) {
    return {
      result: OVERALL_RESPONSE.PARTIAL_RESPONSE,
      message: targetResponse.message || '',
    };
  }

  /** stable disease */
  if (targetResponse.result === TARGET_RESPONSE.STABLE_DISEASE) {
    return {
      result: OVERALL_RESPONSE.STABLE_DISEASE,
      message: targetResponse.message || '',
    };
  }
  if (
    targetResponse.result === TARGET_RESPONSE.NONE &&
    nonTargetResponse.result === NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE
  ) {
    return {
      result: OVERALL_RESPONSE.STABLE_DISEASE,
      message: nonTargetResponse.message || '',
    };
  }

  /** not evaluable */
  if (
    targetResponse.result === TARGET_RESPONSE.NOT_EVALUABLE ||
    nonTargetResponse.result === NON_TARGET_RESPONSE.NOT_EVALUABLE
  ) {
    return {
      result: OVERALL_RESPONSE.NOT_EVALUABLE,
      message: '',
    };
  }

  /** none response */
  return {
    result: OVERALL_RESPONSE.NONE,
    message: '',
  };
}

export default getOverallResponse;
