import { NEW_LESION_RESPONSE } from './guideline';

function confirmNewLesionsOfTimepoints(timepoints, new_ids) {
  new_ids.forEach((id) => {
    let found = false;
    timepoints.forEach((timepoint, index) => {
      const isBaseline = index === 0;
      if (isBaseline) return;
      const lesion =
        timepoint.Lesions.find((lesion) => lesion.link_id === id) || {};
      if (lesion.exist) found = true;
      lesion.found = found;
    });
  });
}

function getNewLesionResponses(timepoints, new_ids) {
  confirmNewLesionsOfTimepoints(timepoints, new_ids);
  return timepoints.map((timepoint) =>
    getNewLesionResponse(timepoint, new_ids)
  );
}

function getNewLesionResponse(timepoint, new_ids) {
  const newLesions = timepoint.Lesions.filter((l) =>
    new_ids.includes(l.link_id)
  );
  const foundLesions = newLesions.filter((lesion) => lesion.found);
  const existLesions = newLesions.filter((lesion) => lesion.exist);
  const confirmedLesions = existLesions.filter((lesion) => lesion.unequivocal);
  const unconfirmedLesions = existLesions.filter(
    (lesion) => !lesion.unequivocal
  );

  let result;
  let message;
  const id = timepoint.id || timepoint.timepoint_id || '';
  if (confirmedLesions.length > 0) {
    const lesionNames = confirmedLesions
      .map((lesion) => lesion.name)
      .join(', ');
    result = NEW_LESION_RESPONSE.PRESENT;
    message = `New Lesion(Unequivocal): ${lesionNames} found at timepoint ${id}`;
  } else if (unconfirmedLesions.length > 0) {
    const lesionNames = unconfirmedLesions
      .map((lesion) => lesion.name)
      .join(', ');
    result = NEW_LESION_RESPONSE.UNCONFIRMED;
    message = `New Lesion(Equivocal): ${lesionNames} found at timepoint ${id}`;
  } else if (foundLesions.length > 0) {
    result = NEW_LESION_RESPONSE.ABSENT;
    message = `New Lesion(Absent): no lesion found at timepoint ${id}`;
  } else {
    result = NEW_LESION_RESPONSE.NONE;
    message = `New Lesion(NED): no lesion found`;
  }
  return {
    lesions: confirmedLesions.map((lesion) => lesion.name),
    result: result,
    message: message,
  };
}

export { getNewLesionResponses, getNewLesionResponse };
