import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  InputBox,
  TextField,
  Typography,
  Divider,
  LoadingSpinner,
  Alert,
} from '@platform/ui';

export function SubmitRTStructForm({ open, onClose }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('Vysioneer DICOMRT');
  const [inputError, setInputError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const warningMessage = (
    <p style={{ margin: 0 }}>
      ROI contours and measurements must be reviewed by a qualified medical
      professional prior to clinical use or clinical trial use to ensure
      accuracy and safety.
    </p>
  );

  const checkForm = () => {
    if (!description) {
      setInputError('Series Description is empty');
      return false;
    }
    return true;
  };

  const reset = () => {
    setName('');
    setDescription('Vysioneer DICOMRT');
    setInputError(null);
  };

  const handleSubmitClick = async () => {
    const confirmed = checkForm();
    if (!confirmed) return;
    try {
      setIsSubmitting(true);
      await onClose(true, {
        StructureSetName: name,
        SeriesDescription: description,
      });
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <div>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {'Save RTSTRUCT'}
        </Typography>
        <Divider />
        <InputBox label={'Series Description:'}>
          <TextField
            value={description}
            onChange={(e) => {
              setInputError(null);
              setDescription(e.target.value);
            }}
            fullWidth
          />
        </InputBox>
        <div style={{ margin: '10px 0' }}>
          {inputError ? (
            <Alert severity='error'>{inputError}</Alert>
          ) : (
            <Alert severity='warning'>{warningMessage}</Alert>
          )}
        </div>
      </div>
      <div style={{ marginTop: 'auto' }}>
        {isSubmitting ? (
          <LoadingSpinner />
        ) : (
          <Button
            onClick={handleSubmitClick}
            color='primary'
            variant='outlined'
            medium
            fullWidth
          >
            Save
          </Button>
        )}
      </div>
    </Modal>
  );
}
SubmitRTStructForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultCreator: PropTypes.string,
};
