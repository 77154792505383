import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { Timepoint } from './getTimepoint';
import getVolumeFromSlices from './getVolumeFromSlices';
import { polygonLongAxis3D } from '../utils/math/polygonAxis';
import { makeHull } from '../utils/math/convexHull';
import transformPointsToPhysicalById from '../utils/transformPointsToPhysicalById';

export default function getTimepointWithTool(
  imageSet,
  ROIContours,
  calculate3D = true
) {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const images = imageSet.images.map(image => {
    const id = image.getImageId();
    const ImagePositionPatient =
      cornerstone.metaData.get('ImagePositionPatient', id) || {};
    const generalImageModule =
      cornerstone.metaData.get('generalImageModule', id) || {};
    const { instanceNumber: InstanceNumber } = generalImageModule;
    return { id, InstanceNumber, ImagePositionPatient };
  });
  const meta = cornerstone.metaData.get('instance', images[0].id) || {};
  const measurements = ROIContours.map(roi => {
    const slices = images
      .map(image => {
        const painter = editModule.getters.peekPainter(image.id);
        if (!painter) return null;
        const painterInfo = painter
          .getInfo()
          .find(c => c.ROINumber === roi.ROINumber);
        return { ...image, ...painterInfo };
      })
      .filter(i => i !== null);

    /** measurements */
    const maxAreaContours = slices.reduce(
      (acc, cur) => {
        return cur.area > acc.area
          ? {
              id: cur.id,
              area: cur.area,
              maxProductValue: cur.product,
              maxLongAxisValue: cur.mergedLongAxisValue,
              maxShortAxisValue: cur.mergedShortAxisValue,
              mainAreaCentroid: cur.mainAreaCentroid,
              mainAreaPhysicalCentroid: cur.mainAreaPhysicalCentroid,
              InstanceNumber: cur.InstanceNumber,
            }
          : acc;
      },
      {
        id: '',
        area: 0,
        maxProductValue: 0,
        maxLongAxisValue: 0,
        maxShortAxisValue: 0,
        mainAreaCentroid: { x: null, y: null },
        mainAreaPhysicalCentroid: { x: null, y: null, z: null },
        InstanceNumber: null,
      }
    );

    let longAxis3DValue = 0;
    if (calculate3D) {
      /** 3D long axis */
      const slicesPoints = slices.reduce((points, slice) => {
        const painter = editModule.getters.peekPainter(slice.id);
        const state = painter.getState();
        const data = state.data.filter(d => d.ROINumber === roi.ROINumber);
        const slicePoints = data.reduce((acc, cur) => {
          const points = makeHull(cur.handles.points);
          const _points = transformPointsToPhysicalById(points, slice.id);
          return [...acc, ..._points];
        }, []);
        return [...points, ...slicePoints];
      }, []);
      const longAxis3D = polygonLongAxis3D(slicesPoints);
      longAxis3DValue = longAxis3D.value;
    }

    /** volume */
    const volume = getVolumeFromSlices(slices);

    return {
      ROIName: roi.ROIName,
      ROINumber: roi.ROINumber,
      link_id: roi.link_id,
      longAxis3DValue: longAxis3DValue,
      maxLongAxisValue: Number(maxAreaContours.maxLongAxisValue),
      maxShortAxisValue: Number(maxAreaContours.maxShortAxisValue),
      maxProductValue: Number(maxAreaContours.maxProductValue),
      maxAreaValue: Number(maxAreaContours.area),
      maxAreaCentroid: maxAreaContours.mainAreaCentroid,
      maxAreaPhysicalCentroid: maxAreaContours.mainAreaPhysicalCentroid,
      imageId: maxAreaContours.id,
      InstanceNumber: maxAreaContours.InstanceNumber || null,
      volume,
    };
  });

  const timepoint = new Timepoint(meta);
  timepoint.ROIMeasurements = measurements;
  return timepoint;
}
