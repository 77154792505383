import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import { store, API } from '@platform/viewer';
import { redux } from '@platform/core';

const { actions } = redux;
// Cornerstone 3rd party dev kit imports
const draw = importInternal('drawing/draw');

export function boundingBoxPainter(toolState, structureSetSeriesInstanceUid) {
  const module = cornerstoneTools.getModule('rtstruct-edit');
  const state = _.cloneDeep(toolState);
  let ROINumber = module.getters.selectedROINumber();
  const { colorArray } = module.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );
  let pInitialPixel = null;
  let pCurrentPixel = null;
  let initialPoint = null;
  let currentPoint = null;
  if (!_.isNumber(ROINumber)) return;
  return {
    getState: function() {
      return state;
    },
    commit: async function(evt, prompt = true) {
      /** check if ROI contours exist */
      if (state.data.find(x => x.ROINumber === ROINumber) && prompt) {
        const confirmed = window.confirm(
          'ROI contours exist. Are you sure to override the current ROI contours?'
        );
        if (!confirmed) return false;
      }

      const { image, element } = evt.detail;
      const { cachedLut, imageId } = image;
      const initial = cornerstone.canvasToPixel(element, initialPoint);
      const current = cornerstone.canvasToPixel(element, currentPoint);
      const body = {
        ...getDICOMwebKeys(imageId),
        mode: 'box',
        prompt: { box: [initial.x, initial.y, current.x, current.y] },
        image_window: {
          width: cachedLut.windowWidth,
          level: cachedLut.windowCenter,
        },
      };
      const { setDICOMRTImageData } = actions;
      try {
        store.dispatch(setDICOMRTImageData(imageId, { isLoading: true }));
        const mask_contour = await getContour(body);
        state.data = [
          ...state.data.filter(x => x.ROINumber !== ROINumber),
          ...mask_contour.map(polygon => ({
            structureSetSeriesInstanceUid,
            ROINumber,
            handles: { points: polygon.map(p => ({ x: p[0], y: p[1] })) },
          })),
        ];
      } catch (err) {
        prompt &&
          window.alert('Service not available. Please use other tools.');
      } finally {
        store.dispatch(setDICOMRTImageData(imageId, { isLoading: false }));
      }
      pInitialPixel = initial;
      pCurrentPixel = current;
      return true;
    },
    update: function(evt) {
      const { image, currentPoints } = evt.detail;
      const newPoint = currentPoints.canvas;
      if (!initialPoint) {
        initialPoint = newPoint;
        state.initial = newPoint;
      }
      currentPoint = newPoint;
      state.current = newPoint;
      state.imageId = image.imageId;
      return true;
    },
    cursor: function(evt, context, cursorCanvasPosition, isDrawing) {
      if (pInitialPixel && pCurrentPixel) {
        if (module.getters.selectedROINumber() === ROINumber) {
          const { element } = evt.detail;
          const initial = cornerstone.pixelToCanvas(element, pInitialPixel);
          const current = cornerstone.pixelToCanvas(element, pCurrentPixel);
          draw(context, context => {
            context.setLineDash([10, 10]);
            context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
            context.strokeRect(
              initial.x,
              initial.y,
              current.x - initial.x,
              current.y - initial.y
            );
          });
        }
      }
      if (isDrawing) {
        draw(context, context => {
          context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
          context.fillStyle = `rgba(${colorArray.join(',')}, 0.1)`;
          context.strokeRect(
            initialPoint.x,
            initialPoint.y,
            currentPoint.x - initialPoint.x,
            currentPoint.y - initialPoint.y
          );
          context.fillRect(
            initialPoint.x,
            initialPoint.y,
            currentPoint.x - initialPoint.x,
            currentPoint.y - initialPoint.y
          );
        });
        return true;
      }
      return;
    },
    clear: function() {
      pInitialPixel = null;
      pCurrentPixel = null;
    },
  };
}

async function getContour(body) {
  const api = new API();
  const data = await api.segment(body);
  return data;
}

function getDICOMwebKeys(id) {
  return {
    site_id: id.substring(
      id.lastIndexOf('/site/') + 6,
      id.indexOf('/trial/')
    ),
    trial_id: id.substring(
      id.lastIndexOf('/trial/') + 7,
      id.indexOf('/dicom-web/')
    ),
    study_uid: id.substring(
      id.lastIndexOf('/studies/') + 9,
      id.indexOf('/series/')
    ),
    series_uid: id.substring(
      id.lastIndexOf('/series/') + 8,
      id.indexOf('/instances/')
    ),
    instance_uid: id.substring(
      id.lastIndexOf('/instances/') + 11,
      id.indexOf('/frames/')
    ),
  };
}
