import { init } from './init';
import {
  getCurrentUser,
  getMultiFactor,
  onAuthStateChanged,
  signInWithGooglePopup,
  signInWithEmail,
  verifyEmail,
  resetPassword,
  signOut,
  reauthenticateWithEmailAndPassword,
  reauthenticateWithGooglePopup,
  getMultiFactorResolverWithError,
  handlePhoneMultiFactor,
  resolvePhoneMultiFactor,
  sendVerificationCode,
  verifyCodeAndEnrollPhoneNumber,
} from './auth';

export default {
  init,
  /** auth */
  getCurrentUser,
  getMultiFactor,
  onAuthStateChanged,
  signInWithGooglePopup,
  signInWithEmail,
  verifyEmail,
  resetPassword,
  signOut,
  reauthenticateWithEmailAndPassword,
  reauthenticateWithGooglePopup,
  getMultiFactorResolverWithError,
  handlePhoneMultiFactor,
  resolvePhoneMultiFactor,
  sendVerificationCode,
  verifyCodeAndEnrollPhoneNumber,
};
