import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import styled from 'styled-components';
import cornerstoneTools from 'cornerstone-tools';
import * as dcmjs from 'dcmjs';
import _ from 'lodash';

import { AppContext } from '@platform/viewer';
import { utils } from '@platform/core';
import { LoadingSpinner, useLogger, useSnackbar } from '@platform/ui';
import theme from './theme';
import RTEdit from './RTEdit/RTEdit';

export function ExtendedRTPanel(props) {
  const { studies, isStudyLoaded, viewports, activeIndex } = props;
  /** contexts */
  const { appConfig: config } = useContext(AppContext);
  /** states */
  const [panelMode, setPanelMode] = useState('default');
  const [seriesStructureMap, setSeriesStructureMap] = useState({});
  const [selectedStructureSetUID, setSelectedStructureSetUID] = useState('');
  /** queries */
  const location = useLocation();
  const loadingSets = getLoadingStructureSets(studies, isStudyLoaded);
  /** services */
  const snackbar = useSnackbar();
  const logger = useLogger();
  const loadStructureSets = async (displaySets) => {
    const loaded = [];
    for (const displaySet of displaySets) {
      try {
        await displaySet.load(studies, displaySet, { ...config });
        loaded.push(displaySet);
      } catch (error) {
        logger.error({
          message: `Can't load DICOM RT structure set`,
          event_type: 'Viewer:load',
          details: {
            pathname: location.pathname,
            structure_set_study_uid: displaySet.StudyInstanceUID,
            structure_set_series_uid: displaySet.SeriesInstanceUID,
            structure_set_instance_uid: displaySet.SOPInstanceUID,
          },
        });
        snackbar.show({
          title: 'DICOM RTSTRUCT',
          message: `Can't load structure set ${displaySet.SeriesInstanceUID}`,
          type: 'error',
          autoClose: false,
        });
      }
    }
    return loaded;
  };

  useEffect(() => {
    if (studies.length === 0 || !isStudyLoaded) return;
    if (panelMode !== 'default') return;
    /** init structure set */
    (async () => {
      const loaded = await loadStructureSets(loadingSets);
      const seriesStructureMap = initStructureSets(studies, loaded);
      setSeriesStructureMap(seriesStructureMap);
      const panelMode = 'edit';
      const module = cornerstoneTools.getModule('rtstruct-edit');
      module.setters.mode(panelMode);
      setPanelMode(panelMode);
    })();
    return () => {
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      editModule.setters.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies, isStudyLoaded]);

  useEffect(() => {
    /** auto switch structure set */
    if (!seriesStructureMap) return;
    const viewport = viewports[activeIndex];
    const { SeriesInstanceUID } = viewport || {};
    const structureSetUID = seriesStructureMap[SeriesInstanceUID];
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    editModule.setters.selectedStructureSetUID(structureSetUID);
    setSelectedStructureSetUID(structureSetUID);
  }, [viewports, activeIndex, seriesStructureMap]);

  if (isStudyLoaded && panelMode === 'edit') {
    const viewport = viewports[activeIndex];
    return (
      <ThemeProvider theme={theme}>
        {viewport?.SeriesInstanceUID && (
          <RTPanel data-cy='rt-panel'>
            <SubPanel value={panelMode} index={'edit'}>
              <RTEdit
                {...props}
                structureSetUID={selectedStructureSetUID}
                seriesStructureMap={seriesStructureMap}
              />
            </SubPanel>
          </RTPanel>
        )}
      </ThemeProvider>
    );
  }
  return (
    <div style={{ margin: '15px' }}>
      <LoadingSpinner />
    </div>
  );
}
ExtendedRTPanel.propTypes = {
  studies: PropTypes.array,
  isStudyLoaded: PropTypes.bool,
};

const RTPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const SubPanel = (props) => {
  const { children, value, index } = props;
  return value === index && children;
};

function getLoadingStructureSets(studies, isStudyLoaded) {
  if (studies.length === 0 || !isStudyLoaded) return [];
  const rtstructDisplaySets = studies
    .reduce((displaySets, study) => [...displaySets, ...study.displaySets], [])
    .filter((set) => set.Modality === 'RTSTRUCT');
  const sortedRtstructDisplaysets = _.chain(rtstructDisplaySets)
    .sortBy((displayset) => {
      const timestamp = utils.getTimestampFromMetadata(
        displayset.SeriesDate,
        displayset.SeriesTime,
        displayset.metadata.TimezoneOffsetFromUTC
      );
      return -timestamp;
    })
    .value();
  return sortedRtstructDisplaysets;
}

function initStructureSets(studies, displaySets) {
  if (studies.length === 0) return;
  const { globalImageIdSpecificToolStateManager } = cornerstoneTools;
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageSets = utils.getImageSetsFromStudies(studies);
  const axialImageSets = imageSets.filter((set) => set.viewType === 'axial');
  const seriesStructureMap = {};

  const imageTools = globalImageIdSpecificToolStateManager.saveToolState();
  for (const displaySet of displaySets) {
    const { StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID } = displaySet;
    const ROIContours = [];
    const referencedSeriesSequence = [];
    const referencedFrameOfReferenceSequence = [];

    /** copy metadata */
    const module = cornerstoneTools.getModule('rtstruct-edit');
    const defaultStructureSet = module.getters.rawStructureSet(
      SeriesInstanceUID
    );
    if (defaultStructureSet) {
      ROIContours.push(..._.cloneDeep(defaultStructureSet.ROIContours));
      referencedFrameOfReferenceSequence.push(
        ..._.cloneDeep(defaultStructureSet.referencedFrameOfReferenceSequence)
      );
    }

    /** check referenced series --> add data */
    axialImageSets.forEach((imageSet) => {
      const existSequence = !!defaultStructureSet.referencedSeriesSequence;
      const existReferencedSeries = defaultStructureSet.referencedSeriesSequence?.find(
        (sq) => sq.SeriesInstanceUID === imageSet.SeriesInstanceUID
      );
      if (existSequence && !existReferencedSeries) return;
      referencedSeriesSequence.push({
        SeriesInstanceUID: imageSet.SeriesInstanceUID,
        ReferencedInstanceSequence: imageSet.images.map((image) => ({
          ReferencedSOPClassUID: image.getData().metadata.SOPClassUID,
          ReferencedSOPInstanceUID: image.getSOPInstanceUID(),
        })),
      });
      for (const image of imageSet.images) {
        const imageId = image.getImageId();
        let _data = [];
        if (imageTools[imageId]) {
          const data = imageTools[imageId].RTStructDisplayTool.data.filter(
            (data) => data.structureSetSeriesInstanceUid === SeriesInstanceUID
          );
          _data = _.cloneDeep(data);
        }
        /** series-rtstruct match */
        const painter = editModule.setters.createPainter(
          'dummy',
          imageId,
          SeriesInstanceUID,
          { data: _data, SeriesInstanceUID }
        );
        painter.commit();
      }
      imageSet.rtstructSeriesInstanceUID = SeriesInstanceUID;
      seriesStructureMap[imageSet.SeriesInstanceUID] = SeriesInstanceUID;
    });

    const structureSet = {
      ROIContours,
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID,
      referencedSeriesSequence,
      referencedFrameOfReferenceSequence,
      visible: defaultStructureSet.visible,
    };
    editModule.setters.structureSet(structureSet);
  }

  /** add default rtstruct */
  axialImageSets.forEach((imageSet) => {
    if (imageSet.rtstructSeriesInstanceUID) return;
    const StudyInstanceUID = imageSet.StudyInstanceUID;
    const SeriesInstanceUID = dcmjs.data.DicomMetaDictionary.uid();
    const SOPInstanceUID = dcmjs.data.DicomMetaDictionary.uid();
    const ROIContours = [];
    const referencedSeriesSequence = [
      {
        SeriesInstanceUID: imageSet.SeriesInstanceUID,
        ReferencedInstanceSequence: imageSet.images.map((image) => ({
          ReferencedSOPClassUID: image.getData().metadata.SOPClassUID,
          ReferencedSOPInstanceUID: image.getSOPInstanceUID(),
        })),
      },
    ];
    const referencedFrameOfReferenceSequence = [
      {
        FrameOfReferenceUID: dcmjs.data.DicomMetaDictionary.uid(),
        RTReferencedStudySequence: [
          {
            ReferencedSOPClassUID: null,
            ReferencedSOPInstanceUID: imageSet.StudyInstanceUID,
            RTReferencedSeriesSequence: referencedSeriesSequence.map((s) => ({
              SeriesInstanceUID: s.SeriesInstanceUID,
              ContourImageSequence: s.ReferencedInstanceSequence,
            })),
          },
        ],
      },
    ];
    for (const image of imageSet.images) {
      const imageId = image.getImageId();
      /** new-rtstruct */
      const painter = editModule.setters.createPainter(
        'dummy',
        imageId,
        SeriesInstanceUID,
        { data: [], SeriesInstanceUID }
      );
      painter.commit();
    }
    imageSet.rtstructSeriesInstanceUID = SeriesInstanceUID;
    seriesStructureMap[imageSet.SeriesInstanceUID] = SeriesInstanceUID;
    const structureSet = {
      ROIContours,
      StudyInstanceUID,
      SeriesInstanceUID,
      SOPInstanceUID,
      referencedSeriesSequence,
      referencedFrameOfReferenceSequence,
      visible: true,
    };
    editModule.setters.structureSet(structureSet);
  });

  return seriesStructureMap;
}
