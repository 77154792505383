import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ToolbarButton, useLogger } from '@platform/ui';

export default function MPRButton({ button }) {
  const { activeViewportIndex, viewportSpecificData } = useSelector(
    state => state.viewports
  );
  const [isMPRactive, setIsMPRactive] = useState(false);
  const logger = useLogger();
  const displaySet = viewportSpecificData[activeViewportIndex] || {};
  const { StudyInstanceUID, SeriesInstanceUID } = displaySet;
  const details = {
    study_uid: StudyInstanceUID,
    series_uid: SeriesInstanceUID,
  };

  return isMPRactive ? (
    <ToolbarButton
      key={'exit'}
      label={'Exit MPR'}
      icon={'times'}
      onClick={() => {
        try {
          const isActive = button.commandsManager.runCommand('exitMPR');
          setIsMPRactive(isActive);
        } catch (err) {
          logger.error({
            message: `Failed to exit MPR mode`,
            event_type: 'Viewer:viewport',
            details: { ...details, message: err },
          });
        }
      }}
    />
  ) : (
    <ToolbarButton
      key={'multiplanar-reconstruction'}
      label={'MPR'}
      icon={'cube'}
      onClick={async () => {
        try {
          const isActive = await button.commandsManager.runCommand('toggleMPR');
          setIsMPRactive(isActive);
        } catch (err) {
          logger.error({
            message: `Failed to enter MPR mode`,
            event_type: 'Viewer:viewport',
            details: { ...details, message: err },
          });
        }
      }}
      isActive={false}
    />
  );
}
MPRButton.propTypes = { button: PropTypes.object };
