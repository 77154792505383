import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import { store } from '@platform/viewer';
import { utils, redux } from '@platform/core';
import { getEnabledElement } from '../../state';
import CornerstoneViewportDownloadForm from '../viewportModule/ViewportDownloadForm';
import initMpr from '../../mpr/initMpr';
import {
  scrollSynchronizer,
  panZoomSynchronizer,
  wwwcSynchronizer,
} from '../../sync';

const scroll = cornerstoneTools.import('util/scroll');
const { studyMetadataManager, isHighPriorityModality } = utils;
const {
  setViewportSpecificData,
  setViewportLayoutAndData,
  setViewportActive,
  setScrollSync,
  setPanZoomSync,
} = redux.actions;

const refreshCornerstoneViewports = () => {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) cornerstone.updateImage(enabledElement.element);
  });
};

const updateViewportLayoutWithSeries = (
  SeriesInstanceUIDs,
  viewports,
  study
) => {
  let seriesFrameIndexCache = {};
  const numColumns = SeriesInstanceUIDs.length;
  const enabledElements = cornerstone.getEnabledElements();
  for (const ds of Object.values(viewports.viewportSpecificData)) {
    const { SeriesInstanceUID } = ds;
    const enabledElement = enabledElements.find(enabledElement => {
      if (!enabledElement.image) return false;
      const { imageId } = enabledElement.image;
      const uid = cornerstone.metaData.get('SeriesInstanceUID', imageId);
      return uid === SeriesInstanceUID;
    });
    if (enabledElement) {
      const { element } = enabledElement;
      const toolState = cornerstoneTools.getToolState(element, 'stack');
      if (toolState) {
        const imageIds = toolState.data[0].imageIds;
        const frameIndex = imageIds.indexOf(enabledElement.image.imageId);
        if (frameIndex !== -1) {
          seriesFrameIndexCache[SeriesInstanceUID] = frameIndex;
        }
      }
    }
  }

  const viewportSpecificData = SeriesInstanceUIDs.reduce(
    (result, uid, idx, all) => {
      const numColumns = all.length;
      const ds = study.findDisplaySet(ds => ds.SeriesInstanceUID === uid);
      if (!ds) return {};
      const cachedFrameIndex = seriesFrameIndexCache[uid];
      const frameIndex = _.isNumber(cachedFrameIndex)
        ? cachedFrameIndex
        : Math.round(ds.numImageFrames / 2);
      return { ...result, [numColumns - idx - 1]: { ...ds, frameIndex } };
    },
    {}
  );

  const layout = {
    numRows: 1,
    numColumns,
    viewports: _.range(0, numColumns).map(x => ({ plugin: 'cornerstone' })),
  };
  store.dispatch(setViewportLayoutAndData(layout, viewportSpecificData));

  const activeElement = enabledElements.find(enabledElement => {
    if (!enabledElement.image) return false;
    const { imageId } = enabledElement.image;
    const uid = SeriesInstanceUIDs[0];
    return cornerstone.metaData.get('SeriesInstanceUID', imageId) === uid;
  });
  if (activeElement) {
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    const { imageId } = activeElement.image;
    if (editModule) editModule.setters.scrollingImageId(imageId);
  }

  refreshCornerstoneViewports();
};

const removeScrollSync = () => {
  store.dispatch(setScrollSync(false));
  scrollSynchronizer.enabled = false;
  cornerstone.events.removeEventListener(
    cornerstone.EVENTS.ELEMENT_ENABLED,
    event => scrollSynchronizer.add(event.detail.element)
  );
  cornerstone.events.removeEventListener(
    cornerstone.EVENTS.ELEMENT_DISABLED,
    event => scrollSynchronizer.remove(event.detail.element)
  );
};

const removePanZoomSync = () => {
  store.dispatch(setPanZoomSync(false));
  panZoomSynchronizer.enabled = false;
  cornerstone.events.removeEventListener(
    cornerstone.EVENTS.ELEMENT_ENABLED,
    event => panZoomSynchronizer.add(event.detail.element)
  );
  cornerstone.events.removeEventListener(
    cornerstone.EVENTS.ELEMENT_DISABLED,
    event => panZoomSynchronizer.remove(event.detail.element)
  );
};

const commandsModule = ({ servicesManager }) => {
  const actions = {
    rotateViewport: ({ viewports, rotation }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.rotation += rotation;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportHorizontal: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.hflip = !viewport.hflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    flipViewportVertical: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      if (enabledElement) {
        let viewport = cornerstone.getViewport(enabledElement);
        viewport.vflip = !viewport.vflip;
        cornerstone.setViewport(enabledElement, viewport);
      }
    },
    scaleViewport: ({ direction, viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      const step = direction * 0.15;
      if (enabledElement) {
        if (step) {
          let viewport = cornerstone.getViewport(enabledElement);
          viewport.scale += step;
          cornerstone.setViewport(enabledElement, viewport);
        } else {
          cornerstone.fitToWindow(enabledElement);
        }
      }
    },
    resetViewport: ({ viewports }) => {
      const idx = viewports.activeViewportIndex;
      const viewportData = viewports.viewportSpecificData[idx];
      if (viewportData.isFusion) return;
      const enabledElement = getEnabledElement(idx);
      if (enabledElement) cornerstone.reset(enabledElement);
    },
    setToolActive: ({ toolName }) => {
      if (!toolName) return;
      cornerstoneTools.store.state.tools.forEach(tool => {
        const targetTools = [
          'Pan',
          'Zoom',
          'Wwwc',
          'StackScroll',
          'EdgeTool',
          'PolygonTool',
          'RepulserTool',
          'DeleteTool',
          'SphereThresholdTool',
          'RegionGrowingTool',
          'PointTool',
          'BoundingBoxTool',
          'BoundingBox3DTool',
          'SelectTool',
        ];
        if (targetTools.includes(tool.name) && tool.name !== toolName) {
          cornerstoneTools.setToolDisabled(tool.name);
        }
      });
      cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 });
      cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
      cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
      cornerstoneTools.setToolActive('StackScrollMouseWheel', {});
      cornerstoneTools.setToolActive('PanMultiTouch', { pointers: 2 });
      cornerstoneTools.setToolActive('ZoomTouchPinch', {});
      cornerstoneTools.setToolEnabled('Overlay', {});
    },
    clearMeasurements: ({ viewports }) => {
      const element = getEnabledElement(viewports.activeViewportIndex);
      if (!element) return;

      const enabledElement = cornerstone.getEnabledElement(element);
      if (!enabledElement || !enabledElement.image) return;

      const {
        toolState,
      } = cornerstoneTools.globalImageIdSpecificToolStateManager;
      if (
        !toolState ||
        toolState.hasOwnProperty(enabledElement.image.imageId) === false
      ) {
        return;
      }
      const imageIdToolState = toolState[enabledElement.image.imageId];
      const lengthsToRemove = [];
      const anglesToRemove = [];
      const bidirectionalsToRemove = [];

      Object.keys(imageIdToolState).forEach(toolType => {
        const { data } = imageIdToolState[toolType];
        if (toolType === 'Length') {
          data.forEach(measurementData => {
            lengthsToRemove.push(measurementData);
          });
        } else if (toolType === 'Angle') {
          data.forEach(measurementData => {
            anglesToRemove.push(measurementData);
          });
        } else if (toolType === 'Bidirectional') {
          data.forEach(measurementData => {
            bidirectionalsToRemove.push(measurementData);
          });
        }
      });
      lengthsToRemove.forEach(data => {
        cornerstoneTools.removeToolState(element, 'Length', data);
      });
      anglesToRemove.forEach(data => {
        cornerstoneTools.removeToolState(element, 'Angle', data);
      });
      bidirectionalsToRemove.forEach(data => {
        cornerstoneTools.removeToolState(element, 'Bidirectional', data);
      });

      cornerstone.updateImage(element);
    },
    nextImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, 1);
    },
    previousImage: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      scroll(enabledElement, -1);
    },
    getActiveViewportEnabledElement: ({ viewports }) => {
      const enabledElement = getEnabledElement(viewports.activeViewportIndex);
      return enabledElement;
    },
    toggleSelectedSynchronizer: ({ toggledState, synchronizer }) => {
      synchronizer.enabled = toggledState;
      // Add event handlers so that if the layout is changed, new elements
      // are automatically added to the synchronizer while it is enabled.
      if (toggledState === true) {
        cornerstone.events.addEventListener(
          cornerstone.EVENTS.ELEMENT_ENABLED,
          event => synchronizer.add(event.detail.element)
        );
        cornerstone.events.addEventListener(
          cornerstone.EVENTS.ELEMENT_DISABLED,
          event => synchronizer.remove(event.detail.element)
        );
      } else {
        // If the synchronizer is disabled, remove the event handlers
        cornerstone.events.removeEventListener(
          cornerstone.EVENTS.ELEMENT_ENABLED,
          event => synchronizer.add(event.detail.element)
        );
        cornerstone.events.removeEventListener(
          cornerstone.EVENTS.ELEMENT_DISABLED,
          event => synchronizer.remove(event.detail.element)
        );
      }

      // Erase existing state and then set up all currently existing elements
      synchronizer.destroy();
      cornerstone
        .getEnabledElements()
        .forEach(e => synchronizer.add(e.element));
    },
    removeScrollSync: removeScrollSync,
    removePanZoomSync: removePanZoomSync,
    showDownloadViewportModal: ({ title, viewports }) => {
      const activeViewportIndex = viewports.activeViewportIndex;
      const { UIModalService } = servicesManager.services;
      if (UIModalService) {
        UIModalService.show({
          content: CornerstoneViewportDownloadForm,
          title,
          contentProps: { activeViewportIndex, onClose: UIModalService.hide },
        });
      }
    },
    jumpToImage: ({
      StudyInstanceUID,
      SOPInstanceUID,
      frameIndex,
      activeViewportIndex,
      refreshViewports = true,
    }) => {
      const study = studyMetadataManager.get(StudyInstanceUID);
      const displaySet = study.findDisplaySet(ds => {
        return (
          ds.images &&
          ds.images.find(i => i.getSOPInstanceUID() === SOPInstanceUID)
        );
      });
      if (!displaySet) return;
      displaySet.SOPInstanceUID = SOPInstanceUID;
      displaySet.frameIndex = frameIndex;
      store.dispatch(setViewportSpecificData(activeViewportIndex, displaySet));
      if (refreshViewports) refreshCornerstoneViewports();
    },
    exitMPR: ({ viewports }) => {
      const nonAxialViewKeys = _.pickBy(
        viewports.viewportSpecificData,
        function(data) {
          if (!isHighPriorityModality(data.Modality)) return false;
          return data.viewType !== 'axial';
        }
      );
      const { StudyInstanceUID } = viewports.viewportSpecificData[
        viewports.activeViewportIndex
      ];
      const study = studyMetadataManager.get(StudyInstanceUID);
      const uids = _.reduce(
        viewports.viewportSpecificData,
        function(result, data, key) {
          if (key in nonAxialViewKeys) return result;
          return [...result, data.SeriesInstanceUID];
        },
        []
      );
      updateViewportLayoutWithSeries(uids, viewports, study);
      return false;
    },
    toggleMPR: async ({ viewports }) => {
      let isActive;
      const nonAxialViewKeys = _.pickBy(
        viewports.viewportSpecificData,
        function(data) {
          if (!isHighPriorityModality(data.Modality)) return false;
          return data.viewType !== 'axial';
        }
      );
      if (_.size(nonAxialViewKeys) === 0) {
        /** Axial -> Sagittal|Coronal|Axial */
        const layout = {
          numRows: 1,
          numColumns: 3,
          viewports: _.range(0, 3).map(x => ({
            plugin: 'cornerstone',
            isMpr: true,
          })),
        };
        const MPRdata = await initMpr(
          viewports.viewportSpecificData,
          viewports.activeViewportIndex
        );
        store.dispatch(setViewportLayoutAndData(layout, MPRdata));
        store.dispatch(setViewportActive(1));
        isActive = true;
        removeScrollSync();
      } else {
        /** Sagittal|Coronal|Axial -> Axial */
        const activeIdx = viewports.activeViewportIndex;
        const { StudyInstanceUID } = viewports.viewportSpecificData[activeIdx];
        const study = studyMetadataManager.get(StudyInstanceUID);
        const uids = _.reduce(
          viewports.viewportSpecificData,
          function(result, data, key) {
            if (key in nonAxialViewKeys) return result;
            return [...result, data.SeriesInstanceUID];
          },
          []
        );
        updateViewportLayoutWithSeries(uids, viewports, study);
        isActive = false;
      }
      return isActive;
    },
    setWindow: ({ windowWidth, windowCenter }) => {
      cornerstone.getEnabledElements().forEach((enabledElement) => {
        if (enabledElement) {
          try {
            let viewport = cornerstone.getViewport(enabledElement.element);
            viewport.voi = {
              windowWidth: Number(windowWidth),
              windowCenter: Number(windowCenter),
            };
            cornerstone.setViewport(enabledElement.element, viewport);
          } catch (err) {
            return;
          }
        }
      });
    },
  };

  const definitions = {
    jumpToImage: {
      commandFn: actions.jumpToImage,
      storeContexts: [],
      options: {},
    },
    showDownloadViewportModal: {
      commandFn: actions.showDownloadViewportModal,
      storeContexts: ['viewports'],
      options: {},
    },
    getActiveViewportEnabledElement: {
      commandFn: actions.getActiveViewportEnabledElement,
      storeContexts: ['viewports'],
      options: {},
    },
    toggleScrollSynchronizer: {
      commandFn: actions.toggleSelectedSynchronizer,
      storeContexts: ['viewports'],
      options: { synchronizer: scrollSynchronizer },
    },
    togglePanZoomSynchronizer: {
      commandFn: actions.toggleSelectedSynchronizer,
      storeContexts: ['viewports'],
      options: { synchronizer: panZoomSynchronizer },
    },
    toggleWwwcSynchronizer: {
      commandFn: actions.toggleSelectedSynchronizer,
      storeContexts: ['viewports'],
      options: { synchronizer: wwwcSynchronizer },
    },
    removeScrollSync: {
      commandFn: actions.removeScrollSync,
      storeContexts: [],
      options: {},
    },
    removePanZoomSync: {
      commandFn: actions.removePanZoomSync,
      storeContexts: [],
      options: {},
    },
    rotateViewportCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: 90 },
    },
    rotateViewportCCW: {
      commandFn: actions.rotateViewport,
      storeContexts: ['viewports'],
      options: { rotation: -90 },
    },
    flipViewportVertical: {
      commandFn: actions.flipViewportVertical,
      storeContexts: ['viewports'],
      options: {},
    },
    flipViewportHorizontal: {
      commandFn: actions.flipViewportHorizontal,
      storeContexts: ['viewports'],
      options: {},
    },
    scaleUpViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: 1 },
    },
    scaleDownViewport: {
      commandFn: actions.scaleViewport,
      storeContexts: ['viewports'],
      options: { direction: -1 },
    },
    resetViewport: {
      commandFn: actions.resetViewport,
      storeContexts: ['viewports'],
      options: {},
    },
    clearMeasurements: {
      commandFn: actions.clearMeasurements,
      storeContexts: ['viewports'],
      options: {},
    },
    nextImage: {
      commandFn: actions.nextImage,
      storeContexts: ['viewports'],
      options: {},
    },
    previousImage: {
      commandFn: actions.previousImage,
      storeContexts: ['viewports'],
      options: {},
    },
    // TOOLS
    setToolActive: {
      commandFn: actions.setToolActive,
      storeContexts: [],
      options: {},
    },
    exitMPR: {
      commandFn: actions.exitMPR,
      storeContexts: ['viewports'],
      options: {},
    },
    toggleMPR: {
      commandFn: actions.toggleMPR,
      storeContexts: ['viewports'],
      options: {},
    },
    windowLevelPreset1: {
      commandFn: () => actions.setWindow({ windowWidth: 80, windowCenter: 40 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset2: {
      commandFn: () =>
        actions.setWindow({ windowWidth: 300, windowCenter: 100 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset3: {
      commandFn: () => actions.setWindow({ windowWidth: 40, windowCenter: 40 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset4: {
      commandFn: () =>
        actions.setWindow({ windowWidth: 2800, windowCenter: 600 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset5: {
      commandFn: () =>
        actions.setWindow({ windowWidth: 400, windowCenter: 60 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset6: {
      commandFn: () =>
        actions.setWindow({ windowWidth: 1500, windowCenter: -600 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset7: {
      commandFn: () =>
        actions.setWindow({ windowWidth: 150, windowCenter: 30 }),
      storeContexts: [],
      options: {},
    },
    windowLevelPreset8: {
      commandFn: () =>
        actions.setWindow({ windowWidth: 1800, windowCenter: 400 }),
      storeContexts: [],
      options: {},
    },
  };

  const context = 'ACTIVE_VIEWPORT::CORNERSTONE';
  return { actions, definitions, defaultContext: context };
};

export default commandsModule;
