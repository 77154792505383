import cornerstoneTools from 'cornerstone-tools';
import * as dcmjs from 'dcmjs';
import _ from 'lodash';
import moment from 'moment';
import {
  math,
  transformPointsToPhysicalById,
  getMeasurementOfSlice,
  getVolumeFromSlices,
} from '../dicom-measurement/src';
const { round } = math;


function getMeasurements(images, ROIContours) {
  const measurements = [];
  for (const roi of ROIContours) {
    const slices = [];
    for (const [id, image] of Object.entries(images)) {
      const data = image.data[roi.ROINumber] || [];
      const ImagePositionPatient =
        cornerstone.metaData.get('ImagePositionPatient', id) || {};
      const generalImageModule =
        cornerstone.metaData.get('generalImageModule', id) || {};
      const { instanceNumber: InstanceNumber } = generalImageModule;
      const ps = cornerstone.metaData.get('PixelSpacing', id);
      if (!ps) return [];
      const imagePlane = { rowPixelSpacing: ps[0], columnPixelSpacing: ps[1] };
      const m = getMeasurementOfSlice(data, id, imagePlane);
      slices.push({ ...m, id, InstanceNumber, ImagePositionPatient });
    }

    /** measurements */
    const maxAreaContours = slices.reduce(
      (acc, cur) => {
        return cur.area > acc.area
          ? {
              id: cur.id,
              area: cur.area,
              maxProductValue: cur.product,
              maxLongAxisValue: cur.mergedLongAxisValue,
              maxShortAxisValue: cur.mergedShortAxisValue,
              mainAreaCentroid: cur.mainAreaCentroid,
              mainAreaPhysicalCentroid: cur.mainAreaPhysicalCentroid,
              imageIdx: cur.InstanceNumber,
            }
          : acc;
      },
      {
        id: '',
        area: 0,
        maxProductValue: 0,
        maxLongAxisValue: 0,
        maxShortAxisValue: 0,
        mainAreaCentroid: { x: null, y: null },
        mainAreaPhysicalCentroid: { x: null, y: null, z: null },
        imageIdx: null,
      }
    );

    /** volume */
    const volume = getVolumeFromSlices(slices);
    measurements.push({
      ROIName: roi.ROIName,
      ROINumber: roi.ROINumber,
      link_id: roi.link_id,
      maxLongAxisValue: Number(maxAreaContours.maxLongAxisValue),
      maxShortAxisValue: Number(maxAreaContours.maxShortAxisValue),
      maxAreaCentroid: maxAreaContours.mainAreaCentroid,
      maxAreaPhysicalCentroid: maxAreaContours.mainAreaPhysicalCentroid,
      imageId: maxAreaContours.id,
      InstanceNumber: maxAreaContours.imageIdx || null,
      volume,
    });
  }
  return measurements;
}

export const meta = {
  MediaStorageSOPClassUID: '1.2.840.10008.5.1.4.1.1.481.3',
  TransferSyntaxUID: '1.2.840.10008.1.2',
};

export function getRTStructDatasetWithMeasurement(series, rtstruct, links) {
  const now = moment();
  const date = now.format('YYYYMMDD');
  const time = now.format('HHmmss.000000');
  const utcOffset = now.utcOffset();
  const tzoffsetSign = utcOffset >= 0 ? '+' : '-';
  const tzoffsetMinute = (100 + (Math.abs(utcOffset) % 60)).toString().slice(1);
  const tzoffsetHour = (100 + Math.floor(Math.abs(utcOffset) / 60))
    .toString()
    .slice(1);

  const dataset = {
    ClinicalTrialSiteID: series.ClinicalTrialSiteID,
    ClinicalTrialSiteName: series.ClinicalTrialSiteName,
    ClinicalTrialSubjectID: series.ClinicalTrialSubjectID,
    ClinicalTrialTimePointID: series.ClinicalTrialTimePointID,
    PatientName: series.PatientName,
    PatientID: series.PatientID,
    PatientBirthDate: series.PatientBirthDate,
    PatientBirthTime: series.PatientBirthTime,
    PatientSex: series.PatientSex,
    StudyDate: series.StudyDate,
    StudyTime: series.StudyTime,
    AccessionNumber: series.AccessionNumber,
    StudyDescription: series.StudyDescription,
    StudyInstanceUID: series.StudyInstanceUID,
    SeriesInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
    SeriesDescription: rtstruct.SeriesDescription,
    SeriesDate: date,
    SeriesTime: time,
    Modality: 'RTSTRUCT',
    SpecificCharacterSet: 'ISO_IR 192',
    SOPClassUID: '1.2.840.10008.5.1.4.1.1.481.3',
    SOPInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
    ContentCreatorName: rtstruct.ContentCreatorName,
    Manufacturer: 'Vysioneer',
    StructureSetName: rtstruct.StructureSetName,
    StructureSetLabel: rtstruct.StructureSetLabel,
    StructureSetDescription: rtstruct.SeriesDescription,
    StructureSetDate: date,
    StructureSetTime: time,
    TimezoneOffsetFromUTC: `${tzoffsetSign}${tzoffsetHour}${tzoffsetMinute}`,
    ReferencedFrameOfReferenceSequence:
      rtstruct.referencedFrameOfReferenceSequence,
  };

  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageIdMap = series.images.reduce((imageIdMap, image) => {
    const imageId = image.getImageId();
    const sopInstanceUID = image.getSOPInstanceUID();
    imageIdMap[sopInstanceUID] = imageId;
    return imageIdMap;
  }, {});
  const imageIds = rtstruct.referencedSeriesSequence
    .reduce((acc, seriesSequence) => {
      return [...acc, ...seriesSequence.ReferencedInstanceSequence];
    }, [])
    .reduce((result, referencedInstance) => {
      const imageId = imageIdMap[referencedInstance.ReferencedSOPInstanceUID];
      if (!imageId) return result;
      const { data } = editModule.getters.toolState(imageId);
      const uid = rtstruct.SeriesInstanceUID;
      return {
        ...result,
        [imageId]: {
          referencedInstance,
          data: _.chain(data)
            .filter((d) => d.structureSetSeriesInstanceUid === uid)
            .groupBy((d) => d.ROINumber)
            .value(),
        },
      };
    }, {});
  const measurements = getMeasurements(imageIds, rtstruct.ROIContours);

  const ROIIdx = rtstruct.ROIContours.reduce((ROIIdx, ROIContour, idx) => {
    ROIIdx[ROIContour.ROINumber] = idx;
    return ROIIdx;
  }, {});

  dataset.StructureSetROISequence = rtstruct.ROIContours.map((roi) => {
    const measruement = measurements.find((m) => m.ROINumber === roi.ROINumber);
    const link = links.find((l) => l._id === roi.link_id);
    const description = {
      link_id: roi.link_id || '',
      long_axis: round(measruement?.maxLongAxisValue || 0, -2),
      short_axis: round(measruement?.maxShortAxisValue || 0, -2),
      volume: round(measruement?.volume || 0, -2),
      centroid:
        measruement.InstanceNumber !== null
          ? [
              round(measruement.maxAreaCentroid.x, -2),
              round(measruement.maxAreaCentroid.y, -2),
              measruement.InstanceNumber,
            ]
          : null,
      physical_centroid:
        measruement.InstanceNumber !== null
          ? [
              round(measruement.maxAreaPhysicalCentroid.x, -2),
              round(measruement.maxAreaPhysicalCentroid.y, -2),
              round(measruement.maxAreaPhysicalCentroid.z, -2),
            ]
          : null,
    };
    if (link?.type === 'TARGET') {
      description.too_small_to_measure = !!roi.too_small_to_measure;
    }
    if (link?.type === 'NEW') {
      description.unequivocal = !!roi.unequivocal;
    }
    if (roi.too_small_to_measure) {
      description.long_axis = 5;
      description.short_axis = 5;
      description.volume = 0;
    }
    if (roi.ROIGenerationDescription === 'SearchRegion') {
      description.long_axis = 0;
      description.short_axis = 0;
      description.volume = 0;
    }

    return {
      ROINumber: ROIIdx[roi.ROINumber],
      ROIDescription: JSON.stringify(description),
      ReferencedFrameOfReferenceUID: series.FrameOfReferenceUID,
      ROIName: roi.ROIName,
      ROIGenerationAlgorithm: roi.ROIGenerationAlgorithm,
      ROIGenerationDescription: roi.ROIGenerationDescription,
    };
  });

  dataset.ROIContourSequence = _.chain(rtstruct.ROIContours)
    .map((roi) => {
      const ContourSequence = _.flatMap(
        imageIds,
        ({ referencedInstance: instance, data }, imageId) => {
          data = data[roi.ROINumber] || [];
          return _.chain(data)
            .map(({ handles }) => {
              const { points } = handles;
              if (!points.length) return null;
              const physicalPoints = transformPointsToPhysicalById(
                points,
                imageId
              );
              const _data = physicalPoints.reduce((acc, e) => {
                return acc.concat([e.x, e.y, e.z].map((x) => _.round(x, 3)));
              }, []);
              return {
                ContourImageSequence: {
                  ReferencedSOPClassUID: instance.ReferencedSOPClassUID,
                  ReferencedSOPInstanceUID: instance.ReferencedSOPInstanceUID,
                },
                ContourGeometricType: 'CLOSED_PLANAR',
                NumberOfContourPoints: physicalPoints.length,
                ContourData: _data,
              };
            })
            .compact()
            .value();
        }
      );
      return {
        ContourSequence,
        ReferencedROINumber: ROIIdx[roi.ROINumber],
        ROIDisplayColor: roi.colorArray,
      };
    })
    .compact()
    .value();

  dataset.RTROIObservationsSequence = rtstruct.ROIContours.map((roi) => ({
    ObservationNumber: ROIIdx[roi.ROINumber],
    ReferencedROINumber: ROIIdx[roi.ROINumber],
    ROIObservationLabel: roi.RTROIObservations.ROIObservationLabel,
    RTROIInterpretedType: roi.RTROIObservations.RTROIInterpretedType,
    ROIInterpreter: roi.RTROIObservations.ROIInterpreter,
  }));

  return dataset;
}
