import * as dcmjs from 'dcmjs';

import { meta } from './getRTStructDatasetWithMeasurement';

const { datasetToBlob } = dcmjs.data;

export default async function(dataset) {
  const reportBlob = datasetToBlob({ ...dataset, _meta: meta });
  return reportBlob;
}
