import React from 'react';

import { API } from './context/APIContext';
import { SystemError } from './cloud/pages/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(err) {
    this.setState({ hasError: true });
    const api = new API();
    api.log({
      level: 'error',
      event_type: 'Platform:Error',
      message: err.message,
      details: { error: err },
    });
  }

  render() {
    if (this.state.hasError) {
      return <SystemError />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
