import getOverallResponse from './getOverallResponse';
import { STEROIDS_USE, CLINICAL_STATUS } from './guideline';

export function getTimepoints(links, rtstructs) {
  const timepoints = Object.values(
    rtstructs.reduce((ts, rt) => {
      ts[rt.timepoint_id] = ts[rt.timepoint_id] || {
        subject_id: rt.subject_id,
        id: rt.timepoint_id,
        rois: [],
      };
      rt.ROIs.forEach((roi) => {
        const link = links.find((l) => l._id === roi.link_id) || {};
        ts[rt.timepoint_id].rois.push({
          id: link._id,
          maxLongAxisValue: roi.long_axis,
          maxShortAxisValue: roi.short_axis,
          maxDiameterValue: link?.location?.includes('LYMPHNODE')
            ? roi.short_axis
            : roi.long_axis,
          maxAreaValue: Number(roi.long_axis) * Number(roi.short_axis),
          volume: roi.volume,
          unequivocal: roi.unequivocal,
        });
      });
      return ts;
    }, {})
  );
  if (!timepoints.find((t) => t.id === 'BL')) {
    timepoints.unshift({
      id: 'BL',
      subject_id: rtstructs[0]?.subject_id,
      rois: [],
    });
  }
  timepoints.forEach((t) => {
    const lesions = links.map((link) => {
      const measurement = t.rois
        .filter((roi) => roi.id === link._id)
        .reduce(
          (m, roi) => {
            m.exist = true;
            m.maxLongAxisValue += Number(roi.maxLongAxisValue);
            m.maxShortAxisValue += Number(roi.maxLongAxisValue);
            m.maxDiameterValue += Number(roi.maxDiameterValue);
            m.maxAreaValue += Number(roi.maxAreaValue);
            m.volume += Number(roi.volume);
            m.unequivocal = m.unequivocal || roi.unequivocal;
            return m;
          },
          {
            exist: false,
            maxLongAxisValue: 0,
            maxShortAxisValue: 0,
            maxDiameterValue: 0,
            maxAreaValue: 0,
            volume: 0,
          }
        );
      const lesion = {
        timepoint_id: t.id,
        id: link._id,
        link_id: link._id,
        name: link.name,
        type: link.type,
        location: link.location,
        ...measurement,
      };
      return lesion;
    });
    t.Lesions = lesions;
  });
  timepoints.sort((a, b) => {
    if (a.id === 'BL') {
      return -1;
    } else if (b.id === 'BL') {
      return 1;
    }
    const atp = Number(a.id.replace('TP', ''));
    const btp = Number(b.id.replace('TP', ''));
    return atp - btp;
  });
  return timepoints;
}

export function getAssessedTimepoints(
  timepoints,
  targetResponses,
  nonTargetResponses,
  newLesionResponses
) {
  return timepoints.map((timepoint, index) => {
    const targetResponse = targetResponses[index];
    const nonTargetResponse = nonTargetResponses[index];
    const newLesionResponse = newLesionResponses[index];
    const steroidsUse = { result: STEROIDS_USE.NOT_APPLICABLE };
    const clinicalStatus = { result: CLINICAL_STATUS.NOT_APPLICABLE };

    /** recalculate overall responses */
    const overallResponse = getOverallResponse({
      targetResponse,
      nonTargetResponse,
      newLesionResponse,
      steroidsUse,
      clinicalStatus,
    });

    return {
      ...timepoint,
      targetResponse,
      nonTargetResponse,
      newLesionResponse,
      steroidsUse,
      clinicalStatus,
      overallResponse,
    };
  });
}
