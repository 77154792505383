import { MEASUREMENT_TECHNIQUE, NON_TARGET_RESPONSE } from './guideline';

function a(num) {
  return Number(num) > 0 ? `+${num}` : num;
}

function getNonTargetResponses(nonTargetStats, criteria) {
  return nonTargetStats.map((stat) => getNonTargetResponse(stat, criteria));
}

/**
 * @param {String} criteria.measurementTechnique
 * @param {Number} criteria.minDiameterIncrease
 * @param {Number} criteria.minDiameterDecrease
 * @param {Number} criteria.minVolumeIncrease
 * @param {Number} criteria.minVolumeDecrease
 */
function getNonTargetResponse(stat, criteria) {
  const measurementTechnique =
    criteria?.measurementTechnique || MEASUREMENT_TECHNIQUE.BIDIMENSIONAL;
  const keyMap = {
    [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: {
      sum: 'sumDiameter',
      change: 'sumDiameter',
      increase: 'minDiameterIncrease',
      decrease: 'minDiameterDecrease',
    },
    [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: {
      sum: 'sumVolume',
      change: 'sumVolume',
      increase: 'minVolumeIncrease',
      decrease: 'minVolumeDecrease',
    },
  };
  const key = keyMap[measurementTechnique];
  const minIncrease = criteria[key.increase] * 100;
  const changeFromBaseline = stat.fromBaseline[key.change];
  const changeFromNadir = stat.fromNadir[key.change];
  const existAbsoluteDiameterChange =
    stat.fromNadir.existAbsoluteDiameterChange;
  const existAbsoulteSODChange =
    stat.fromNadir.existAbsoluteSumOfDiameterChange;

  let result;
  let message;

  switch (criteria.guidelineId) {
    case 'RECIST11':
    case 'RANOBM': {
      if (stat.existLesions && !stat.existROIs) {
        /** complete response */
        result = NON_TARGET_RESPONSE.COMPLETE_RESPONSE;
        message = `Non-target Lesion(CR): no lesion found at timepoint ${stat.fromBaseline.timepoint_id}`;
      } else {
        /** non-progressive disease */
        result = NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE;
        message = `Non-target Lesion(Non-CR/Non-PD): lesion found at timepoint ${stat.fromBaseline.timepoint_id}; please refer to the DICOM images`;
      }
      break;
    }
    default: {
      const noChange =
        changeFromNadir < minIncrease
          ? ''
          : criteria.absoluteSumOfDiameterChange && !existAbsoulteSODChange
          ? `, sum of change in non-target lesions is not greater than ${criteria['absoluteSumOfDiameterChange']}mm from ${stat.fromNadir.baseline_timepoint_id} to ${stat.fromNadir.timepoint_id}`
          : criteria.absoluteDiameterChange && !existAbsoluteDiameterChange
          ? `, no change in non-target lesion greater than ${criteria['absoluteDiameterChange']}mm from ${stat.fromNadir.baseline_timepoint_id} to ${stat.fromNadir.timepoint_id}`
          : '';

      if (
        changeFromNadir >= minIncrease &&
        existAbsoulteSODChange &&
        existAbsoluteDiameterChange
      ) {
        /** progressive disease */
        result = NON_TARGET_RESPONSE.PROGRESSIVE_DISEASE;
        message = `Non-target Lesion(PD): ${a(
          changeFromNadir
        )}% change from timepoint ${stat.fromNadir.baseline_timepoint_id} to ${
          stat.fromNadir.timepoint_id
        }`;
      } else if (changeFromBaseline <= -100) {
        /** complete response */
        result = NON_TARGET_RESPONSE.COMPLETE_RESPONSE;
        message = `Non-target Lesion(CR): ${a(
          changeFromBaseline
        )}% change from timepoint ${
          stat.fromBaseline.baseline_timepoint_id
        } to ${stat.fromBaseline.timepoint_id}`;
      } else {
        /** non-progressive disease */
        result = NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE;
        message = `Non-target Lesion(Non-CR/Non-PD): ${a(
          changeFromBaseline
        )}% change from timepoint ${
          stat.fromBaseline.baseline_timepoint_id
        } to ${stat.fromBaseline.timepoint_id}${noChange}`;
      }

      const sum = stat.summation[key.sum];
      const { timepoint_id, baseline_timepoint_id } = stat.fromBaseline;
      if (timepoint_id !== baseline_timepoint_id && sum === 0) {
        /** complete response */
        result = NON_TARGET_RESPONSE.COMPLETE_RESPONSE;
        message = `Non-target Lesion(CR): no lesion found at timepoint ${stat.fromBaseline.timepoint_id}`;
      }
      break;
    }
  }

  if (!stat.existLesions) {
    /** no lesion */
    result = NON_TARGET_RESPONSE.NONE;
    message = `Non-target Lesion(NED): no non-target lesion selected`;
  }

  return {
    fromBaseline: changeFromBaseline,
    fromNadir: changeFromNadir,
    result: result,
    message: message,
  };
}

export { getNonTargetResponses, getNonTargetResponse };
