import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { utils } from '@platform/core';

import {
  math,
  transformPointsToPhysicalById,
} from '../../modules/dicom-measurement/src';
const { getDistance3D } = math;
const scrollToIndex = cornerstoneTools.import('util/scrollToIndex');
const { getViewTypeFromOrientation } = utils;

function refreshViewport() {
  cornerstone.getEnabledElements().forEach((enabledElement) => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
}

const getMaxAreaSliceWithTool = (imageIds, StructureSetUID, ROINumber) => {
  const slices = imageIds.map((imageId, index) => {
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    const painter = editModule.getters.peekPainter(imageId);
    if (!painter) return { index: -1 };
    const { area, mainAreaCentroid } = painter
      .getInfo()
      .filter(
        (c) =>
          c.structureSetSeriesInstanceUid === StructureSetUID &&
          c.ROINumber === ROINumber
      )
      .reduce(
        (acc, cur) => {
          return cur.area > acc.area
            ? {
                area: acc.area + cur.area,
                mainArea: cur.area,
                mainAreaCentroid: cur.mainAreaCentroid,
              }
            : { ...acc, area: acc.area + cur.area };
        },
        { area: 0, mainArea: 0, mainAreaCentroid: { x: null, y: null } }
      );
    return { area: area, centroid: mainAreaCentroid, index: index };
  });
  return getMaxAreaSlice(slices);
};

const getMaxAreaSlice = (slices) => {
  const maxAreaSlice = slices.reduce(
    (acc, cur) => {
      return cur.area > acc.area ? cur : acc;
    },
    { index: -1, area: 0, centroid: null }
  );
  return maxAreaSlice;
};

const checkExistenceOfROIContour = (StructureSetUID, ROINumber) => {
  const elements = cornerstone.getEnabledElements();
  const elementWithROI = elements.find((enabledElement) => {
    const { element } = enabledElement;
    const toolState = cornerstoneTools.getToolState(element, 'stack');
    const { imageIds: ids } = toolState.data[0];
    const slice = getMaxAreaSliceWithTool(ids, StructureSetUID, ROINumber);
    return slice.index !== -1;
  });
  return !!elementWithROI;
};

function checkViewTypeOfElement(enabledElement) {
  const { element } = enabledElement;
  const { imageId } = enabledElement.image;
  const toolState = cornerstoneTools.getToolState(element, 'stack');
  const { imageIds: ids } = toolState.data[0];
  const m = cornerstone.metaData.get('imagePlaneModule', imageId);
  const m0 = cornerstone.metaData.get('imagePlaneModule', ids[0]);
  const m1 = cornerstone.metaData.get('imagePlaneModule', ids[ids.length - 1]);
  const vt = getViewTypeFromOrientation(m.imageOrientationPatient);
  const vt0 = getViewTypeFromOrientation(m0.imageOrientationPatient);
  const vt1 = getViewTypeFromOrientation(m1.imageOrientationPatient);
  if ([vt, vt0, vt1].includes('axial')) return 'axial';
  return vt;
}

function navigateToROIContour(SeriesUID, StructureSetUID, ROINumber) {
  let axial = '';
  let sagittal = '';
  let coronal = '';
  cornerstone.getEnabledElements().forEach((enabledElement) => {
    const { imageId } = enabledElement.image;
    const instance = cornerstone.metaData.get('instance', imageId);
    if (instance.SeriesInstanceUID === SeriesUID) {
      const viewType = checkViewTypeOfElement(enabledElement);
      if (viewType === 'axial') axial = enabledElement;
      if (viewType === 'sagittal') sagittal = enabledElement;
      if (viewType === 'coronal') coronal = enabledElement;
    }
  });
  const toolState = cornerstoneTools.getToolState(axial.element, 'stack');
  const { imageIds: ids } = toolState.data[0];
  const maxAreaSlice = getMaxAreaSliceWithTool(ids, StructureSetUID, ROINumber);

  if (maxAreaSlice.index === -1) return false;
  try {
    if (ids[maxAreaSlice.index]) {
      /**
       * find centroids of sagittal/coronal views and scroll to index
       * will skip this step if no sagittal/coronal view displayed
       * */
      const { centroid, index } = maxAreaSlice;
      const points = transformPointsToPhysicalById([centroid], ids[index]);
      const getCentroidImageIndex = (element) => {
        if (!element) return -1;
        const toolState = cornerstoneTools.getToolState(element, 'stack');
        const { imageIds: _ids } = toolState.data[0];
        return findNearestImageIndexFromPhysicalPoint(_ids, points[0]);
      };
      const findNearestImageIndexFromPhysicalPoint = (imageIds, point) => {
        const { minIndex } = imageIds.reduce(
          ({ minId, minDistance, minIndex }, id, index) => {
            const imagePlane = cornerstone.metaData.get('imagePlaneModule', id);
            const imagePosition = imagePlane.imagePositionPatient;
            const [x, y, z] = imagePosition;
            const distance = getDistance3D(point, { x, y, z });
            if (minDistance > distance) {
              return { minId: id, minDistance: distance, minIndex: index };
            }
            return { minId, minDistance, minIndex };
          },
          { minId: '', minDistance: Infinity, minIndex: 0 }
        );
        return minIndex;
      };
      const sagittalIdx = getCentroidImageIndex(sagittal.element);
      if (sagittalIdx !== -1) scrollToIndex(sagittal.element, sagittalIdx);
      const coronalIdx = getCentroidImageIndex(coronal.element);
      if (sagittalIdx !== -1) scrollToIndex(coronal.element, coronalIdx);
    }
    /** scroll to index in axial views */
    scrollToIndex(axial.element, maxAreaSlice.index);
    return true;
  } catch (err) {
    return false;
  }
}

function checkAndNavigate(StructureSetUID, ROINumber) {
  const axials = cornerstone.getEnabledElements().filter((enabledElement) => {
    const viewType = checkViewTypeOfElement(enabledElement);
    return viewType === 'axial';
  });

  let existing = false;
  axials.forEach((enabledElement) => {
    const { imageId } = enabledElement.image;
    const instanceMetaData = cornerstone.metaData.get('instance', imageId);
    const { SeriesInstanceUID: uid } = instanceMetaData;
    const exist = navigateToROIContour(uid, StructureSetUID, ROINumber);
    existing = existing || exist;
  });
  return existing;
}

export {
  refreshViewport,
  checkExistenceOfROIContour,
  navigateToROIContour,
  checkAndNavigate,
  getMaxAreaSlice,
};
