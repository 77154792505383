/** Action Creators:
 *  https://redux.js.org/basics/actions#action-creators
 */

import {
  CLEAR_VIEWPORT,
  SET_VIEWPORT,
  SET_VIEWPORT_ACTIVE,
  SET_VIEWPORT_LAYOUT,
  SET_VIEWPORT_LAYOUT_AND_DATA,
} from './constants/ActionTypes.js';

/**
 * The definition of a viewport layout.
 *
 * @typedef {Object} ViewportLayout
 * @property {number} numRows -
 * @property {number} numColumns -
 * @property {array} viewports -
 */

/**
 * VIEWPORT
 */
export const setViewportSpecificData = (
  viewportIndex,
  viewportSpecificData
) => ({
  type: SET_VIEWPORT,
  viewportIndex,
  viewportSpecificData,
});

export const setViewportActive = viewportIndex => ({
  type: SET_VIEWPORT_ACTIVE,
  viewportIndex,
});

/**
 * @param {ViewportLayout} layout
 */
export const setLayout = ({ numRows, numColumns, viewports }) => ({
  type: SET_VIEWPORT_LAYOUT,
  numRows,
  numColumns,
  viewports,
});

/**
 * @param {number} layout.numRows
 * @param {number} layout.numColumns
 * @param {array} viewports
 */
export const setViewportLayoutAndData = (
  { numRows, numColumns, viewports },
  viewportSpecificData
) => ({
  type: SET_VIEWPORT_LAYOUT_AND_DATA,
  numRows,
  numColumns,
  viewports,
  viewportSpecificData,
});

export const clearViewportSpecificData = viewportIndex => ({
  type: CLEAR_VIEWPORT,
  viewportIndex,
});

/**
 * CORNERSTONE
 */
export const setScrollSync = isToggled => ({
  type: 'SET_SCROLL_SYNC',
  payload: { isToggled },
});
export const setPanZoomSync = isToggled => ({
  type: 'SET_PAN_ZOOM_SYNC',
  payload: { isToggled },
});
export const setWwwcSync = isToggled => ({
  type: 'SET_WWWC_SYNC',
  payload: { isToggled },
});

/**
 * RT-PANEL
 */
export const setDICOMRTImageData = (imageId, data) => ({
  type: 'SET_DICOMRT_IMAGE_DATA',
  imageId,
  data,
});
export const setIsComputing = isComputing => ({
  type: 'SET_IS_COMPUTING',
  payload: { isComputing },
});
export const setIsLinking = isLinking => ({
  type: 'SET_IS_LINKING',
  payload: { isLinking },
});
export const setPointLabel = (isToggled) => ({
  type: 'SET_POINT_LABEL',
  payload: { isToggled },
});

const actions = {
  /**
   * VIEWPORT
   */
  setViewportActive,
  setViewportSpecificData,
  setViewportLayoutAndData,
  setLayout,
  clearViewportSpecificData,
  /**
   * CORNERSTONE
   */
  setScrollSync,
  setPanZoomSync,
  setWwwcSync,
  /**
   * RT-PANEL
   */
  setDICOMRTImageData,
  setIsComputing,
  setIsLinking,
  setPointLabel,
};

export default actions;
