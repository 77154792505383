import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Modal,
  InputBox,
  TextField,
  MenuItem,
  Typography,
  Divider,
  Icon,
} from '@platform/ui';
import {
  getLinkTypes,
  getLinkLocations,
} from '../../../modules/dicom-measurement/src/constants';

const interpretedTypes = [
  { key: 'GTV', label: 'GTV' },
  { key: 'CTV', label: 'CTV' },
  { key: 'PTV', label: 'PTV' },
  { key: 'ORGAN', label: 'Organ' },
];

export function NewROIForm({
  name: defaultName,
  onFinished,
  guideline = 'default',
  links = [],
  link = {},
  label,
  color = 'secondary',
  variant = 'contained',
  fullWidth = true,
  disabled,
  CustomButton,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState(defaultName);
  const [interpretedType, setInterpretedType] = useState('GTV');
  const [open, setOpen] = useState(false);
  const [linkID, setLinkID] = useState(link._id || 'default');
  const _link = link._id ? link : links.find((link) => link._id === linkID);
  const [location, setLocation] = useState('default');
  const [type, setType] = useState('default');
  const [description, setDescription] = useState('');
  const [measurable, setMeasurable] = useState(true);
  const [equivocal, setEquivocal] = useState(true);

  const reset = () => {
    setName(defaultName);
    setInterpretedType('GTV');
    setLinkID(link._id ? _link?._id || 'default' : 'default');
    setLocation(link._id ? _link?.location || 'default' : 'default');
    setType(link._id ? _link?.type || 'default' : 'default');
    setDescription(link._id ? _link?.description || '' : '');
  };

  const handleOpen = () => {
    reset();
    setOpen(true);
  };

  const handleCreateClick = async () => {
    try {
      setIsProcessing(true);
      const rois = [
        {
          ROIName: name,
          RTROIObservations: { RTROIInterpretedType: interpretedType },
          link_id: linkID === 'default' ? null : linkID,
          too_small_to_measure: _link?.type === 'TARGET' ? !measurable : null,
          unequivocal: _link?.type === 'NEW' ? !equivocal : null,
        },
      ].map((roi) => {
        Object.keys(roi).forEach((key) => {
          if (roi[key] == null) delete roi[key];
        });
        return roi;
      });
      await onFinished(rois, type, location, description);
      reset();
      setOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  return (
    <>
      {CustomButton ? (
        <CustomButton onClick={handleOpen} disabled={disabled}>
          {label}
        </CustomButton>
      ) : (
        <Button
          data-cy='create-roi-button'
          disabled={disabled || isProcessing}
          onClick={handleOpen}
          color={color}
          variant={variant}
          fullWidth={fullWidth}
        >
          <Icon
            className='plus-icon'
            name='plus'
            width='20px'
            height='20px'
            style={{
              color: disabled
                ? '#5A5A5A'
                : color === 'primary'
                ? '#BFCBED'
                : '#000000',
              background: 'transparent',
            }}
          />
          ROI
        </Button>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <Typography component='h2' variant='h6' color='primary' gutterBottom>
            {'New ROI'}
          </Typography>
          <Divider />
          <InputBox label={'Name:'}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </InputBox>
          <InputBox label={'Interpreted Type:'}>
            <TextField
              select
              value={interpretedType}
              onChange={(e) => setInterpretedType(e.target.value)}
              fullWidth
            >
              {interpretedTypes.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </InputBox>
          <InputBox label={'Link:'}>
            {link._id ? (
              <TextField
                select
                value={linkID}
                onChange={(e) => setLinkID(e.target.value)}
                fullWidth
              >
                <MenuItem key={link._id} value={link._id}>
                  {link.name}
                </MenuItem>
              </TextField>
            ) : (
              <TextField
                select
                value={linkID}
                onChange={(e) => setLinkID(e.target.value)}
                fullWidth
              >
                <MenuItem key={'default'} value={'default'}>
                  {'------ (CREATE NEW LINK) ------'}
                </MenuItem>
                {links.map((i) => (
                  <MenuItem key={i._id} value={i._id}>
                    {i.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </InputBox>
          {linkID === 'default' && (
            <NewLinkBox
              guideline={guideline}
              location={location}
              setLocation={setLocation}
              type={type}
              setType={setType}
              description={description}
              setDescription={setDescription}
            />
          )}
          <AttributeBox
            type={_link?.type}
            measurable={measurable}
            setMeasurable={setMeasurable}
            equivocal={equivocal}
            setEquivocal={setEquivocal}
          />
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Button
            data-cy='submit-new-roi-button'
            onClick={handleCreateClick}
            color='primary'
            variant='outlined'
            medium
            disabled={isProcessing}
            fullWidth
          >
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
}
NewROIForm.propTypes = {
  name: PropTypes.string,
  onFinished: PropTypes.func,
  disabled: PropTypes.bool,
};

const NewLinkBox = ({
  guideline,
  location,
  setLocation,
  type,
  setType,
  description,
  setDescription,
}) => {
  return (
    <>
      <InputBox label={'Location/Type:'}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              value={location}
              onChange={(evt) => setLocation(evt.target.value)}
              fullWidth
            >
              <MenuItem key={'default'} value={'default'}>
                {'------'}
              </MenuItem>
              {getLinkLocations().map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              value={type}
              onChange={(evt) => setType(evt.target.value)}
              fullWidth
            >
              <MenuItem key={'default'} value={'default'}>
                {'------'}
              </MenuItem>
              {getLinkTypes(guideline).map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </InputBox>
      <InputBox label={'Description:'}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </InputBox>
    </>
  );
};

const AttributeBox = ({
  type,
  measurable,
  setMeasurable,
  equivocal,
  setEquivocal,
}) => {
  switch (type) {
    case 'TARGET':
      return (
        <InputBox label={'Target Lesion:'}>
          <TextField
            select
            value={measurable}
            onChange={(e) => setMeasurable(e.target.value)}
            fullWidth
          >
            <MenuItem key={0} value={true}>
              MEASURABLE
            </MenuItem>
            <MenuItem key={1} value={false}>
              TOO SMALL TO MEASURE
            </MenuItem>
          </TextField>
        </InputBox>
      );
    case 'NEW':
      return (
        <InputBox label={'New Lesion:'}>
          <TextField
            select
            value={equivocal}
            onChange={(e) => setEquivocal(e.target.value)}
            fullWidth
          >
            <MenuItem key={0} value={true}>
              EQUIVOCAL
            </MenuItem>
            <MenuItem key={1} value={false}>
              UNEQUIVOCAL
            </MenuItem>
          </TextField>
        </InputBox>
      );
  }
};
