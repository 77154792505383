import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import {
  Body,
  Container,
  Grid,
  InputBox,
  Select,
  H6,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LoadingSpinner,
} from '@platform/ui';
import ExportAlert from '../ExportAlert';
import * as models from '../../../modules/dicom-measurement/src';

const { math } = models;
const { round } = math;

export const MeasurementSection = ({
  imageSets,
  seriesInstanceUID,
  structureSetUID,
}) => {
  const [rois, setRois] = useState([]);
  const [timepoint, setTimepoint] = useState({ ROIMeasurements: [] });
  const [isCalculating, setIsCalculating] = useState(false);
  const [selectedROINumber, setSelectedROINumber] = useState(-1);

  useEffect(() => {
    (async () => {
      setIsCalculating(true);
      setSelectedROINumber(-1);
      await new Promise((resolve) => setTimeout(resolve, 0));
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      const { ROIContours } = editModule.getters.structureSet(structureSetUID);
      setRois(ROIContours);
      const imageSet = imageSets.find(
        (set) => set.SeriesInstanceUID === seriesInstanceUID
      );
      const timepoint = models.getTimepointWithTool(
        imageSet,
        ROIContours,
        false
      );
      setTimepoint(timepoint);
      setIsCalculating(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureSetUID]);

  const volumeMM = (
    <span key={'volume'}>
      Volume(mm<sup>3</sup>)
    </span>
  );

  return (
    <Body>
      <Container>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow index={-1}>
              {[
                ['Name', '20%'],
                ['Long Axis(mm)', '20%'],
                ['Short Axis(mm)', '20%'],
                [volumeMM, '20%'],
                ['Interpreted Type', '20%'],
              ].map(([text, width], index) => (
                <TableCell key={index} width={width} header={1}>
                  <H6>{text}</H6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isCalculating && (
            <TableBody>
              {rois
                .filter((roi) =>
                  [-1, roi.ROINumber].includes(selectedROINumber)
                )
                .map((roi, index) => {
                  const measurement = timepoint.ROIMeasurements.find(
                    (l) => l.ROINumber === roi.ROINumber
                  );
                  const longAxis = round(measurement['maxLongAxisValue'], -2);
                  const shortAxis = round(measurement['maxShortAxisValue'], -2);
                  const volume = round(measurement['volume'], -2);
                  return (
                    <Row
                      key={structureSetUID + roi.ROINumber}
                      index={index}
                      roi={roi}
                      longAxis={longAxis}
                      shortAxis={shortAxis}
                      volume={volume}
                    />
                  );
                })}
            </TableBody>
          )}
        </Table>
        {isCalculating && (
          <div style={{ margin: '15px 0' }}>
            <LoadingSpinner />
          </div>
        )}
      </Container>
      <div>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={3} display='flex'>
            <InputBox label={'Series Date:'} ratio={1}>
              <Select value={'default'}>
                <option key={'default'} value={'default'}>
                  {timepoint.ClinicalTrialTimePointID}
                </option>
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={3} display='flex'>
            <InputBox label={'ROI Name:'} ratio={1}>
              <Select
                value={selectedROINumber}
                onChange={(evt) =>
                  setSelectedROINumber(Number(evt.target.value))
                }
              >
                <option key={-1} value={-1}>
                  ------
                </option>
                {rois.map((roi) => (
                  <option key={roi.ROINumber} value={roi.ROINumber}>
                    {roi.ROIName}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    </Body>
  );
};
MeasurementSection.propTypes = {
  imageSets: PropTypes.array,
};

const Row = ({ index, roi, longAxis, shortAxis, volume }) => {
  const { ROIName, RTROIObservations } = roi;
  const { RTROIInterpretedType } = RTROIObservations;
  const interpretedTypeMap = {
    GTV: 'GTV',
    CTV: 'CTV',
    PTV: 'PTV',
    ORGAN: 'Organ',
  };
  return (
    <TableRow index={index}>
      {[ROIName, longAxis, shortAxis, volume].map((text, i) => (
        <TableCell key={i}>
          <H6>{text}</H6>
        </TableCell>
      ))}
      <TableCell>
        <H6>{interpretedTypeMap[RTROIInterpretedType]}</H6>
      </TableCell>
    </TableRow>
  );
};
Row.propTypes = {
  index: PropTypes.number,
  roi: PropTypes.shape({
    ROIName: PropTypes.string,
    ROINumber: PropTypes.number,
    RTROIObservations: PropTypes.shape({
      RTROIInterpretedType: PropTypes.string,
    }),
  }),
  longAxis: PropTypes.number,
  shortAxis: PropTypes.number,
  volume: PropTypes.number,
};
