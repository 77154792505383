import React from 'react';
import { Container, CssBaseline, Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Firebase } from '@platform/core';
import Hero from '../../static/images/HeroTheme.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
  },
}));

export default function ErrorPage({ title, content }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth='lg' className={classes.container}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} sm={4}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                <img src={Hero} alt='Vysioneer' style={{ width: '100%' }} />
                <div style={{ width: '80%', height: '60px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h3>{title}</h3>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {content}
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export function SystemError() {
  const title = 'SITE UNDER MAINTENANCE';
  const content =
    'Sorry for the inconvenience. The page is currently down from maintenance. Please check back soon.';
  return <ErrorPage title={title} content={content} />;
}

export function UserError() {
  const title = 'INVALID USER';
  const content = (
    <div style={{ justifyContent: 'center' }}>
      <p>
        Cannot find the user account. Please contact the system admin. Or
        <Button size='small' color='primary' onClick={() => Firebase.signOut()}>
          Use another account
        </Button>
        .
      </p>
    </div>
  );
  return <ErrorPage title={title} content={content} />;
}
