import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import { store, API } from '@platform/viewer';
import { redux } from '@platform/core';

const { actions } = redux;
// Cornerstone 3rd party dev kit imports
const draw = importInternal('drawing/draw');
const getNewContext = importInternal('drawing/getNewContext');

export function pointPainter(toolState, structureSetSeriesInstanceUid) {
  const module = cornerstoneTools.getModule('rtstruct-edit');
  const state = _.cloneDeep(toolState);
  let ROINumber = module.getters.selectedROINumber();
  const { colorArray } = module.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );
  let points = [];
  if (!_.isNumber(ROINumber)) return;
  return {
    getState: function() {
      return state;
    },
    commit: async function(evt) {
      const { image } = evt.detail;
      const { cachedLut, imageId } = image;
      const body = {
        ...getDICOMwebKeys(imageId),
        mode: 'point',
        prompt: {
          point: points.map(p => [p.x, p.y, p.label]),
          mask_contour: [],
        },
        image_window: {
          width: cachedLut.windowWidth,
          level: cachedLut.windowCenter,
        },
      };
      const { setDICOMRTImageData } = actions;
      try {
        store.dispatch(setDICOMRTImageData(imageId, { isLoading: true }));
        const mask_contour = await getContour(body);
        state.data = [
          ...state.data.filter(x => x.ROINumber !== ROINumber),
          ...mask_contour.map(polygon => ({
            structureSetSeriesInstanceUid,
            ROINumber,
            handles: { points: polygon.map(p => ({ x: p[0], y: p[1] })) },
          })),
        ];
      } catch (err) {
        window.alert('Service not available. Please use other tools.');
      } finally {
        store.dispatch(setDICOMRTImageData(imageId, { isLoading: false }));
      }
      return true;
    },
    update: function(evt) {
      const config = module.getters.painterConfig('point');
      const element = evt.detail.element;
      const eventData = evt.detail;
      const newPoint = eventData.currentPoints.canvas;
      const newPixel = cornerstone.canvasToPixel(element, newPoint);
      const lastPixel = points[points.length - 1];

      if (
        newPixel.x === lastPixel?.x &&
        newPixel.y === lastPixel?.y &&
        config.label === lastPixel?.label
      ) {
        return true;
      }
      points.push({ ...newPixel, label: config.label });
      return true;
    },
    cursor: function(evt) {
      const { element } = evt.detail;
      const canvas = cornerstone.getEnabledElement(element).canvas;
      const context = getNewContext(canvas);
      for (const _point of points) {
        const point = cornerstone.pixelToCanvas(element, _point);
        draw(context, context => {
          const radius = 5;
          context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
          context.fillStyle =
            _point.label === 1
              ? `rgba(${colorArray.join(',')}, 1)`
              : `rgba(${colorArray.join(',')}, 0.3)`;
          context.beginPath();
          context.arc(point.x, point.y, radius, 0, 2 * Math.PI);
          context.fill();
          context.stroke();
        });
      }
    },
    clear: function() {
      points = [];
    },
  };
}

async function getContour(body) {
  const api = new API();
  const data = await api.segment(body);
  return data;
}

function getDICOMwebKeys(id) {
  return {
    site_id: id.substring(
      id.lastIndexOf('/site/') + 6,
      id.indexOf('/trial/')
    ),
    trial_id: id.substring(
      id.lastIndexOf('/trial/') + 7,
      id.indexOf('/dicom-web/')
    ),
    study_uid: id.substring(
      id.lastIndexOf('/studies/') + 9,
      id.indexOf('/series/')
    ),
    series_uid: id.substring(
      id.lastIndexOf('/series/') + 8,
      id.indexOf('/instances/')
    ),
    instance_uid: id.substring(
      id.lastIndexOf('/instances/') + 11,
      id.indexOf('/frames/')
    ),
  };
}
