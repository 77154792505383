export default function transformPointsToPhysical(
  points,
  {
    RowCosines,
    ColumnCosines,
    RowPixelSpacing,
    ColumnPixelSpacing,
    ImagePositionPatient = [0, 0, 0],
  }
) {
  // See Equation C.7.6.2.1-1 of the DICOM standard
  let physicalPoints = [];
  for (let i = 0; i < points.length; i++) {
    let Px =
      RowCosines[0] * ColumnPixelSpacing * points[i].x +
      ColumnCosines[0] * RowPixelSpacing * points[i].y +
      ImagePositionPatient[0];
    let Py =
      RowCosines[1] * ColumnPixelSpacing * points[i].x +
      ColumnCosines[1] * RowPixelSpacing * points[i].y +
      ImagePositionPatient[1];
    let Pz =
      RowCosines[2] * ColumnPixelSpacing * points[i].x +
      ColumnCosines[2] * RowPixelSpacing * points[i].y +
      ImagePositionPatient[2];
    physicalPoints.push({ x: Px, y: Py, z: Pz });
  }
  return physicalPoints;
}
