import getTimepointStats from './getTimepointStats';
import { getTargetResponses } from './getTargetResponse';
import { getNonTargetResponses } from './getNonTargetResponse';
import { getNewLesionResponses } from './getNewLesionResponse';
import { getAssessedTimepoints } from './getTimepoints';
import {
  getImmuneTargetResponse,
  getImmuneNonTargetResponse,
  getImmuneNewLesionResponse,
  getImmuneOverallResponse,
} from './getImmuneResponse';

export default function getAIAssessments(timepoints, links, criteria) {
  if (timepoints.length === 0) return [];
  if (links.length === 0) return [];
  if (!criteria?.guidelineId) return [];
  switch (criteria.guidelineId) {
    case 'iRECIST': {
      const ai_assessments = [];
      for (let i = 1; i <= timepoints.length; i++) {
        const _timepoints = timepoints.slice(0, i);
        const assessment = getImmuneTimepointAssessment(
          _timepoints,
          links,
          criteria
        );
        ai_assessments.push(assessment);
      }
      return ai_assessments;
    }
    default: {
      /** target lesion responses */
      const target_ids = links
        .filter((l) => l.type === 'TARGET')
        .map((l) => l._id);
      const targetStats = getTimepointStats(
        timepoints,
        target_ids,
        [],
        criteria
      );
      const targetResponses = getTargetResponses(targetStats, criteria);

      /** non-target lesion responses */
      const non_target_ids = links
        .filter((l) => l.type === 'NON-TARGET')
        .map((l) => l._id);
      const nonTargetStats = getTimepointStats(
        timepoints,
        non_target_ids,
        [],
        criteria
      );
      const nonTargetResponses = getNonTargetResponses(
        nonTargetStats,
        criteria
      );

      /** new lesion responses */
      const new_ids = links.filter((l) => l.type === 'NEW').map((l) => l._id);
      const newLesionResponses = getNewLesionResponses(timepoints, new_ids);

      /** overall responses */
      const ai_assessments = getAssessedTimepoints(
        timepoints,
        targetResponses,
        nonTargetResponses,
        newLesionResponses,
        []
      );
      return ai_assessments;
    }
  }
}

export function getImmuneAssessments(timepoints, links, criteria) {
  const ai_assessments = [];
  for (let i = 1; i <= timepoints.length; i++) {
    const _timepoints = timepoints.slice(0, i).map((t) => {
      const previous_ai_assessment = ai_assessments.find((a) => a.id === t.id);
      if (previous_ai_assessment) {
        return {
          ...t,
          target_response: previous_ai_assessment.targetResponse.result,
          non_target_response: previous_ai_assessment.nonTargetResponse.result,
          new_lesion_response: previous_ai_assessment.newLesionResponse.result,
          steroids_use: previous_ai_assessment.steroidsUse.result,
          clinical_status: previous_ai_assessment.clinicalStatus.result,
          overall_response: previous_ai_assessment.overallResponse.result,
        };
      }
      return t;
    });

    const assessment = getImmuneTimepointAssessment(
      _timepoints,
      links,
      criteria
    );
    ai_assessments.push(assessment);
  }
  return ai_assessments.map((a) => ({
    id: a.id,
    target_response: a.targetResponse.result,
    non_target_response: a.nonTargetResponse.result,
    new_lesion_response: a.newLesionResponse.result,
    steroids_use: a.steroidsUse.result,
    clinical_status: a.clinicalStatus.result,
    overall_response: a.overallResponse.result,
  }));
}

function getImmuneTimepointAssessment(_timepoints, links, criteria) {
  /** target lesion response */
  const target_ids = links.filter((l) => l.type === 'TARGET').map((l) => l._id);
  const targetStats = getTimepointStats(_timepoints, target_ids, [], criteria);
  const targetResponse = getImmuneTargetResponse(targetStats, criteria);

  /** non-target lesion response */
  const non_target_ids = links
    .filter((l) => l.type === 'NON-TARGET')
    .map((l) => l._id);
  const nonTargetStats = getTimepointStats(
    _timepoints,
    non_target_ids,
    [],
    criteria
  );
  const nonTargetResponse = getImmuneNonTargetResponse(
    nonTargetStats,
    criteria
  );

  /** new lesion response */
  const new_ids = links
    .filter((l) => l.type === 'NL-TARGET' || l.type === 'NL-NON-TARGET')
    .map((l) => l._id);
  const new_target_ids = links
    .filter((l) => l.type === 'NL-TARGET')
    .map((l) => l._id);
  const newLesionStats = getTimepointStats(
    _timepoints,
    new_target_ids,
    [],
    criteria
  );
  const newLesionStatsWithLesions = newLesionStats.map((t, index) => ({
    ...t,
    Lesions: _timepoints[index].Lesions.filter(({ link_id }) =>
      new_ids.includes(link_id)
    ),
  }));
  const newLesionResponse = getImmuneNewLesionResponse(
    newLesionStatsWithLesions,
    criteria
  );

  /** overall response */
  const current_timepoint = _timepoints[_timepoints.length - 1];
  const steroidsUse = { result: 'N/A', message: '' };
  const clinicalStatus = { result: 'N/A', message: '' };
  const overallResponse = getImmuneOverallResponse(
    [
      ..._timepoints.slice(0, _timepoints.length - 1),
      {
        target_response: targetResponse.result,
        non_target_response: nonTargetResponse.result,
        new_lesion_response: newLesionResponse.result,
        steroids_use: steroidsUse.result,
        clinical_status: clinicalStatus.result,
      },
    ],
    criteria
  );
  return {
    id: current_timepoint.id,
    targetResponse,
    nonTargetResponse,
    newLesionResponse,
    steroidsUse,
    clinicalStatus,
    overallResponse,
  };
}
