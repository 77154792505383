import React from 'react';
import { Box, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    height: 420,
    width: 600,
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    border: 'none',
    borderRadius: 5,
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function MuiModal(props) {
  const { children, open, onClose } = props;
  const classes = useStyles();

  const body = (
    <div className={classes.paper}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </div>
  );

  return (
    <Modal open={open} onClose={onClose}>
      {body}
    </Modal>
  );
}
