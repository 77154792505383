import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Box, Button, Icon, TableListItem } from '@platform/ui';
import { ROIAttribute } from './ROIContourItem';
import { NewROIForm } from '../forms/NewROIForm';

export const LinkItem = ({
  guideline,
  link,
  isLinking,
  selected,
  onConfirm,
  onCommand,
  getNewROIName,
  onROICreate,
  onROIUpdate,
  onStatusChange,
  onNavigate,
}) => {
  const { _id: id, name, location, description } = link;
  const current = link.current_rois.length > 0;
  const present = link.rois.length > 0;
  const absent = link.status === 'absent';

  return (
    <>
      <TableListItem
        itemKey={id}
        itemIndex={''}
        itemClass={''}
        itemMeta={<div></div>}
        itemMetaClass='item-color-section'
        itemSelected={selected}
        onItemClick={() => {}}
        style={{ padding: '0 4px 0 0', cursor: 'pointer' }}
      >
        <div style={{ padding: '0 6px 0 0' }}>
          <Box>
            <Label>{location}</Label>
            <Chip>{name}</Chip>
          </Box>
          {description && (
            <Box>
              <Label>{description}</Label>
            </Box>
          )}
          <Box style={{ margin: '6px 0' }}>
            <Button
              data-cy='localize-button'
              onClick={onNavigate}
              color='primary'
              variant='outlined'
              style={{ padding: '4px 2px' }}
            >
              <Icon
                name='crosshair'
                width='24px'
                height='24px'
                style={{ background: 'transparent' }}
              />
            </Button>
            {present ? (
              <Tabs>
                <Tab disabled>ABSENT</Tab>
                <Tab selected={present} disabled={!current}>
                  PRESENT
                </Tab>
              </Tabs>
            ) : (
              <Tabs>
                <Tab
                  selected={absent}
                  onClick={() => onStatusChange(absent ? '' : 'absent')}
                  disabled={link.disabled}
                >
                  ABSENT
                </Tab>
                <NewROIForm
                  name={getNewROIName()}
                  onFinished={(rois) => onROICreate(rois)}
                  guideline={guideline}
                  link={link}
                  disabled={link.disabled}
                  label={'PRESENT'}
                  CustomButton={Tab}
                />
              </Tabs>
            )}
          </Box>
        </div>
      </TableListItem>
      {selected && !isLinking && link.current_rois.length > 0 && (
        <ROIAttribute
          type={link?.type}
          roi={link.current_rois[0]}
          onConfirm={onConfirm}
          onCommand={onCommand}
          onUpdate={(key, value) =>
            onROIUpdate(link.current_rois[0], key, value)
          }
        />
      )}
    </>
  );
};
LinkItem.propTypes = {
  id: PropTypes.string,
};

const Label = styled.p`
  margin: 0;
  padding: 0;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 16px;
`;
const Chip = styled.div`
  color: white;
  background-color: var(--snackbar-success);
  margin: 5px 0;
  padding: 0 15px;
  border-radius: 10px;
`;
const Tabs = styled.div`
  display: flex;
  grid-gap: 0 4px;
`;
const Tab = styled.button`
  width: 100%;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 12px;
  border: 0;
  border-radius: 4px;
  outline: 0;
  user-select: none;
  -moz-appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.03em;
  white-space: nowrap;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-secondary-color);
  background-color: var(--ui-gray);
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      color: #000000;
      background-color: var(--active-color);
    `}
  &:hover {
    color: #000000;
    background-color: var(--active-color);
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
    cursor: not-allowed;
    ${(props) =>
      props.selected &&
      css`
        color: #000000;
        background-color: var(--active-color);
        opacity: 0.4;
        cursor: default;
      `}
  }
`;
