export const defaultState = {
  cornerstone: {
    isScrollSync: false,
    isPanZoomSync: false,
    isWwwcSync: false,
  },
  dicomRT: {
    isComputing: false,
    isLinking: false,
    isPointLabelToggled: true,
    images: {},
  },
};

const extensions = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_EXTENSION_DATA': {
      const extensionName = action.extension;
      const currentData = state[extensionName] || {};
      const incomingData = action.data;
      const extension = {
        [extensionName]: { ...currentData, ...incomingData },
      };
      return { ...state, ...extension };
    }
    case 'SET_SCROLL_SYNC': {
      const { isToggled } = action.payload;
      return {
        ...state,
        cornerstone: { ...state.cornerstone, isScrollSync: isToggled },
      };
    }
    case 'SET_PAN_ZOOM_SYNC': {
      const { isToggled } = action.payload;
      return {
        ...state,
        cornerstone: { ...state.cornerstone, isPanZoomSync: isToggled },
      };
    }
    case 'SET_WWWC_SYNC': {
      const { isToggled } = action.payload;
      return {
        ...state,
        cornerstone: { ...state.cornerstone, isWwwcSync: isToggled },
      };
    }
    case 'SET_DICOMRT_IMAGE_DATA': {
      const imageId = action.imageId;
      const imageData = action.data;
      return {
        ...state,
        dicomRT: {
          ...state.dicomRT,
          images: {
            ...state.dicomRT.images,
            [imageId]: {
              ...state.dicomRT.images[imageId],
              ...imageData,
            },
          },
        },
      };
    }
    case 'SET_IS_COMPUTING': {
      const { isComputing } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, isComputing } };
    }
    case 'SET_IS_LINKING': {
      const { isLinking } = action.payload;
      return { ...state, dicomRT: { ...state.dicomRT, isLinking } };
    }
    case 'SET_POINT_LABEL': {
      const { isToggled } = action.payload;
      return {
        ...state,
        dicomRT: { ...state.dicomRT, isPointLabelToggled: isToggled },
      };
    }
    default: {
      return state;
    }
  }
};

export default extensions;
