const TOOL_NAMES = {
  SELECT_TOOL: 'SelectTool',
  RTSTRUCT_DISPLAY_TOOL: 'RTStructDisplayTool',
  EDGE_TOOL: 'EdgeTool',
  POLYGON_TOOL: 'PolygonTool',
  DELETE_TOOL: 'DeleteTool',
  REPULSER_TOOL: 'RepulserTool',
  SPHERE_THRESHOLD_TOOL: 'SphereThresholdTool',
  REGION_GROWING_TOOL: 'RegionGrowingTool',
  BOOLEAN_TOOL: 'BooleanTool',
  MERGE_TOOL: 'MergeTool',
  INTERPOLATION_TOOL: 'InterpolationTool',
  EXPANSION_TOOL: 'ExpansionTool',
  POINT_TOOL: 'PointTool',
  BOUNDING_BOX_TOOL: 'BoundingBoxTool',
  BOUNDING_BOX_3D_TOOL: 'BoundingBox3DTool',
};

export default TOOL_NAMES;
