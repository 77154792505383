export default function getHotkeys() {
  return [
    { commandName: 'scaleUpViewport', label: 'Zoom In', keys: ['+'] },
    { commandName: 'scaleDownViewport', label: 'Zoom Out', keys: ['-'] },
    { commandName: 'nextImage', label: 'Next Image', keys: ['down'] },
    { commandName: 'previousImage', label: 'Previous Image', keys: ['up'] },
    {
      commandName: 'setRepulserActive',
      label: 'Set Repulser Active',
      keys: ['ctrl+r'],
    },
    {
      commandName: 'setSelectActive',
      label: 'Set Select Active',
      keys: ['ctrl+s'],
    },
    {
      commandName: 'interpolateContour',
      label: 'Copy Contour',
      keys: ['ctrl+v'],
    },
    {
      commandName: 'clearActiveViewportContours',
      label: 'Delete Contours',
      keys: ['ctrl+x'],
    },
    {
      commandName: 'undoRT',
      label: 'Undo RT',
      keys: ['ctrl+z'],
    },
    {
      commandName: 'redoRT',
      label: 'Redo RT',
      keys: ['ctrl+shift+z'],
    },
    {
      commandName: 'clearRefLine',
      label: 'Clear Reference Line',
      keys: ['esc'],
    },
    {
      commandName: 'switchPointLabel',
      label: 'Switch Point Label',
      keys: ['space'],
    },
    {
      commandName: 'windowLevelPreset1',
      label: 'W/L Preset 1',
      keys: ['1'],
    },
    {
      commandName: 'windowLevelPreset2',
      label: 'W/L Preset 2',
      keys: ['2'],
    },
    {
      commandName: 'windowLevelPreset3',
      label: 'W/L Preset 3',
      keys: ['3'],
    },
    {
      commandName: 'windowLevelPreset4',
      label: 'W/L Preset 4',
      keys: ['4'],
    },
    {
      commandName: 'windowLevelPreset5',
      label: 'W/L Preset 5',
      keys: ['5'],
    },
    {
      commandName: 'windowLevelPreset6',
      label: 'W/L Preset 6',
      keys: ['6'],
    },
    {
      commandName: 'windowLevelPreset7',
      label: 'W/L Preset 7',
      keys: ['7'],
    },
    {
      commandName: 'windowLevelPreset8',
      label: 'W/L Preset 8',
      keys: ['8'],
    },
  ];
}
