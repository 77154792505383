const guideline = {
  guidelineId: 'RANOBM',
  guidelineName: 'RANO-BM',
  measurementTechnique: 'BIDIMENSIONAL',
  maxNumberOfTargets: 5,
  maxNumberOfTargetsPerOrgan: 5,
  measurableLongAxis: 10,
  measurableShortAxis: 5,
  measurableVolume: 0,
  measurableLongAxisOfLymphNode: 15,
  measurableShortAxisOfLymphNode: 15,
  measurableVolumeOfLymphNode: 0,
  measurableLongAxisOfNewLesion: 5,
  measurableShortAxisOfNewLesion: 0,
  measurableVolumeOfNewLesion: 0,
  minDiameterIncrease: 0.2,
  minDiameterDecrease: 0.3,
  minVolumeIncrease: 0.65,
  minVolumeDecrease: 0.65,
  smallDiameterChange: 3,
  smallVolumeChange: 0,
  absoluteDiameterChange: 5,
  absoluteSumOfDiameterChange: 0,
  absoluteVolumeChange: 0,
  absoluteSumOfVolumeChange: 0,
};

export default guideline;
