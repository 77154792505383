import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Dynamic Import Routes (CodeSplitting)
const ViewerRouting = lazy(() =>
  import(/* webpackChunkName: "Routing" */ './Routing.js')
);
const PlatformRouting = lazy(() =>
  import(
    /* webpackChunkName: "PlatformRouting" */ '../cloud/PlatformRouting.js'
  )
);
const ViewerLocalFileData = lazy(() =>
  import(
    /* webpackChunkName: "ViewerLocalFileData" */ './ViewerLocalFileData.js'
  )
);

const ViewerRoutes = () => {
  return (
    <Routes>
      <Route path='/viewer' element={<ViewerRouting />} />
      <Route path='/local' element={<ViewerLocalFileData />}/>
      <Route path='/*' element={<PlatformRouting />} />
    </Routes>
  );
};

export default ViewerRoutes;
