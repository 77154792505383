import React from 'react';
import PropTypes from 'prop-types';

import { ScrollableArea } from '@platform/ui';
import { RTDisplaySettings } from '../settings/RTDisplaySettings';
import { RegionGrowingToolSettings } from '../settings/RegionGrowingToolSettings';
import { SphereThresholdToolSettings } from '../settings/SphereThresholdToolSettings';

export const ToolSettingsSection = () => {
  return (
    <ScrollableArea>
      <div style={{ padding: '0 0 40px' }}>
        <RTDisplaySettings />
        <RegionGrowingToolSettings />
        <SphereThresholdToolSettings />
      </div>
    </ScrollableArea>
  );
};
ToolSettingsSection.propTypes = {};
