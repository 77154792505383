import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';

import { RoundedButtonGroup } from '@platform/ui';
import { MeasurementSection } from './sections/MeasurementSection';
import { LinkSection } from './sections/LinkSection';
import theme from '../theme';

const Panel = (props) => {
  const { children, value, index } = props;
  return value === index && children;
};

export default function SubjectReviewModal({
  imageSets,
  structureSetUID,
  seriesInstanceUID,
  seriesStructureMap,
  links,
  timepoints,
  snapshots,
}) {
  const [sectionName, setSectionName] = useState('link');
  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: '0 0 10px 0' }}>
        <RoundedButtonGroup
          options={[
            { value: 'measurement', label: 'Measurements' },
            { value: 'link', label: 'Links' },
          ]}
          value={sectionName}
          onValueChanged={(value) => setSectionName(value)}
        />
      </div>
      <div style={{ height: '70vh', minHeight: '300px', maxHeight: '70vh' }}>
        <Panel value={sectionName} index={'measurement'}>
          <MeasurementSection
            imageSets={imageSets}
            seriesInstanceUID={seriesInstanceUID}
            structureSetUID={structureSetUID}
          />
        </Panel>
        <Panel value={sectionName} index={'link'}>
          <LinkSection
            imageSets={imageSets}
            seriesInstanceUID={seriesInstanceUID}
            seriesStructureMap={seriesStructureMap}
            links={links}
            timepoints={timepoints}
            snapshots={snapshots}
          />
        </Panel>
      </div>
    </ThemeProvider>
  );
}
