import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import { useDrag } from 'react-dnd';
import { classes } from '@platform/core';
import ImageThumbnail from './ImageThumbnail';
import classNames from 'classnames';
import './Thumbnail.styl';

const StudyLoadingListener = classes.StudyLoadingListener;

function ThumbnailFooter({
  ClinicalTrialTimePointID,
  SeriesDescription,
  numImageFrames,
}) {
  const infoOnly = !SeriesDescription;

  return (
    <div className={classNames('series-details', { 'info-only': infoOnly })}>
      <div className='series-description'>{SeriesDescription}</div>
      <div className='series-information'>
        <div className='item item-text'>
          <div>{ClinicalTrialTimePointID || ''}</div>
        </div>
        {numImageFrames && (
          <div className={classNames('item item-series', 'image-frames')}>
            <div className='icon'></div>

            <div className='value'>{numImageFrames}</div>
          </div>
        )}
      </div>
    </div>
  );
}

ThumbnailFooter.propTypes = {
  SeriesDescription: PropTypes.string,
  SeriesNumber: PropTypes.number,
  SeriesDate: PropTypes.string,
  numImageFrames: PropTypes.number,
};

function Thumbnail(props) {
  const {
    id,
    active,
    altImageText,
    error,
    displaySetInstanceUID,
    imageId,
    imageSrc,
    StudyInstanceUID,
    onClick,
    onDoubleClick,
    onMouseDown,
    supportsDrag,
    showProgressBar,
  } = props;

  const [stackPercentComplete, setStackPercentComplete] = useState(0);
  useEffect(() => {
    const onProgressChange = throttle(({ detail }) => {
      const { progressId, progressData } = detail;
      if (`StackProgress:${displaySetInstanceUID}` === progressId) {
        const percent = progressData ? progressData.percentComplete : 0;
        if (percent > stackPercentComplete) {
          setStackPercentComplete(percent);
        }
      }
    }, 100);

    document.addEventListener(
      StudyLoadingListener.events.OnProgress,
      onProgressChange
    );

    return () => {
      document.removeEventListener(
        StudyLoadingListener.events.OnProgress,
        onProgressChange
      );
    };
  }, [displaySetInstanceUID]);

  const [, drag] = useDrag({
    // `droppedItem` in `dropTarget`
    // The only data it will have access to
    item: {
      StudyInstanceUID,
      displaySetInstanceUID,
      type: 'thumbnail', // Has to match `dropTarget`'s type
    },
    canDrag: function() {
      return supportsDrag;
    },
  });

  const hasImage = imageSrc || imageId;
  const hasAltText = altImageText !== undefined;

  return (
    <div
      data-cy={`thumbnail-${id}`}
      ref={drag}
      className={classNames('thumbnail', { active: active })}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
    >
      {/* SHOW IMAGE */}
      {hasImage && (
        <ImageThumbnail
          active={active}
          imageSrc={imageSrc}
          imageId={imageId}
          error={error}
          stackPercentComplete={stackPercentComplete}
          showProgressBar={showProgressBar}
        />
      )}
      {/* SHOW TEXT ALTERNATIVE */}
      {!hasImage && hasAltText && (
        <div className={'alt-image-text p-x-1'}>
          <h1>{altImageText}</h1>
        </div>
      )}
      {ThumbnailFooter(props)}
    </div>
  );
}

Thumbnail.propTypes = {
  supportsDrag: PropTypes.bool,
  id: PropTypes.string.isRequired,
  displaySetInstanceUID: PropTypes.string.isRequired,
  StudyInstanceUID: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  imageId: PropTypes.string,
  error: PropTypes.bool,
  active: PropTypes.bool,
  stackPercentComplete: PropTypes.number,
  /**
  altImageText will be used when no imageId or imageSrc is provided.
  It will be displayed inside the <div>. This is useful when it is difficult
  to make a preview for a type of DICOM series (e.g. DICOM-SR)
  */
  altImageText: PropTypes.string,
  SeriesDescription: PropTypes.string,
  SeriesNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numImageFrames: PropTypes.number,
  onDoubleClick: PropTypes.func,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  showProgressBar: PropTypes.bool,
};

Thumbnail.defaultProps = {
  supportsDrag: false,
  active: false,
  error: false,
  stackPercentComplete: 0,
  onDoubleClick: () => {},
  onClick: () => {},
  onMouseDown: () => {},
};

export { Thumbnail };
