import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import {
  Body,
  Container,
  Grid,
  InputBox,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  H6,
  IconButton,
  Icon,
} from '@platform/ui';
import ExportAlert from '../ExportAlert';
import * as models from '../../../modules/dicom-measurement/src';

const { math } = models;
const { round } = math;

export const LinkSection = ({
  imageSets,
  seriesInstanceUID,
  seriesStructureMap,
  links,
  timepoints,
  snapshots,
}) => {
  const [timepoint, setTimepoint] = useState({ timepoint_id: '' });
  const [isAllOpen, setIsAllOpen] = useState(false);

  useEffect(() => {
    const imageSet = imageSets.find(
      (set) => set.SeriesInstanceUID === seriesInstanceUID
    );
    const timepoint = timepoints.find(
      (t) => t.timepoint_id === imageSet.ClinicalTrialTimePointID
    );
    setTimepoint(timepoint || { timepoint_id: '' });
  }, [timepoints]);

  function onTimepointChange(timepoint_id) {
    const timepoint = timepoints.find((t) => t.timepoint_id === timepoint_id);
    setTimepoint({ ...timepoint });
  }

  const structure_set_uids = Object.values(seriesStructureMap).filter(
    (value, index, array) => array.indexOf(value) === index
  );
  const study_uids = structure_set_uids.map(uid => {
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    const { StudyInstanceUID } = editModule.getters.structureSet(uid);
    return StudyInstanceUID;
  })
  const links_of_timepoint = links.map((l) => ({
    ...l,
    status: timepoint?.link_status?.[l._id],
    rois: [
      ...snapshots.filter(
        (snapshot) => !study_uids.find((id) => snapshot.study_uid === id)
      ),
      ...structure_set_uids.map((uid) => {
        const editModule = cornerstoneTools.getModule('rtstruct-edit');
        const { ROIContours } = editModule.getters.structureSet(uid);
        const series_uid = models.getSeriesUIDByStructureSetUID(
          uid,
          seriesStructureMap
        );
        const imageSet = imageSets.find(
          (set) => set.SeriesInstanceUID === series_uid
        );
        const series = models.getTimepointWithTool(
          imageSet,
          ROIContours,
          false
        );
        return {
          ...series,
          timepoint_id: series.ClinicalTrialTimePointID,
          ROIs: series.ROIMeasurements.map((roi) => ({
            ...roi,
            link_id: roi.link_id,
            roi_name: roi.ROIName,
            long_axis: roi.maxLongAxisValue,
            short_axis: roi.maxShortAxisValue,
            volume: roi.volume,
            series_description: series.SeriesDescription,
          })),
        };
      }),
    ]
      .reduce(
        (rois, s) => [
          ...rois,
          ...s.ROIs?.map((r) => ({ ...r, timepoint_id: s.timepoint_id })),
        ],
        []
      )
      .filter((roi) => roi.link_id === l._id)
      .filter((roi) => roi.timepoint_id === timepoint.timepoint_id),
  }));

  return (
    <Body>
      <Container>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow index={-1}>
              <TableCell key={'icon'} width='5%' header={1}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsAllOpen(!isAllOpen);
                  }}
                  color='primary'
                >
                  <Icon
                    name={
                      isAllOpen
                        ? 'feather-chevron-down'
                        : 'feather-chevron-right'
                    }
                    width='24px'
                    height='24px'
                  />
                </IconButton>
              </TableCell>
              {[
                ['Name', '8%'],
                ['Location', '10%'],
                ['Type', '12%'],
                ['Status', '10%'],
                ['Series', '15%'],
                ['ROI Name', '10%'],
                ['Long Axis(mm)', '10%'],
                ['Short Axis(mm)', '10%'],
                [
                  <span>
                    Volume(mm<sup>3</sup>)
                  </span>,
                  '10%',
                ],
              ].map(([text, width], index) => (
                <TableCell key={index} width={width} header={1}>
                  <H6>{text}</H6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {links_of_timepoint.map((link, index) => (
              <LinkRow
                key={link.link_id}
                link={link}
                index={index}
                isAllOpen={isAllOpen}
              />
            ))}
          </TableBody>
        </Table>
      </Container>
      <div>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={3} display='flex'>
            <InputBox label={'Timepoint ID:'} ratio={1}>
              <Select
                value={timepoint?.timepoint_id}
                onChange={(evt) => onTimepointChange(evt.target.value)}
              >
                {timepoints.map((t) => (
                  <option key={t.timepoint_id} value={t.timepoint_id}>
                    {t.timepoint_id}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    </Body>
  );
};
LinkSection.propTypes = {
  imageSets: PropTypes.array,
};

function LinkRow({ link, index, isAllOpen }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isAllOpen);
  }, [isAllOpen]);

  const present = link.rois.length > 0;
  const absent = link.status === 'absent';
  const sum = link.rois.reduce(
    (acc, roi) => {
      return {
        long_axis: acc.long_axis + roi.long_axis,
        short_axis: acc.short_axis + roi.short_axis,
        volume: acc.volume + roi.volume,
      };
    },
    { long_axis: 0, short_axis: 0, volume: 0 }
  );

  return (
    <>
      <TableRow index={index}>
        <TableCell key={'icon'}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
            color='primary'
          >
            <Icon
              name={isOpen ? 'feather-chevron-down' : 'feather-chevron-right'}
              width='24px'
              height='24px'
            />
          </IconButton>
        </TableCell>
        {[link.name, link.location, link.type].map((cell, index) => (
          <TableCell key={index}>
            <H6>{cell}</H6>
          </TableCell>
        ))}
        <TableCell key={index}>
          {present ? <H6>PRESENT</H6> : absent ? <H6>ABSENT</H6> : ''}
        </TableCell>
        {['', ''].map((cell, index) => (
          <TableCell key={index}>
            <H6>{cell}</H6>
          </TableCell>
        ))}
        {[
          round(sum.long_axis, -2),
          round(sum.short_axis, -2),
          round(sum.volume, -2),
        ].map((cell, index) => (
          <TableCell key={index} align='right'>
            {isOpen ? '' : present ? <H6>{cell}</H6> : ''}
          </TableCell>
        ))}
      </TableRow>
      {isOpen &&
        link.rois.map((roi) => (
          <RoiRow
            key={link._id + roi.series_uid + roi.roi_number}
            roi={roi}
            index={index}
          />
        ))}
    </>
  );
}
LinkRow.propTypes = {
  link: PropTypes.object,
};

function RoiRow({ roi, index }) {
  return (
    <TableRow index={index}>
      <TableCell />
      {['', '', '', '', roi.series_description, roi.roi_name].map(
        (cell, index) => (
          <TableCell key={index}>
            <H6>{cell}</H6>
          </TableCell>
        )
      )}
      {[
        round(roi.long_axis, -2),
        round(roi.short_axis, -2),
        round(roi.volume, -2),
      ].map((cell, index) => (
        <TableCell key={index} align='right'>
          <H6>{cell}</H6>
        </TableCell>
      ))}
    </TableRow>
  );
}
RoiRow.propTypes = {
  roi: PropTypes.object,
};
