import React, { useEffect, useState } from 'react';
import cornerstoneTools from 'cornerstone-tools';

import { LoadingSpinner } from '@platform/ui';
import { LinkItem } from './LinkItem';
import * as models from '../../../modules/dicom-measurement/src';

export function LinkList({
  seriesStructureMap,
  seriesInstanceUID: series_uid,
  structureSetUID: uid,
  axialImageSets: imageSets,
  onConfirm,
  onCommand,
  getNewROIName,
  onROIUpdate,
  trackingType,
  timepoints,
  snapshots,
  selectedLinkID,
  guideline,
  links,
  isLinking,
  onLinkUpdate,
  onLinkROICreate,
  onTimepointLinkStatusChange,
  navigateToLink,
}) {
  const [timepointLinks, setTimepointLinks] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  async function renderLinks() {
    /** find timepoint id */
    const imageSet = imageSets.find(
      (set) => set.SeriesInstanceUID === series_uid
    );
    const { ClinicalTrialTimePointID: timepoint_id } = imageSet;
    const timepoint = timepoints.find((t) => t.timepoint_id === timepoint_id);

    /** map structure sets to links */
    const structure_set_uids = Object.values(seriesStructureMap).filter(
      (value, index, array) => array.indexOf(value) === index
    );
    const study_uids = structure_set_uids.map((uid) => {
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      const { StudyInstanceUID } = editModule.getters.structureSet(uid);
      return StudyInstanceUID;
    });
    const links_of_timepoint = links
      .map((l) => ({
        ...l,
        timepoint_id: timepoint_id,
        status: timepoint?.link_status?.[l._id],
        disabled: ['COMPLETED', 'EXPIRED'].includes(timepoint?.status),
        rois: [
          ...snapshots.filter(
            (snapshot) => !study_uids.find((id) => snapshot.study_uid === id)
          ),
          ...structure_set_uids.map((uid) => {
            const editModule = cornerstoneTools.getModule('rtstruct-edit');
            const { ROIContours } = editModule.getters.structureSet(uid);
            const _series_uid = models.getSeriesUIDByStructureSetUID(
              uid,
              seriesStructureMap
            );
            const _imageSet = imageSets.find(
              (set) => set.SeriesInstanceUID === _series_uid
            );
            return {
              timepoint_id: _imageSet.ClinicalTrialTimePointID,
              ROIs: ROIContours.map((roi) => ({
                ...roi,
                link_id: roi.link_id,
                series_uid: uid,
                roi_name: roi.ROIName,
              })),
            };
          }),
        ]
          .filter((s) => s.timepoint_id === timepoint_id)
          .reduce((rois, s) => [...rois, ...s.ROIs], [])
          .filter((roi) => roi.link_id === l._id),
      }))
      .map((l) => ({
        ...l,
        current_rois: l.rois.filter((roi) => roi.series_uid === uid),
      }));
    setTimepointLinks(links_of_timepoint);
  }

  useEffect(() => {
    (async function() {
      try {
        setIsProcessing(true);
        await renderLinks();
      } catch (err) {
        console.log(err);
      } finally {
        setIsProcessing(false);
      }
    })();
  }, [uid, links, timepoints]);

  return isProcessing ? (
    <div style={{ margin: '15px' }}>
      <LoadingSpinner />
    </div>
  ) : (
    timepointLinks
      .filter((link) => {
        switch (trackingType) {
          case 'target':
            return link.type === 'TARGET';
          case 'non-target':
            return link.type === 'NON-TARGET';
          case 'new':
            return link.type === 'NEW';
          case 'all':
          default:
            return true;
        }
      })
      .map((link) => (
        <LinkItem
          key={link._id}
          guideline={guideline}
          link={link}
          isLinking={isLinking}
          selected={selectedLinkID === link._id}
          onConfirm={onConfirm}
          onCommand={onCommand}
          getNewROIName={getNewROIName}
          onROICreate={(rois) => {
            onLinkROICreate(link._id, rois);
            renderLinks();
          }}
          onROIUpdate={(roi, key, value) => {
            onROIUpdate(roi, key, value);
            renderLinks();
          }}
          onUpdate={(key, value) => onLinkUpdate({ ...link, [key]: value })}
          onStatusChange={(status) =>
            onTimepointLinkStatusChange(link._id, link.timepoint_id, status)
          }
          onNavigate={() => navigateToLink(link)}
        />
      ))
  );
}
