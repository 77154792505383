import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { APIContext } from '@platform/viewer';

const LoggerContext = createContext(null);
const { Provider } = LoggerContext;

export const useLogger = () => useContext(LoggerContext);

const LoggerProvider = ({ children, service }) => {
  const api = useContext(APIContext);
  const [state, setState] = useState({ errors: [], warns: [], infos: [] });

  const error = useCallback(
    (log) => {
      setState((state) => ({ ...state, errors: [...state.errors, log] }));
      if (api?.isEnabled()) api.log({ ...log, level: 'error' });
    },
    [api]
  );

  const warn = useCallback(
    (log) => {
      setState((state) => ({ ...state, warns: [...state.warns, log] }));
      if (api?.isEnabled()) api.log({ ...log, level: 'warning' });
    },
    [api]
  );

  const info = useCallback(
    (log) => {
      setState((state) => ({ ...state, infos: [...state.infos, log] }));
      if (api?.isEnabled()) api.log({ ...log, level: 'info' });
    },
    [api]
  );

  useEffect(() => {
    if (service) service.setServiceImplementation({ error, warn, info });
  }, [service, error, warn, info]);
  return <Provider value={{ state, error, warn, info }}>{children}</Provider>;
};

export const withLogger = (Component) => {
  return function WrappedComponent(props) {
    const { error, warn, info } = useLogger();
    return <Component {...props} logger={{ error, warn, info }} />;
  };
};
LoggerProvider.defaultProps = { service: null };
LoggerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  service: PropTypes.shape({
    setServiceImplementation: PropTypes.func,
  }),
  id: PropTypes.string,
};

export default LoggerProvider;
export const LogConsumer = LoggerContext.Consumer;
