import RECIST11 from '../guidelines/RECIST11';
import iRECIST from '../guidelines/iRECIST';
import RANOBM from '../guidelines/RANOBM';
import CUSTOM from '../guidelines/CUSTOM';

const guidelines = {
  RECIST11,
  iRECIST,
  RANOBM,
  CUSTOM,
};

export default guidelines;
