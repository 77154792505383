import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '@platform/viewer';
import {
  Grid,
  Icon,
  ScrollableArea,
  TableList,
  Box,
  Select,
  RoundedButtonGroup,
  Button,
  IconButton,
  ToolTip,
} from '@platform/ui';
import { getLinkTypes } from '../../../modules/dicom-measurement/src/constants';
import { NewROIForm } from '../forms/NewROIForm';
import { NewLinksForm } from '../forms/NewLinksForm';
import { ROIContourItem } from '../sections/ROIContourItem';
import { LinkList } from './LinkList';

export const ROIContourSection = ({
  seriesStructureMap,
  seriesInstanceUID,
  structureSetUID,
  axialImageSets,
  onCommand,
  onConfirm,
  /** ROIs */
  ROIContours,
  selectedROIContour,
  getNewROIName,
  addNewROIContour,
  copyROIContour,
  navigateToROIContour,
  onROISelect,
  onROIClick,
  onROIUpdate,
  onROIDelete,
  isAllDisplayed,
  onDisplayAllStatusChange,
  onComputingStatusChange,
  /** LINKs */
  trial,
  timepoints,
  snapshots,
  links,
  selectedLinks,
  selectedLinkID,
  setSelectedLinkID,
  isLinking,
  onLinking,
  onLinkCreate,
  onLinkUpdate,
  onLinksSelect,
  onLinksSubmit,
  onTimepointLinkStatusChange,
  navigateToLink,
  isReading,
  isBaselineCompleted,
  isTimepointCompleted,
}) => {
  const api = useContext(APIContext);
  const bottomRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [mode, setMode] = useState('roi');
  const [trackingType, setTrackingType] = useState('All');
  const modes = isLinking
    ? [{ id: 'roi', value: 'roi', label: 'ROIs' }]
    : [
        { id: 'roi', value: 'roi', label: 'ROIs' },
        { id: 'link', value: 'link', label: 'LINKs' },
      ];
  const guideline = trial?.protocol?.guidelineId;
  const types = [
    { value: 'ALL', label: 'All Lesions' },
    ...getLinkTypes(guideline),
  ];
  const rois_with_link = ROIContours.map((roi) => {
    const link = links.find((link) => roi.link_id === link._id) || {};
    return { ...roi, type: link.type, location: link.location };
  });
  api?.sortLinks(rois_with_link);

  useEffect(() => {
    if (isScrolling) bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    setIsScrolling(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ROIContours]);

  return (
    <>
      <div style={{ margin: '5px 0' }}>
        <RoundedButtonGroup
          options={modes}
          value={mode}
          onValueChanged={(value) => {
            setMode(value);
            setSelectedLinkID('');
            onROISelect(null, false);
          }}
          disabled={isLinking}
        />
      </div>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        {isLinking ? (
          <Box style={{ marginBottom: '10px' }}>
            <NewLinksForm links={selectedLinks} onSubmit={onLinksSubmit} />
          </Box>
        ) : (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ marginBottom: '10px' }}
          >
            <ToolTip title={isAllDisplayed ? 'Hide' : 'Display'}>
              <IconButton
                onClick={onDisplayAllStatusChange}
                style={{ marginRight: '5px' }}
                color='secondary'
                variant='contained'
              >
                <Icon
                  name={isAllDisplayed ? 'eye-on' : 'eye-off'}
                  width='24px'
                  height='24px'
                  style={{ color: 'black', background: 'transparent' }}
                />
              </IconButton>
            </ToolTip>
            <NewROIForm
              name={getNewROIName()}
              onFinished={async (rois, type, location, description) => {
                const roi = addNewROIContour(rois);
                if (roi) {
                  let link_id = '';
                  if (location !== 'default' && type !== 'default') {
                    link_id = await onLinkCreate(type, location, description);
                    setSelectedLinkID(link_id);
                  }
                  onROIUpdate(roi, 'link_id', link_id);
                }
                setIsScrolling(true);
              }}
              guideline={guideline}
              links={links}
              disabled={isTimepointCompleted || mode !== 'roi'}
            />
            <Button
              onClick={() => onComputingStatusChange(true)}
              style={{ marginLeft: '5px' }}
              color='secondary'
              variant='contained'
              fullWidth
              disabled={!isReading || isTimepointCompleted || mode !== 'roi'}
            >
              <Icon
                name='code'
                width='20px'
                height='20px'
                style={{ color: 'black' }}
              />
              Calc
            </Button>
            <Button
              onClick={onLinking}
              style={{ marginLeft: '5px' }}
              color='secondary'
              variant='contained'
              fullWidth
              disabled={!isReading || isTimepointCompleted || mode !== 'roi'}
            >
              <Icon
                name='link'
                width='20px'
                height='20px'
                style={{ color: 'black' }}
              />
              Link
            </Button>
          </Box>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              value={trackingType}
              onChange={(evt) => setTrackingType(evt.target.value)}
              style={{ margin: '0 0 5px', minHeight: '40px' }}
            >
              {types.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>
        <ScrollableArea>
          <TableList headless style={{ margin: '0 0 100px' }}>
            {mode === 'roi' &&
              rois_with_link
                .filter((roi) => {
                  if (trackingType === 'ALL') return true;
                  if (types.map((t) => t.value).includes(trackingType)) {
                    return roi.type === trackingType;
                  }
                  return true;
                })
                .map((roi, idx) => (
                  <ROIContourItem
                    key={structureSetUID + roi.ROINumber}
                    id={structureSetUID + roi.ROINumber}
                    idx={idx}
                    roi={roi}
                    selected={roi.ROINumber === selectedROIContour?.ROINumber}
                    onCommand={onCommand}
                    onConfirm={onConfirm}
                    onClick={() => onROIClick(roi)}
                    onNavigate={() => navigateToROIContour(roi)}
                    onCopy={() => {
                      copyROIContour(roi, idx);
                      setIsScrolling(true);
                    }}
                    onUpdate={(key, value) => onROIUpdate(roi, key, value)}
                    onDelete={() => onROIDelete(roi)}
                    guideline={guideline}
                    links={links}
                    selectedLinks={selectedLinks}
                    isLinking={isLinking}
                    onLinksSelect={onLinksSelect}
                    onLinkUpdate={onLinkUpdate}
                    isReading={isReading}
                    isBaselineCompleted={isBaselineCompleted}
                    isTimepointCompleted={isTimepointCompleted}
                  />
                ))}
            {mode === 'link' && isReading && (
              <LinkList
                seriesStructureMap={seriesStructureMap}
                seriesInstanceUID={seriesInstanceUID}
                structureSetUID={structureSetUID}
                axialImageSets={axialImageSets}
                onConfirm={onConfirm}
                onCommand={onCommand}
                getNewROIName={getNewROIName}
                onROIUpdate={onROIUpdate}
                trackingType={trackingType}
                timepoints={timepoints}
                snapshots={snapshots}
                selectedLinkID={selectedLinkID}
                guideline={guideline}
                links={links}
                isLinking={isLinking}
                onLinkUpdate={onLinkUpdate}
                onLinkROICreate={(link_id, rois) => {
                  setSelectedLinkID(link_id);
                  addNewROIContour(rois);
                }}
                onTimepointLinkStatusChange={onTimepointLinkStatusChange}
                navigateToLink={navigateToLink}
              />
            )}
            <div ref={bottomRef} />
          </TableList>
        </ScrollableArea>
      </div>
    </>
  );
};
ROIContourSection.propTypes = {
  seriesStructureMap: PropTypes.object,
  structureSetUID: PropTypes.string,
  onConfirm: PropTypes.func,
  ROIContours: PropTypes.array,
  selectedROIContour: PropTypes.object,
  addNewROIContour: PropTypes.func,
  copyROIContour: PropTypes.func,
  navigateToROIContour: PropTypes.func,
  onROIClick: PropTypes.func,
  onROIUpdate: PropTypes.func,
  onROIDelete: PropTypes.func,
  isAllDisplayed: PropTypes.bool,
  onDisplayAllStatusChange: PropTypes.func,
  onComputingStatusChange: PropTypes.func,
};
