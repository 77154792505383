import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { ExpansionForm } from '../forms/ExpansionForm';
import getExpandedStackData from './getExpandedStackData';
import TOOL_NAMES from '../../../tools/constants/toolNames';
import * as commands from '../../../tools/commands';

export function ExpansionCompute({
  createPainters,
  popActivePainters,
  ROIContours,
  addNewROIContourWithName,
  onCommand,
  onFinished,
}) {
  const api = { isEnabled: () => false };
  const [activePainters, setActivePainters] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const isSubmitting = useRef(false);
  const isDeleting = useRef(false);

  const onFormCreate = () => {
    onCommand('setToolActive', { toolName: TOOL_NAMES.EXPANSION_TOOL });
    const painters = createPainters('expansion');
    setActivePainters(painters);
  };

  const onFormPreview = async (ROINumber, margins, type, avoidNumber) => {
    try {
      setErrorMessage('');
      if (!ROINumber && ROINumber !== 0) throw new Error('Invalid ROI Number');
      if (!api?.isEnabled()) {
        if (type !== 'outer') {
          throw new Error("Invalid input. Please select 'Outer'.");
        }
        if (Object.values(margins).some((i) => i > 5)) {
          throw new Error('Invalid input. Please enter margin <= 5');
        }
      }
      const stackData = await getExpandedStackData(
        api,
        activePainters,
        ROINumber,
        margins,
        type
      );
      for (let k = 0; k < activePainters.length; k++) {
        activePainters[k].update({
          data: stackData[k],
          avoidROINumber: avoidNumber,
        });
      }
      commands.refreshViewport();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onFormSubmit = ({ name }) => {
    try {
      if (!name) throw new Error('ROI Name is empty');
      const newNumber = addNewROIContourWithName(name);
      if (newNumber === -1) throw new Error(`ROI ${name} already exists`);
      for (let i = 0; i < activePainters.length; i++) {
        activePainters[i].commit({ ROINumber: newNumber });
      }
      commands.refreshViewport();
      isSubmitting.current = true;
      onFinished();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onFormDelete = () => {
    isDeleting.current = true;
    popActivePainters();
    onFinished();
  };

  useEffect(() => {
    onFormCreate();
    return () => {
      if (!isSubmitting.current && !isDeleting.current) popActivePainters();
    };
  }, []);

  return (
    <ExpansionForm
      ROIContours={ROIContours}
      onPreview={onFormPreview}
      onSubmit={onFormSubmit}
      onDelete={onFormDelete}
      errorMessage={errorMessage}
    />
  );
}
ExpansionCompute.propTypes = {
  createPainters: PropTypes.func,
  popActivePainters: PropTypes.func,
  ROIContours: PropTypes.arrayOf(
    PropTypes.shape({ ROINumber: PropTypes.number })
  ),
  addNewROIContourWithName: PropTypes.func,
  onCommand: PropTypes.func,
  onFinished: PropTypes.func,
};
