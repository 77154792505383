import {
  MEASUREMENT_TECHNIQUE,
  TARGET_RESPONSE,
  NON_TARGET_RESPONSE,
  NEW_LESION_RESPONSE,
  CLINICAL_STATUS,
  OVERALL_RESPONSE,
} from './guideline';
import { math } from '../index';
const { round } = math;

function a(num) {
  return Number(num) > 0 ? `+${num}` : num;
}

export function getImmuneTargetResponse(stats, criteria) {
  if (criteria.measurementTechnique !== MEASUREMENT_TECHNIQUE.BIDIMENSIONAL) {
    return {
      result: TARGET_RESPONSE.NOT_EVALUABLE,
      message: 'Target Lesion(NE)',
    };
  }
  if (stats.length < 2) {
    return {
      result: TARGET_RESPONSE.NOT_EVALUABLE,
      message: 'Target Lesion(NE)',
    };
  }
  const baseline_index = 0;
  const baseline = stats[baseline_index];
  const baseline_som = baseline.summation.sumDiameter;

  const current_index = stats.length - 1;
  const current = stats[current_index];
  const current_som = current.summation.sumDiameter;

  if (!current.existLesions) {
    return {
      result: TARGET_RESPONSE.NONE,
      message: 'Target Lesion(NED): no target lesion selected',
    };
  }

  const previous = stats[current_index - 1];
  const previous_sum = previous.summation.sumDiameter;
  if (
    previous.target_response === TARGET_RESPONSE.iUPD &&
    current_som - previous_sum >= 5
  ) {
    const diameter_change = round(current_som - previous_sum, -2);
    return {
      result: TARGET_RESPONSE.iCPD,
      message: `Target Lesion(iCPD): ${a(diameter_change)}mm change from timepoint ${previous.id} to ${current.id}`,
    };
  }

  const nadir_index = stats.reduce((index, cur, cur_index, array) => {
    return cur.summation.sumDiameter < array[index].summation.sumDiameter
      ? cur_index
      : index;
  }, 0);
  const nadir = stats[nadir_index];
  const nadir_som = nadir.summation.sumDiameter;

  if (current_som >= nadir_som * 1.2 && current_som - nadir_som >= 5) {
    const percentage_change =
      nadir_som === 0
        ? 100
        : round((100 * (current_som - nadir_som)) / nadir_som, -2);
    return {
      result: TARGET_RESPONSE.iUPD,
      message: `Target Lesion(iUPD): ${a(percentage_change)}% change from timepoint ${nadir.id} to ${current.id}`,
    };
  } else if (current_som === 0) {
    return {
      result: TARGET_RESPONSE.iCR,
      message: `Target Lesion(iCR): no lesion found at timepoint ${current.id}`,
    };
  } else if (current_som <= baseline_som * 0.7) {
    const percentage_change =
      baseline_som === 0
        ? 100
        : round((100 * (current_som - baseline_som)) / baseline_som, -2);
    return {
      result: TARGET_RESPONSE.iPR,
      message: `Target Lesion(iPR): ${a(percentage_change)}% change from timepoint ${baseline.id} to ${current.id}`,
    };
  } else {
    const percentage_change =
      baseline_som === 0
        ? 100
        : round((100 * (current_som - baseline_som)) / baseline_som, -2);
    return {
      result: TARGET_RESPONSE.iSD,
      message: `Target Lesion(iSD): ${a(percentage_change)}% change from timepoint ${baseline.id} to ${current.id}`,
    };
  }
}

export function getImmuneNonTargetResponse(stats, criteria) {
  if (criteria.measurementTechnique !== MEASUREMENT_TECHNIQUE.BIDIMENSIONAL) {
    return {
      result: NON_TARGET_RESPONSE.NOT_EVALUABLE,
      message: 'Non-target Lesion(NE)',
    };
  }
  if (stats.length < 2) {
    return {
      result: NON_TARGET_RESPONSE.NOT_EVALUABLE,
      message: 'Non-target Lesion(NE)',
    };
  }

  const current_index = stats.length - 1;
  const current = stats[current_index];
  if (current.existROIs) {
    return {
      result: NON_TARGET_RESPONSE.iNN,
      message: `Non-target Lesion(Non-iCR/Non-iUPD): lesion found at timepoint ${current.id}; please refer to the DICOM images`,
    };
  }
  if (current.existLesions) {
    return {
      result: NON_TARGET_RESPONSE.iCR,
      message: `Non-target Lesion(iCR): no lesion found at timepoint ${current.id}`,
    };
  }
  return {
    result: NON_TARGET_RESPONSE.NONE,
    message: 'Non-target Lesion(NED): no non-target lesion selected',
  };
}

export function getImmuneNewLesionResponse(stats, criteria) {
  if (criteria.measurementTechnique !== MEASUREMENT_TECHNIQUE.BIDIMENSIONAL) {
    return {
      result: NEW_LESION_RESPONSE.NOT_EVALUABLE,
      message: 'New Lesion(NE)',
    };
  }
  if (stats.length < 2) {
    return {
      result: NEW_LESION_RESPONSE.NOT_EVALUABLE,
      message: 'New Lesion(NE)',
    };
  }

  const current_index = stats.length - 1;
  const current = stats[current_index];
  const current_som = current.summation.sumDiameter;
  const current_lesions = current.Lesions;

  const previous = stats[current_index - 1];
  const previous_sum = previous.summation.sumDiameter;
  const previous_lesions = Object.entries(
    stats.reduce((acc, cur, cur_index, array) => {
      if (cur_index === array.length - 1) return acc;
      for (const lesion of cur.Lesions) {
        acc[lesion.id] = acc[lesion.id] || 0;
        acc[lesion.id] += lesion.maxDiameterValue;
      }
      return acc;
    }, {})
  ).map(([key, value]) => ({ id: key, diameter: value }));
  const exist_previous_lesion = previous_lesions.some(
    (lesion) => lesion.diameter > 0
  );
  const exist_new_lesion = current_lesions.some(
    (lesion) => lesion.maxDiameterValue > 0
  );
  if (previous.new_lesion_response === NEW_LESION_RESPONSE.iUPD) {
    const exist_extra = current_lesions.some((lesion, index) => {
      const previous_lesion = previous_lesions[index];
      return lesion.maxDiameterValue > 0 && previous_lesion.diameter === 0;
    });
    if (exist_extra) {
      return {
        result: NEW_LESION_RESPONSE.iCPD,
        message: `New Lesion(iCPD): additional new lesion found at timepoint ${current.id}`,
      };
    }
    if (current_som - previous_sum >= 5) {
      const diameter_change = round(current_som - previous_sum, -2);
      return {
        result: NEW_LESION_RESPONSE.iCPD,
        message: `New Lesion(iCPD): target new lesions ${a(diameter_change)}mm change from timepoint ${previous.id} to ${current.id}`,
      };
    }
    if (exist_new_lesion) {
      const diameter_change = round(current_som - previous_sum, -2);
      return {
        result: NEW_LESION_RESPONSE.iUPD,
        message: `New Lesion(iUPD): target new lesions ${a(diameter_change)}mm change from timepoint ${previous.id} to ${current.id}`,
      };
    }
  }

  if (exist_new_lesion) {
    return {
      result: NEW_LESION_RESPONSE.iUPD,
      message: `New Lesion(iUPD): new lesion found at timepoint ${current.id}`,
    };
  }

  if (exist_previous_lesion) {
    return {
      result: NEW_LESION_RESPONSE.iCR,
      message: `New Lesion(iCR): no new lesion found at timepoint ${current.id}`,
    };
  }

  return {
    result: NEW_LESION_RESPONSE.NONE,
    message: 'New Lesion(NED): no new lesion found',
  };
}

export function getImmuneOverallResponse(stats) {
  if (stats.length < 2) {
    return { result: TARGET_RESPONSE.NOT_EVALUABLE, message: '' };
  }

  const current_index = stats.length - 1;
  const stat = stats[current_index];
  /** progressive disease */
  if (stat.target_response === TARGET_RESPONSE.iCPD) {
    return { result: OVERALL_RESPONSE.iCPD, message: '' };
  }
  if (stat.non_target_response === NON_TARGET_RESPONSE.iCPD) {
    return { result: OVERALL_RESPONSE.iCPD, message: '' };
  }
  if (stat.new_lesion_response === NEW_LESION_RESPONSE.iCPD) {
    return { result: OVERALL_RESPONSE.iCPD, message: '' };
  }
  if (stat.clinical_status === CLINICAL_STATUS.WORSE) {
    return { result: OVERALL_RESPONSE.iCPD, message: '' };
  }

  /** evidence of progression */
  const previous = stats[current_index - 1];
  if (previous.overall_response === OVERALL_RESPONSE.iUPD) {
    if (previous.target_response === TARGET_RESPONSE.iUPD) {
      if (
        previous.non_target_response !== NON_TARGET_RESPONSE.iCPD &&
        previous.non_target_response !== NON_TARGET_RESPONSE.iUPD &&
        stat.non_target_response === NON_TARGET_RESPONSE.iUPD
      ) {
        return { result: OVERALL_RESPONSE.iCPD, message: '' };
      }
      if (
        previous.new_lesion_response !== NEW_LESION_RESPONSE.iCPD &&
        previous.new_lesion_response !== NEW_LESION_RESPONSE.iUPD &&
        stat.new_lesion_response === NEW_LESION_RESPONSE.iUPD
      ) {
        return { result: OVERALL_RESPONSE.iCPD, message: '' };
      }
    }
    if (previous.non_target_response === NON_TARGET_RESPONSE.iUPD) {
      if (
        previous.target_response !== TARGET_RESPONSE.iCPD &&
        previous.target_response !== TARGET_RESPONSE.iUPD &&
        stat.target_response === TARGET_RESPONSE.iUPD
      ) {
        return { result: OVERALL_RESPONSE.iCPD, message: '' };
      }
      if (
        previous.new_lesion_response !== NEW_LESION_RESPONSE.iCPD &&
        previous.new_lesion_response !== NEW_LESION_RESPONSE.iUPD &&
        stat.new_lesion_response === NEW_LESION_RESPONSE.iUPD
      ) {
        return { result: OVERALL_RESPONSE.iCPD, message: '' };
      }
    }
    if (previous.new_lesion_response === NEW_LESION_RESPONSE.iUPD) {
      if (
        previous.target_response !== TARGET_RESPONSE.iCPD &&
        previous.target_response !== TARGET_RESPONSE.iUPD &&
        stat.target_response === TARGET_RESPONSE.iUPD
      ) {
        return { result: OVERALL_RESPONSE.iCPD, message: '' };
      }
      if (
        previous.non_target_response !== NON_TARGET_RESPONSE.iCPD &&
        previous.non_target_response !== NON_TARGET_RESPONSE.iUPD &&
        stat.non_target_response === NON_TARGET_RESPONSE.iUPD
      ) {
        return { result: OVERALL_RESPONSE.iCPD, message: '' };
      }
    }
  }

  /** evidence of response */
  if (
    previous.non_target_response === NON_TARGET_RESPONSE.iUPD ||
    previous.new_lesion_response === NEW_LESION_RESPONSE.iUPD
  ) {
    if (
      stat.target_response === TARGET_RESPONSE.iCR ||
      stat.target_response === TARGET_RESPONSE.iPR
    ) {
      return { result: OVERALL_RESPONSE.iPR, message: '' };
    }
    if (stat.target_response === TARGET_RESPONSE.iSD) {
      return { result: OVERALL_RESPONSE.iSD, message: '' };
    }
  }

  /** unconfirmed progressive disease */
  if (stat.target_response === TARGET_RESPONSE.iUPD) {
    return { result: OVERALL_RESPONSE.iUPD, message: '' };
  }
  if (stat.non_target_response === NON_TARGET_RESPONSE.iUPD) {
    return { result: OVERALL_RESPONSE.iUPD, message: '' };
  }
  if (stat.new_lesion_response === NEW_LESION_RESPONSE.iUPD) {
    return { result: OVERALL_RESPONSE.iUPD, message: '' };
  }

  /** complete response */
  if (
    stat.target_response === TARGET_RESPONSE.iCR &&
    (stat.non_target_response === NON_TARGET_RESPONSE.iCR ||
      stat.non_target_response === NON_TARGET_RESPONSE.NONE) &&
    (stat.new_lesion_response === NEW_LESION_RESPONSE.iCR ||
      stat.new_lesion_response === NEW_LESION_RESPONSE.NONE)
  ) {
    return { result: OVERALL_RESPONSE.iCR, message: '' };
  }
  if (
    stat.target_response === TARGET_RESPONSE.NONE &&
    stat.non_target_response === NON_TARGET_RESPONSE.iCR &&
    (stat.new_lesion_response === NEW_LESION_RESPONSE.iCR ||
      stat.new_lesion_response === NEW_LESION_RESPONSE.NONE)
  ) {
    return { result: OVERALL_RESPONSE.iCR, message: '' };
  }

  /** partial response */
  if (
    stat.target_response === TARGET_RESPONSE.iCR ||
    stat.target_response === TARGET_RESPONSE.iPR
  ) {
    return { result: OVERALL_RESPONSE.iPR, message: '' };
  }

  /** stable disease */
  if (stat.target_response === TARGET_RESPONSE.iSD) {
    return { result: OVERALL_RESPONSE.iSD, message: '' };
  }
  if (
    stat.target_response === TARGET_RESPONSE.NONE &&
    stat.non_target_response === NON_TARGET_RESPONSE.iNN
  ) {
    return { result: OVERALL_RESPONSE.iSD, message: '' };
  }

  /** not evaluable */
  if (
    stat.target_response === TARGET_RESPONSE.NOT_EVALUABLE ||
    stat.non_target_response === NON_TARGET_RESPONSE.NOT_EVALUABLE
  ) {
    return { result: OVERALL_RESPONSE.NOT_EVALUABLE, message: '' };
  }

  /** none response */
  return { result: OVERALL_RESPONSE.NONE, message: '' };
}
