import React, { useState, useEffect, useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Firebase } from '@platform/core';
import { APIContext } from '../../context/APIContext';

const timeout = 60 * 60 * 1000;
const promptBeforeIdle = 30000;

const Timer = ({ children }) => {
  const api = useContext(APIContext);
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(timeout);

  const onIdle = async () => {
    setOpen(false);
    try {
      await api.log({
        level: 'info',
        event_type: 'Platform:IdleTimeout',
        message: `idle timeout`,
        details: {},
      });
    } catch (err) {
      console.log(err);
    } finally {
      Firebase.signOut();
    }
  };
  const onActive = () => setOpen(false);
  const onPrompt = () => setOpen(true);

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 300,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <Modal open={open} remaining={remaining} onConfirm={() => activate()} />
      {children}
    </>
  );
};

export default Timer;

function Modal({ open, remaining, onConfirm }) {
  return (
    <Dialog open={open} onClose={() => {}} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>{'Timeout'}</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you still here?</DialogContentText>
        <DialogContentText>
          Logging out in {remaining} seconds
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Still Here</Button>
      </DialogActions>
    </Dialog>
  );
}
