import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import { Home } from './Home';
import { Callback } from './Callback';
import { theme } from '../components/theme';

export const AuthRouting = ({ isAuthStateChanged }) => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/callback' element={<Callback />} />
        <Route
          path='/*'
          element={<Home isAuthStateChanged={isAuthStateChanged} />}
        />
      </Routes>
    </ThemeProvider>
  );
};
AuthRouting.propTypes = { isAuthStateChanged: PropTypes.bool };
