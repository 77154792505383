function findMultiple(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== arr[0]) return true;
  }
  return false;
}

export function checkMultipleStackByAcquisitionNumbers(numbers) {
  if (numbers.length === 0) return false;
  return findMultiple(numbers);
}

export default function checkMultipleAquisition(instances) {
  const numbers = instances
    .map((instance) => instance.getTagValue('AcquisitionNumber'))
    .filter((num) => !!num);
  return checkMultipleStackByAcquisitionNumbers(numbers);
}
