import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CompactPicker } from 'react-color';

import {
  TableListItem,
  Box,
  Select,
  TextField,
  Button,
  IconButton,
  Icon,
  CheckBox,
} from '@platform/ui';
import {
  getLinkTypes,
  getLinkLocations,
} from '../../../modules/dicom-measurement/src/constants';

export const ROIContourItem = ({
  id,
  idx,
  roi,
  selected,
  onCommand,
  onConfirm,
  onClick,
  onNavigate,
  onCopy,
  onUpdate,
  onDelete,
  guideline,
  links,
  selectedLinks,
  isLinking,
  onLinksSelect,
  onLinkUpdate,
  isReading,
  isBaselineCompleted,
  isTimepointCompleted,
}) => {
  const {
    colorArray: color,
    ROIName: name,
    ROINumber: number,
    ROIGenerationDescription,
    existing,
    visible,
  } = roi;

  const link_id = roi.link_id;
  const link = links.find((l) => l._id === link_id);
  const link_name = link?.name;
  const link_description = link?.description;
  const checked = !!selectedLinks.find((l) => l.id === number);
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(name);
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState(link?.description || '');
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);

  useEffect(() => {
    setLabel(name);
  }, [name]);

  useEffect(() => {
    if (link) {
      const link_location = link?.location;
      const link_type = link?.type;
      setType(link_type);
      setLocation(link_location);
    } else {
      if (isBaselineCompleted) {
        setType('NEW');
      } else {
        setType('NON-TARGET');
      }
      setLocation('default');
    }
  }, [links, link_id]);

  const handleBlur = () => {
    setIsEditing(false);
    if (roi.ROIName !== label) onUpdate('ROIName', label);
  };
  const handleNavigateClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onNavigate();
  };
  const handleCheckClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isEditing) handleBlur();
    setIsEditing(!isEditing);
  };
  const handleEyeClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onUpdate('visible', !visible);
  };
  const handleCopyClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onCopy();
  };
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onConfirm({
      title: 'Delete ROI',
      content: `Are you sure to delete ${name}? This is not recoverable.`,
      callback: () => onDelete(),
    });
  };
  const handleLinkChange = (key, value) => {
    switch (key) {
      case 'type':
        if (link) {
          onLinkUpdate({ ...link, type: value });
        } else {
          setType(value);
          onLinksSelect('update', {
            id: number,
            name: name,
            location,
            type: value,
            description,
          });
        }
        break;
      case 'location':
        if (link) {
          onLinkUpdate({ ...link, location: value });
        } else {
          setLocation(value);
          onLinksSelect('update', {
            id: number,
            name: name,
            location: value,
            type,
            description,
          });
        }
        break;
      case 'description':
        if (link) {
          onLinkUpdate({ ...link, description: value });
        } else {
          setDescription(value);
          onLinksSelect('update', {
            id: number,
            name: name,
            location,
            type,
            description: value,
          });
        }
      default:
    }
  };

  return (
    <>
      <TableListItem
        data-cy={`roi-contour-item-${idx}`}
        itemKey={id}
        itemIndex={idx}
        itemClass={''}
        itemMeta={
          <ColoredCircle
            color={color}
            onChange={(color) => onUpdate('colorArray', color)}
          />
        }
        itemMetaClass='item-color-section'
        itemSelected={selected}
        onItemClick={onClick}
        style={{ padding: '0 4px 0 0', cursor: 'pointer' }}
      >
        {isLinking ? (
          <div style={{ padding: '0 6px 0 0' }}>
            <Box>
              <Label name={name} />
              <div style={{ display: 'flex' }}>
                {link ? (
                  <Chip>{link_name}</Chip>
                ) : (
                  <CheckBox
                    color='primary'
                    checked={checked}
                    onChange={(e) =>
                      onLinksSelect(e.target.checked ? 'add' : 'remove', {
                        id: number,
                        name: label,
                        location,
                        type,
                        description,
                      })
                    }
                    onClick={(e) => e.stopPropagation()}
                    style={{ padding: '2px 3px' }}
                    disabled={location === 'default' || type === 'default'}
                  />
                )}
              </div>
            </Box>
            <Box>
              {isDescriptionEditing ? (
                <>
                  <Label
                    name={link_description}
                    value={description}
                    isEditing={isDescriptionEditing}
                    handleClick={(e) => e.stopPropagation()}
                    handleChange={(e) => setDescription(e.target.value)}
                  />
                  <IconButton
                    onClick={() => {
                      handleLinkChange('description', description);
                      setIsDescriptionEditing(false);
                    }}
                    color='primary'
                    size='small'
                  >
                    <Icon name='check' width='24px' height='24px' />
                  </IconButton>
                </>
              ) : (
                <>
                  <Label name={link ? link_description : description} />
                  <IconButton
                    onClick={() => setIsDescriptionEditing(true)}
                    color='primary'
                    size='small'
                  >
                    <Icon name='edit-light' width='24px' height='24px' />
                  </IconButton>
                </>
              )}
            </Box>
            <Box style={{ margin: '6px 0' }}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridGap: '5px',
                  width: '100%',
                }}
              >
                <Select
                  select
                  margin='dense'
                  size='small'
                  variant='outlined'
                  fullWidth
                  value={location}
                  onChange={(e) => handleLinkChange('location', e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  style={{ padding: '2px 4px' }}
                >
                  {!link && !checked && (
                    <option
                      key={'default'}
                      value={'default'}
                      style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    >
                      {'------'}
                    </option>
                  )}
                  {getLinkLocations().map((i) => (
                    <option
                      key={i.value}
                      value={i.value}
                      style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    >
                      {i.label}
                    </option>
                  ))}
                </Select>
                <Select
                  select
                  margin='dense'
                  size='small'
                  variant='outlined'
                  fullWidth
                  value={type}
                  onChange={(e) => handleLinkChange('type', e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  style={{ padding: '2px 4px' }}
                >
                  {!link && !checked && (
                    <option
                      key={'default'}
                      value={'default'}
                      style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    >
                      {'------'}
                    </option>
                  )}
                  {getLinkTypes(guideline)
                    .filter((i) => {
                      if (link) return true;
                      if (isBaselineCompleted) {
                        return ['NEW', 'NL-TARGET', 'NL-NON-TARGET'].includes(
                          i.value
                        );
                      } else {
                        return ['TARGET', 'NON-TARGET'].includes(i.value);
                      }
                    })
                    .map((i) => (
                      <option
                        key={i.value}
                        value={i.value}
                        style={{ paddingTop: '2px', paddingBottom: '2px' }}
                      >
                        {i.label}
                      </option>
                    ))}
                </Select>
              </div>
            </Box>
          </div>
        ) : isEditing ? (
          <div style={{ padding: '0 6px 0 0' }}>
            <Box>
              <Label
                name={name}
                value={label}
                isEditing={isEditing}
                handleClick={(e) => e.stopPropagation()}
                handleChange={(e) => setLabel(e.target.value)}
                handleBlur={handleBlur}
              />
              {isReading && (
                <Select
                  margin='dense'
                  size='small'
                  variant='outlined'
                  value={link ? link_id : 'default'}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const id = e.target.value;
                    onUpdate('link_id', id === 'default' ? '' : id);
                  }}
                  style={{ width: '100px', padding: '2px 4px' }}
                >
                  <option key={'default'} value={'default'}>
                    {'------'}
                  </option>
                  {links.map((i) => (
                    <option key={i._id} value={i._id}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              )}
            </Box>
            <Box style={{ margin: '6px 0' }}>
              <Box>
                <IconButton
                  onClick={handleCheckClick}
                  color='primary'
                  size='small'
                >
                  <Icon name='check' width='24px' height='24px' />
                </IconButton>
              </Box>
            </Box>
          </div>
        ) : (
          <div style={{ padding: '0 6px 0 0' }}>
            <Box>
              <Label name={name} />
              {isReading && link && <Chip>{link_name}</Chip>}
            </Box>
            <Box style={{ margin: '6px 0' }}>
              <div>
                {existing && selected && (
                  <Button
                    data-cy='localize-button'
                    onClick={handleNavigateClick}
                    color='primary'
                    variant='outlined'
                    style={{ padding: '4px 2px' }}
                  >
                    Localize
                    <Icon
                      name='crosshair'
                      width='24px'
                      height='24px'
                      style={{ background: 'transparent' }}
                    />
                  </Button>
                )}
              </div>
              {ROIGenerationDescription === 'SearchRegion' ? (
                <Box>
                  <IconButton onClick={handleDeleteClick} color='primary'>
                    <Icon name='trash-light' width='24px' height='24px' />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    onClick={handleCheckClick}
                    color='primary'
                    size='small'
                    disabled={isTimepointCompleted}
                  >
                    <Icon
                      name={isEditing ? 'check' : 'edit-light'}
                      width='24px'
                      height='24px'
                    />
                  </IconButton>
                  {selected ? (
                    <IconButton
                      onClick={(e) => e.stopPropagation()}
                      color='primary'
                      style={{
                        backgroundColor: 'transparent',
                        cursor: 'not-allowed',
                      }}
                    >
                      <Icon
                        name={'eye-on'}
                        width='24px'
                        height='24px'
                        style={{ color: 'var(--active-color)' }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleEyeClick} color='primary'>
                      <Icon
                        name={visible ? 'eye-on' : 'eye-off'}
                        width='24px'
                        height='24px'
                      />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={handleCopyClick}
                    color='primary'
                    disabled={isTimepointCompleted}
                  >
                    <Icon name='clipboard' width='24px' height='24px' />
                  </IconButton>
                  <IconButton
                    onClick={handleDeleteClick}
                    color='primary'
                    disabled={isTimepointCompleted}
                  >
                    <Icon name='trash-light' width='24px' height='24px' />
                  </IconButton>
                </Box>
              )}
            </Box>
          </div>
        )}
      </TableListItem>
      {selected && !isEditing && !isLinking && (
        <ROIAttribute
          type={link?.type}
          roi={roi}
          onConfirm={onConfirm}
          onCommand={onCommand}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};
ROIContourItem.propTypes = {
  id: PropTypes.string,
  idx: PropTypes.number,
  roi: PropTypes.object,
  selected: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  onNavigate: PropTypes.func,
  onCopy: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export const ROIAttribute = ({ roi, type, onCommand, onConfirm, onUpdate }) => {
  switch (type) {
    case 'TARGET':
      return (
        <Tabs>
          {[
            [false, <Icon name='straighten' width='24px' height='24px' />],
            [true, 'TOO SMALL TO MEASURE'],
          ].map(([value, label]) => (
            <Tab
              selected={!!roi.too_small_to_measure === value}
              onClick={() => {
                if (value) {
                  onConfirm({
                    title: 'The ROI is TOO SMALL TO MEASURE',
                    content: `Are you sure? This will remove the existing ROI contours.`,
                    callback: () => {
                      onCommand('clearActiveViewportContours');
                      onUpdate('too_small_to_measure', value);
                    },
                  });
                } else {
                  onUpdate('too_small_to_measure', value);
                }
              }}
            >
              {label}
            </Tab>
          ))}
        </Tabs>
      );
    case 'NON-TARGET':
      return <></>;
    case 'NEW':
      return (
        <Tabs>
          {[
            [false, 'EQUIVOCAL'],
            [true, 'UNEQUIVOCAL'],
          ].map(([value, label]) => (
            <Tab
              selected={!!roi.unequivocal === value}
              onClick={() => onUpdate('unequivocal', value)}
            >
              {label}
            </Tab>
          ))}
        </Tabs>
      );
    default:
      return <></>;
  }
};

const ColoredCircle = ({ color, onChange }) => {
  const [isSelected, setIsSelected] = useState(false);
  const onChangeComplete = ({ rgb }) => {
    onChange([rgb.r, rgb.g, rgb.b]);
  };

  return (
    <div
      className='item-color'
      style={{ backgroundColor: `rgba(${color.join(',')}, 1)`, zIndex: '10' }}
      onClick={() => setIsSelected(true)}
    >
      {isSelected && (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            right: '0px',
            left: '0px',
            bottom: '0px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsSelected(false);
          }}
        />
      )}
      {isSelected && (
        <CompactPicker
          color={{ r: color[0], g: color[1], b: color[2] }}
          onChangeComplete={onChangeComplete}
        />
      )}
    </div>
  );
};
ColoredCircle.propTypes = {
  color: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

const Label = ({
  name,
  value = '',
  handleClick = () => {},
  handleChange = () => {},
  handleBlur = () => {},
  isEditing = false,
}) => {
  return (
    <div>
      {isEditing ? (
        <TextField
          value={value}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      ) : (
        <p
          style={{
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            wordWrap: 'break-word',
            fontSize: '16px',
          }}
        >
          {name}
        </p>
      )}
    </div>
  );
};
Label.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isEditing: PropTypes.bool,
};

const Chip = styled.div`
  color: white;
  background-color: var(--snackbar-success);
  margin: 5px 0;
  padding: 0 15px;
  border-radius: 10px;
`;
const Tabs = styled.div`
  margin: 4px 0;
  display: flex;
  grid-gap: 0 4px;
`;
const Tab = styled.button`
  width: 100%;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 6px 16px;
  border: 0;
  border-radius: 4px;
  outline: 0;
  user-select: none;
  -moz-appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.03em;
  white-space: nowrap;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: var(--text-secondary-color);
  background-color: var(--ui-gray);
  cursor: pointer;
  &:hover {
    color: #000000;
    background-color: var(--active-color);
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
  ${(props) =>
    props.selected &&
    css`
      color: #000000;
      background-color: var(--active-color);
    `}
`;
