export default function getPrefetcher({
  enabled: e,
  displaySetCount: c,
  maxNumPrefetchRequests: r,
}) {
  const params =
    window.location.search && new URLSearchParams(window.location.search);
  let enabled = params && params.get('prefetch');
  switch (enabled) {
    case 'false':
      enabled = false;
      break;
    default:
      enabled = e;
  }
  let displaySetCount = params && params.get('prefetchCount');
  if (displaySetCount) {
    displaySetCount = Number(displaySetCount) || c;
  } else {
    displaySetCount = c;
  }
  let maxNumPrefetchRequests = params && params.get('prefetchRequests');
  if (maxNumPrefetchRequests) {
    maxNumPrefetchRequests = Number(maxNumPrefetchRequests) || r;
  } else {
    maxNumPrefetchRequests = r;
  }
  return {
    enabled: enabled,
    order: 'closest',
    displaySetCount: displaySetCount,
    preventCache: false,
    prefetchDisplaySetsTimeout: 300,
    maxNumPrefetchRequests: maxNumPrefetchRequests,
    displayProgress: true,
    includeActiveDisplaySet: true,
  };
}
