import {
  getAuth,
  onAuthStateChanged as _onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  EmailAuthProvider,
  reauthenticateWithPopup,
  reauthenticateWithCredential,
  getMultiFactorResolver,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signOut as _signOut,
} from 'firebase/auth';

export function getCurrentUser() {
  const auth = getAuth();
  return auth.currentUser;
}

export function getMultiFactor() {
  const auth = getAuth();
  const factor = multiFactor(auth.currentUser);
  return factor;
}

export function onAuthStateChanged(callback) {
  const auth = getAuth();
  return _onAuthStateChanged(auth, (user) => callback(user));
}

export async function signInWithEmail(email, password) {
  const auth = getAuth();
  await signInWithEmailAndPassword(auth, email, password);
}

export async function verifyEmail() {
  const auth = getAuth();
  await sendEmailVerification(auth.currentUser);
  window.alert(`Email verificaiton is sent.`);
} 

export async function resetPassword(email, remindMsg = '') {
  if (!email) {
    window.alert('Please enter your email.');
    return;
  }
  const auth = getAuth();
  await sendPasswordResetEmail(auth, email);
  window.alert(`Password reset email is sent${remindMsg}.`);
}

export async function signInWithGooglePopup() {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'consent' });
  const auth = getAuth();
  await signInWithPopup(auth, provider);
}

export function getMultiFactorResolverWithError(err) {
  const auth = getAuth();
  const resolver = getMultiFactorResolver(auth, err);
  return resolver;
}

export async function handlePhoneMultiFactor(resolver) {
  const auth = getAuth();
  const factor_id = PhoneMultiFactorGenerator.FACTOR_ID;
  const hint = resolver.hints.find((hint) => hint.factorId === factor_id);
  let verificationId = '';
  if (hint) {
    const phoneInfoOptions = {
      multiFactorHint: hint,
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      window.recaptchaVerifier
    );
  }
  return verificationId;
}

export async function resolvePhoneMultiFactor(resolver, id, code) {
  const cred = PhoneAuthProvider.credential(id, code);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  return await resolver.resolveSignIn(multiFactorAssertion);
}

export async function reauthenticateWithEmailAndPassword(user, password) {
  const credential = EmailAuthProvider.credential(user.email, password);
  return await reauthenticateWithCredential(user, credential).catch((err) => {
    if (err.code === 'auth/multi-factor-auth-required') {
      return true;
    } else {
      throw new Error(err);
    }
  });
}

export async function reauthenticateWithGooglePopup(user) {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'consent' });
  return await reauthenticateWithPopup(user, provider).catch((err) => {
    if (err.code === 'auth/multi-factor-auth-required') {
      return true;
    } else {
      throw new Error(err);
    }
  });
}

export async function sendVerificationCode(user, phoneNumber) {
  const multiFactorSession = await multiFactor(user).getSession();
  const phoneInfoOptions = {
    phoneNumber: phoneNumber,
    session: multiFactorSession,
  };

  const auth = getAuth();
  const phoneAuthProvider = new PhoneAuthProvider(auth);
  const verificationId = await phoneAuthProvider.verifyPhoneNumber(
    phoneInfoOptions,
    window.recaptchaVerifier
  );
  return verificationId;
}

export async function verifyCodeAndEnrollPhoneNumber(id, code) {
  const displayName = 'Phone 1';
  const auth = getAuth();
  const user = auth.currentUser;
  const cred = PhoneAuthProvider.credential(id, code);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  return await multiFactor(user).enroll(multiFactorAssertion, displayName);
}

export function signOut() {
  const auth = getAuth();
  return _signOut(auth);
}
