import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import { APIContext } from '@platform/viewer';
import { redux, utils } from '@platform/core';
import {
  RoundedButtonGroup,
  useModal,
  useDialog,
  useSnackbar,
  useLogger,
} from '@platform/ui';
import RTCompute from './computes/RTCompute';
import { ViewportSection } from './sections/ViewportSection';
import { ToolSettingsSection } from './sections/ToolSettingsSection';
import { ROIContourSection } from './sections/ROIContourSection';
import FooterButton from './sections/FooterButton';
import { ConfirmDialog } from './forms/ConfirmDialog';
import { SubmitRTStructForm } from './forms/SubmitRTStructForm';
import SubjectReviewModal from '../LongitudinalStudy/SubjectReviewModal';
import dicom from '../../modules/sopClassHandlerModule/DicomRTStructSopClassHandler';
import * as commands from '../../tools/commands';

const { actions } = redux;

export default function RTEdit({
  studies,
  viewports,
  activeIndex,
  commandsManager,
  seriesStructureMap,
  structureSetUID,
}) {
  const [user, setUser] = useState({});
  const { pathname } = useLocation();
  const isLocal = pathname.split('/')[1] === 'local';
  const query = new URLSearchParams(window.location.search);
  const site_id = query.get('site') || '';
  const trial_id = query.get('trial') || '';
  const subject_id = query.get('subject') || '';
  const reader_id = query.get('reader') || '';
  const isReader = user.roles?.includes(`trial_editor_${trial_id}`);
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageSets = utils.getImageSetsFromStudies(studies);
  const axialImageSets = imageSets.filter((set) => set.viewType === 'axial');
  const defaultTab = structureSetUID ? 'roi-contours' : 'viewports';
  const tabOptions = structureSetUID
    ? [
        { value: 'roi-contours', label: 'ROI', labelFontSize: 12 },
        { value: 'settings', label: 'Settings', labelFontSize: 12 },
        { value: 'viewports', label: 'Viewports', labelFontSize: 12 },
      ]
    : [{ value: 'viewports', label: 'Viewports', labelFontSize: 12 }];
  /** contexts */
  const api = useContext(APIContext);
  /** states */
  const [interactings, setInteractings] = useState([]);
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAllDisplayed, setIsAllDisplayed] = useState(true);
  const [tabValue, setTabValue] = useState(defaultTab);
  const [ROIContours, setROIContours] = useState([]);
  const [selectedROIContour, setSelectedROIContour] = useState(null);
  const [selectedLinkID, setSelectedLinkID] = useState('');
  const [trial, setTrial] = useState({});
  const [snapshots, setSnapshots] = useState([]);
  const [timepoints, setTimepoints] = useState([]);
  const [links, setLinks] = useState([]);
  const [isLinking, setIsLinking] = useState(false);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const { isScrollSync } = useSelector((state) => state.extensions.cornerstone);
  const { isComputing } = useSelector((state) => state.extensions.dicomRT);
  const isTimepointCompleted = timepoints.some(
    (timepoint) =>
      timepoint.timepoint_id ===
        viewports[activeIndex].ClinicalTrialTimePointID &&
      timepoint.status === 'COMPLETED'
  );

  /** hooks */
  const dispatch = useDispatch();
  /** services */
  const modal = useModal();
  const dialog = useDialog();
  const snackbar = useSnackbar();
  const logger = useLogger();

  useEffect(() => {
    const newPainterCallback = function(evt) {
      const uid = evt.detail.uid;
      const newInteractings = interactings.filter((i) => i !== uid);
      newInteractings.push(uid);
      setInteractings(newInteractings);
    };
    editModule.addEventListener('new_painter', newPainterCallback);
    return () => {
      window.onbeforeunload = null;
      editModule.removeEventListener('new_painter', newPainterCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callback = () => refreshROIContours();
    editModule.addEventListener('refresh_roi_contours', callback);
    return () => {
      editModule.removeEventListener('refresh_roi_contours', callback);
    };
  }, [structureSetUID]);

  useEffect(() => {
    const callback = () => refreshSelectedROIContour();
    editModule.addEventListener('refresh_selected_roi', callback);
    return () => {
      editModule.removeEventListener('refresh_selected_roi', callback);
    };
  }, []);

  useEffect(() => {
    setTabValue(structureSetUID ? 'roi-contours' : 'viewports');
    const structureSet = editModule.getters.structureSet(structureSetUID);
    const length = structureSet?.ROIContours?.length;
    if (length || length === 0) {
      refreshROIContours();
      refreshSelectedROIContour();
      setIsAllDisplayed(structureSet.visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureSetUID]);

  useEffect(() => {
    if (selectedLinkID) {
      const { ROIContours } = editModule.getters.structureSet(structureSetUID);
      const rois = ROIContours.filter((roi) => roi.link_id === selectedLinkID);
      if (rois.length > 0) {
        onROISelect(rois[0], true);
      } else {
        onROISelect(null, false);
      }
    }
  }, [structureSetUID]);

  useEffect(() => {
    if (isLocal) return;
    (async () => {
      try {
        setUser(await api.getAuth());
      } catch (err) {
        snackbar.show({
          title: 'DICOM viewer',
          message: 'Invalid user',
          type: 'error',
          autoClose: false,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (isLocal) return;
    (async () => {
      if (!user._id) return;
      try {
        setTrial(await api.getTrial(site_id, trial_id));
      } catch (err) {
        snackbar.show({
          title: 'DICOM viewer',
          message: 'Failed to get trial protocol',
          type: 'error',
          autoClose: false,
        });
      }
      try {
        renderLinks();
      } catch (err) {
        snackbar.show({
          title: 'DICOM viewer',
          message: 'Failed to download links',
          type: 'error',
          autoClose: false,
        });
      }
      try {
        renderTimepoints();
      } catch (err) {
        snackbar.show({
          title: 'DICOM viewer',
          message: 'Failed to download timepoints',
          type: 'error',
          autoClose: false,
        });
      }
      try {
        renderSnapshots();
      } catch (err) {
        snackbar.show({
          title: 'DICOM viewer',
          message: 'Failed to download snapshots',
          type: 'error',
          autoClose: false,
        });
      }
    })();
  }, [user]);

  const renderLinks = async () => {
    if (reader_id === 'default') {
      setLinks([]);
    } else {
      const id = reader_id || user._id;
      const links = await api.getReaderLinks(trial_id, subject_id, id);
      setLinks(links);
      editModule.setters.links(links);
    }
  };

  const renderTimepoints = async () => {
    if (reader_id === 'default') {
      setTimepoints([]);
    } else {
      const id = reader_id || user._id;
      setTimepoints(await api.getReaderAssessments(trial_id, subject_id, id));
    }
  };

  const renderSnapshots = async () => {
    if (reader_id === 'default') {
      setSnapshots([]);
    } else {
      const id = reader_id || user._id;
      setSnapshots(await api.getApprovedSnapshots(trial_id, subject_id, id));
    }
  };

  const onCommand = function(name, opt) {
    commandsManager.runCommand(name, opt);
  };

  const onConfirm = ({ title, content, callback }) => {
    if (dialog) {
      let dialogId = dialog.create({
        content: ConfirmDialog,
        contentProps: {
          title: title,
          content: content,
          onClose: () => dialog.dismiss({ id: dialogId }),
          onSubmit: () => {
            callback();
            dialog.dismiss({ id: dialogId });
          },
        },
      });
    }
  };

  const refreshROIContours = () => {
    const { ROIContours } = editModule.getters.structureSet(structureSetUID);
    setROIContours([...ROIContours]);
  };

  const refreshSelectedROIContour = () => {
    const roi = editModule.getters.selectedROIContour();
    setSelectedROIContour(roi);
  };

  const createROI = (idx, roi) => {
    let colorArray = _.range(3).map((x) => Math.random());
    const colorMax = _.max(colorArray);
    colorArray = colorArray.map((x) => Math.ceil((x / colorMax) * 255));
    const ROINumber = new Date().getTime() * 10 + idx;

    window.onbeforeunload = (evt) => evt.preventDefault();
    const event = { detail: { uid: structureSetUID } };
    editModule.dispatchEvent(new CustomEvent('new_painter', event));

    return {
      ROIDescription: null,
      ROIGenerationAlgorithm: 'AUTOMATIC',
      ROIGenerationDescription: '',
      ROINumber,
      ROIName: roi.ROIName,
      RTROIObservations: {
        ObservationNumber: ROINumber,
        ROIInterpreter: null,
        ROIObservationDescription: null,
        RTROIInterpretedType: roi.RTROIObservations.RTROIInterpretedType,
      },
      colorArray,
      visible: true,
      link_id: roi.link_id,
      unequivocal: roi.unequivocal,
      too_small_to_measure: roi.too_small_to_measure,
    };
  };

  const copyROIContour = (ROIContour, idx) => {
    const roi = { ...ROIContour, ROIName: `${ROIContour.ROIName} copy` };
    const created = createROI(idx, roi);
    editModule.setters.pushROI(structureSetUID, created);
    editModule.setters.selectedROIContour(structureSetUID, created.ROINumber);
    for (const imageSet of axialImageSets) {
      for (const image of imageSet.images) {
        const imageId = image.getImageId();
        const p = editModule.getters.peekPainter(imageId);
        if (!p || p.structureSetSeriesInstanceUid !== structureSetUID) continue;
        const painter = editModule.setters.createPainter(
          'copy',
          imageId,
          structureSetUID,
          null,
          [ROIContour.ROINumber, created.ROINumber]
        );
        painter.commit();
      }
    }
    refreshROIContours();
    refreshSelectedROIContour();
  };

  const getNewROIName = () => {
    const maxROINum = ROIContours.reduce((max, contour) => {
      const match = /^ROI_(\S+)$/.exec(contour.ROIName);
      if (!match) return max;
      return Math.max(max, parseInt(match[1]));
    }, 0);
    const newNumber = maxROINum + 1;
    return `ROI_${newNumber}`;
  };

  const addNewROIContour = (rois) => {
    const created = [];
    for (let idx = 0; idx < rois.length; idx++) {
      const { ROIName } = rois[idx];
      const validated = validateROIName(ROIName);
      if (!validated) continue;
      const newROI = createROI(idx, rois[idx]);
      editModule.setters.pushROI(structureSetUID, newROI);
      created.push(newROI);
    }
    refreshROIContours();
    if (created[0]) {
      const number = created[0].ROINumber;
      editModule.setters.selectedROIContour(structureSetUID, number);
      refreshSelectedROIContour();
    }
    return created[0];
  };

  const addNewROIContourWithName = (name) => {
    const roi = {
      ROIName: name,
      RTROIObservations: { RTROIInterpretedType: 'GTV' },
    };
    const newROIContour = addNewROIContour([roi]);
    if (!newROIContour) return -1;
    return newROIContour.ROINumber;
  };

  const validateROIName = (name) => {
    const isExisting = _.some(ROIContours, (x) => x.ROIName === name);
    if (isExisting) {
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: `ROI ${name} already exists`,
        type: 'error',
        autoClose: false,
      });
      return false;
    }
    return true;
  };

  const navigateToLink = async (link_id, checkExisting = false) => {
    const highlights = [];
    const structureSets = editModule.getters.structureSets();
    for (const structureSet of structureSets) {
      const { SeriesInstanceUID: uid, ROIContours: rois } = structureSet;
      for (const roi of rois) {
        if (roi.link_id === link_id) {
          highlights.push({ SeriesInstanceUID: uid, ROINumber: roi.ROINumber });
          commands.checkAndNavigate(uid, roi.ROINumber);
        }
      }
    }
    editModule.setters.highlights(highlights);
    if (checkExisting && highlights.length === 0) {
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: "Can't find ROI in current viewports",
        type: 'warning',
        autoClose: false,
      });
    }
    dispatch(actions.setViewportActive(activeIndex));
  };

  const navigateToROIContour = async (ROIContour) => {
    const existing = isScrollSync
      ? commands.navigateToROIContour(
          viewports[activeIndex].SeriesInstanceUID,
          structureSetUID,
          ROIContour.ROINumber
        )
      : commands.checkAndNavigate(structureSetUID, ROIContour.ROINumber);
    if (!existing) {
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: "Can't find contours in current viewports",
        type: 'error',
        autoClose: false,
      });
      onROIUpdate(ROIContour, 'existing', false);
    }
    dispatch(actions.setViewportActive(activeIndex));
  };

  const onROIClick = (ROIContour) => {
    const deselect = ROIContour.ROINumber === selectedROIContour?.ROINumber;
    if (!deselect) {
      const existing = commands.checkExistenceOfROIContour(
        structureSetUID,
        ROIContour.ROINumber
      );
      onROIUpdate(ROIContour, 'existing', existing);
    }
    onROISelect(ROIContour, !deselect);
  };

  const onROISelect = (ROIContour, select = true) => {
    const number = select ? ROIContour?.ROINumber : null;
    editModule.setters.selectedROIContour(structureSetUID, number);
    editModule.setters.isEditable(!ROIContour?.too_small_to_measure);
    refreshSelectedROIContour();
    if (select) {
      const highlights = getROIHighlights(ROIContour);
      editModule.setters.highlights(highlights);
    } else {
      editModule.setters.highlights([]);
    }
  };

  const getROIHighlights = (roi) => {
    if (roi.link_id) {
      const highlights = [];
      const structureSets = editModule.getters.structureSets();
      for (const structureSet of structureSets) {
        const { SeriesInstanceUID: uid, ROIContours: rois } = structureSet;
        for (const _roi of rois) {
          if (roi.link_id === _roi.link_id) {
            highlights.push({
              SeriesInstanceUID: uid,
              ROINumber: _roi.ROINumber,
            });
          }
        }
      }
      if (highlights.length > 0) return highlights;
    }
    return [{ SeriesInstanceUID: structureSetUID, ROINumber: roi.ROINumber }];
  };

  const onROIUpdate = (ROIContour, key, value) => {
    if (key === 'ROIName') {
      const validated = validateROIName(value);
      if (!validated) return;
    }
    const newROIContour = { ...ROIContour, [key]: value };
    editModule.setters.updateROI(structureSetUID, newROIContour);
    if (key === 'too_small_to_measure') {
      editModule.setters.isEditable(!value);
    }
    commands.refreshViewport();
    refreshROIContours();

    window.onbeforeunload = (evt) => evt.preventDefault();
    const event = { detail: { uid: structureSetUID } };
    editModule.dispatchEvent(new CustomEvent('new_painter', event));
  };

  const onROIDelete = (ROIContour) => {
    editModule.setters.deleteROI(structureSetUID, ROIContour);
    commands.refreshViewport();
    refreshROIContours();

    window.onbeforeunload = (evt) => evt.preventDefault();
    const event = { detail: { uid: structureSetUID } };
    editModule.dispatchEvent(new CustomEvent('new_painter', event));
  };

  const onDisplayAllStatusChange = () => {
    ROIContours.forEach((roi) => {
      const _roi = _.cloneDeep(roi);
      _roi.visible = !isAllDisplayed;
      editModule.setters.updateROI(structureSetUID, _roi);
    });
    editModule.setters.structureSetVisible(structureSetUID, !isAllDisplayed);
    commands.refreshViewport();
    setIsAllDisplayed(!isAllDisplayed);
    refreshROIContours();
  };

  const onComputingStatusChange = (isComputing) => {
    if (isComputing) {
      editModule.setters.highlights([]);
      editModule.setters.selectedROIContour(null, null);
      refreshSelectedROIContour();
    }
    dispatch(actions.setIsComputing(isComputing));
  };

  const checkLinkWithTrialProtocol = (link, links) => {
    if (trial?.protocol?.guidelineId && link.type === 'TARGET') {
      const number = trial.protocol.maxNumberOfTargets;
      const number_per_organ = trial.protocol.maxNumberOfTargetsPerOrgan;
      const targets = links
        .filter((l) => l._id !== link._id)
        .filter((l) => l.type === 'TARGET');
      if (targets.length >= number) {
        snackbar.show({
          title: 'Links',
          message: `Target lesions should not exceed ${number} total`,
          type: 'error',
          autoClose: false,
        });
        return false;
      }
      const targetsPerOrgan = links
        .filter((l) => l._id !== link._id)
        .filter((l) => l.type === 'TARGET' && l.location === link.location);
      if (targetsPerOrgan.length >= number_per_organ) {
        snackbar.show({
          title: 'Links',
          message: `Target lesions should not exceed ${number_per_organ} lesions per organ`,
          type: 'error',
          autoClose: false,
        });
        return false;
      }
    }
    return true;
  };

  const onLinkCreate = async (type, location, description) => {
    const data = { type, location, description };
    if (checkLinkWithTrialProtocol(data, links)) {
      const link_id = await api.createReaderLink(trial_id, subject_id, data);
      await renderLinks();
      return link_id;
    }
    return null;
  };

  const onLinkUpdate = async (link) => {
    const title = 'Update Link';
    const content = `Are you sure to update ${link.name} to ${link.location} ${link.type} ${link.description}`;
    const callback = async () => {
      try {
        const data = {
          _id: link._id,
          type: link.type,
          location: link.location,
          description: link.description,
        };
        if (checkLinkWithTrialProtocol(data, links)) {
          await api.updateReaderLink(trial_id, subject_id, link._id, data);
          await renderLinks();
        }
      } catch (err) {
        snackbar.show({
          title: 'DICOM viewer',
          message: 'Failed to update link',
          type: 'error',
          autoClose: false,
        });
      }
    };
    onConfirm({ title, content, callback });
  };

  const onLinksSelect = (action, link) => {
    switch (action) {
      case 'add': {
        const _links = selectedLinks.filter((s) => s.id !== link.id);
        setSelectedLinks([..._links, link]);
        break;
      }
      case 'update': {
        const prev = selectedLinks.find((s) => s.id === link.id);
        if (prev) {
          const _links = selectedLinks.filter((s) => s.id !== link.id);
          setSelectedLinks([..._links, link]);
        }
        break;
      }
      case 'remove': {
        const _links = selectedLinks.filter((s) => s.id !== link.id);
        setSelectedLinks(_links);
        break;
      }
      default:
    }
  };

  const onLinksSubmit = async (confirmed) => {
    try {
      if (confirmed) {
        const prev_links = [...links];
        for (const link of selectedLinks) {
          const roi = ROIContours.find((roi) => roi.ROINumber === link.id);
          const data = {
            type: link.type,
            location: link.location,
            description: link.description,
          };
          if (checkLinkWithTrialProtocol(data, prev_links)) {
            const link_id = await api.createReaderLink(
              trial_id,
              subject_id,
              data
            );
            if (link_id) {
              onROIUpdate(roi, 'link_id', link_id);
              prev_links.push({ ...data, _id: link_id });
            }
          }
        }
        await renderLinks();
        setSelectedLinks([]);
      }
    } catch (err) {
      snackbar.show({
        title: 'DICOM viewer',
        message: 'Failed to create links',
        type: 'error',
        autoClose: false,
      });
    } finally {
      setIsLinking(false);
    }
  };

  const onTimepointLinkStatusChange = async (link_id, timepoint_id, status) => {
    try {
      const timepoint = timepoints.find((t) => t.timepoint_id === timepoint_id);
      const link_status = {
        ...timepoint.link_status,
        [link_id]: status,
      };
      await api.updateReaderLinkTimepointStatus(
        trial_id,
        subject_id,
        timepoint_id,
        { link_status }
      );
      renderTimepoints();
    } catch (err) {
      snackbar.show({
        title: 'DICOM viewer',
        message: 'Failed to update link status',
        type: 'error',
        autoClose: false,
      });
    }
  };

  const onReviewClick = () => {
    if (modal) {
      modal.show({
        content: SubjectReviewModal,
        title: 'Review',
        fullscreen: true,
        contentProps: {
          seriesStructureMap,
          seriesInstanceUID: viewports[activeIndex].SeriesInstanceUID,
          structureSetUID,
          imageSets: axialImageSets,
          links,
          timepoints,
          snapshots,
        },
        onClose: () => {},
      });
    }
  };

  /** outputs */
  const onSubmitClick = async () => {
    setIsSubmitOpen(true);
  };

  const handleSubmitClose = async (ok, input) => {
    if (ok) await submit(input);
    setIsSubmitOpen(false);
  };

  class SubmitError extends Error {
    constructor(message) {
      super(message);
      this.name = 'SubmitError';
      this.stack = new Error().stack;
    }
  }

  function checkEmptyROI(dataset) {
    const target_link_ids = links
      .filter((l) => l.type === 'TARGET')
      .map((l) => l._id);
    const empty = dataset.StructureSetROISequence.some((roi, index) => {
      if (dataset.ROIContourSequence[index]?.ContourSequence?.length === 0) {
        const description = roi.ROIDescription
          ? JSON.parse(roi.ROIDescription)
          : {};
        if (target_link_ids.includes(description.link_id)) {
          if (!description.too_small_to_measure) {
            return true;
          }
        }
      }
      return false;
    });
    return empty;
  }

  const submit = async (input) => {
    try {
      /** save */
      setIsSubmitting(true);
      const structureSet = {
        ...editModule.getters.structureSet(structureSetUID),
        StructureSetName: 'Vysioneer Inc',
        StructureSetLabel: 'Vysioneer Inc',
        SeriesDescription: input.SeriesDescription,
        ContentCreatorName: user.email,
      };
      const series_uid =
        structureSet.referencedSeriesSequence[0].SeriesInstanceUID;
      const imageSet = axialImageSets.find(
        (s) => s.SeriesInstanceUID === series_uid
      );
      const dataset = dicom.getRTStructDatasetWithMeasurement(
        imageSet,
        structureSet,
        links
      );
      if (checkEmptyROI(dataset)) {
        onConfirm({
          title: 'Save RTSTRUCT',
          content: `You have an empty ROI for the Target Lesions. Please review and update before saving the file.`,
          callback: () => {},
        });
        return;
      }
      let snapshot_id;
      try {
        const file = await dicom.getDICOMfile(dataset);
        snapshot_id = await api.uploadRTSTRUCT(trial_id, file);
      } catch (err) {
        throw new SubmitError('Failed to save DICOM RT structure set');
      }
      try {
        const id = reader_id || user._id;
        await api.approveRTSTRUCT(
          trial_id,
          subject_id,
          dataset.ClinicalTrialTimePointID,
          series_uid,
          snapshot_id,
          id
        );
      } catch (err) {
        throw new SubmitError('Failed to approve DICOM RT');
      }
      if (interactings.length > 0) {
        const _interactings = interactings.filter((i) => i !== structureSetUID);
        if (_interactings.length === 0) window.onbeforeunload = null;
        setInteractings(_interactings);
      }
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: 'The file has been saved successfully',
        type: 'success',
      });
    } catch (e) {
      switch (e.name) {
        default: {
          logger.error({
            event_type: 'Viewer:save',
            message: e.message,
            details: { error: e },
          });
          snackbar.show({
            title: 'DICOM RTSTRUCT',
            message: 'Failed to save DICOM RT structure set',
            type: 'error',
            autoClose: false,
          });
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  /** end outputs */

  return (
    <>
      <Header>
        <RoundedButtonGroup
          id={'rt-edit'}
          options={tabOptions}
          value={tabValue}
          onValueChanged={setTabValue}
        />
      </Header>
      <TabPanel value={tabValue} index={'viewports'}>
        {axialImageSets.length > 0 && (
          <ViewportSection viewports={viewports} activeIndex={activeIndex} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={'settings'}>
        <ToolSettingsSection />
      </TabPanel>
      <TabPanel value={tabValue} index={'roi-contours'}>
        {isComputing ? (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <RTCompute
              axialImageSets={axialImageSets}
              structureSetUID={structureSetUID}
              ROIContours={ROIContours}
              addNewROIContourWithName={addNewROIContourWithName}
              onROISelect={onROISelect}
              onROIDelete={onROIDelete}
              onCommand={onCommand}
              onFinished={() => onComputingStatusChange(false)}
            />
          </div>
        ) : (
          <ROIContourSection
            seriesStructureMap={seriesStructureMap}
            seriesInstanceUID={viewports[activeIndex].SeriesInstanceUID}
            structureSetUID={structureSetUID}
            axialImageSets={axialImageSets}
            onCommand={onCommand}
            onConfirm={onConfirm}
            ROIContours={ROIContours}
            selectedROIContour={selectedROIContour}
            getNewROIName={getNewROIName}
            addNewROIContour={addNewROIContour}
            copyROIContour={copyROIContour}
            navigateToROIContour={(roi) => {
              const { link_id } = roi;
              if (link_id && !isScrollSync) {
                const link = links.find((l) => l._id === link_id);
                if (link) navigateToLink(link_id);
              }
              navigateToROIContour(roi);
            }}
            onROISelect={onROISelect}
            onROIClick={onROIClick}
            onROIUpdate={onROIUpdate}
            onROIDelete={onROIDelete}
            isAllDisplayed={isAllDisplayed}
            onDisplayAllStatusChange={onDisplayAllStatusChange}
            onComputingStatusChange={onComputingStatusChange}
            trial={trial}
            timepoints={timepoints}
            snapshots={snapshots}
            links={links}
            selectedLinks={selectedLinks}
            selectedLinkID={selectedLinkID}
            setSelectedLinkID={setSelectedLinkID}
            isLinking={isLinking}
            onLinking={() => setIsLinking(true)}
            onLinkCreate={onLinkCreate}
            onLinkUpdate={onLinkUpdate}
            onLinksSelect={onLinksSelect}
            onLinksSubmit={onLinksSubmit}
            onTimepointLinkStatusChange={onTimepointLinkStatusChange}
            navigateToLink={(link) => {
              setSelectedLinkID(link._id);
              const rois = link.rois.filter(
                (roi) => roi.series_uid === structureSetUID
              );
              const roi = rois[0];
              roi ? onROISelect(roi, true) : onROISelect(null, false);
              navigateToLink(link._id, true);
              if (roi) navigateToROIContour(roi);
            }}
            isReading={isReader || links.length > 0}
            isBaselineCompleted={
              timepoints.find((t) => t.timepoint_id === 'BL')?.status ===
              'COMPLETED'
            }
            isTimepointCompleted={isTimepointCompleted}
          />
        )}
        {!isComputing && !isLinking && (
          <Footer>
            <FooterButton onClick={onReviewClick} disabled={isLocal}>
              Review
            </FooterButton>
            <FooterButton
              onClick={onSubmitClick}
              disabled={!isReader || isTimepointCompleted || isLocal}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </FooterButton>
          </Footer>
        )}
      </TabPanel>
      <SubmitRTStructForm open={isSubmitOpen} onClose={handleSubmitClose} />
    </>
  );
}
RTEdit.propTypes = {
  studies: PropTypes.array,
  structureSetUID: PropTypes.string,
  viewports: PropTypes.object,
  activeIndex: PropTypes.number,
  commandsManager: PropTypes.object,
};

const TabPanel = (props) => {
  const { children, value, index } = props;
  return value === index && children;
};
const Header = styled.div`
  margin: 0;
`;
const Footer = styled.div`
  margin: auto 0 0 0;
  padding: 5px 0 0;
`;
