import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';

export function init(config) {
  initializeApp(config);
  window.recaptchaVerifier = new RecaptchaVerifier(
    getAuth(),
    'recaptcha-root',
    { size: 'invisible' }
  );
}
