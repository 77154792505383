import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import TOOL_NAMES from './constants/toolNames';
import _ from 'lodash';
import { refreshViewport } from './commands';

// Cornerstone 3rd party dev kit imports
export const draw = importInternal('drawing/draw');
export const getNewContext = importInternal('drawing/getNewContext');
const BaseTool = importInternal('base/BaseTool');

/**
 * @class RTStructDisplayTool - Renders RTSTRUCT data in a read only manner (i.e. as an overlay).
 * @extends cornerstoneTools.BaseTool
 */
export default class PolygonTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      mixins: ['renderBrushMixin'],
      configuration: {},
      name: TOOL_NAMES.POLYGON_TOOL,
    };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps);

    this._module = cornerstoneTools.getModule('rtstruct-edit');
    this._isDrawing = false;
  }

  renderToolData(evt) {}

  _switchPainterMode(painterMode, element) {
    const imageId = cornerstone.getEnabledElement(element).image.imageId;
    const painter = this._module.getters.peekPainter(imageId);
    const newPainter = this._module.setters.createPainter(
      painterMode,
      imageId,
      painter._structureSetSeriesInstanceUid
    );
    return newPainter;
  }

  postTouchStartCallback(evt) {
    this.preMouseDownCallback(evt);
  }

  preMouseDownCallback(evt) {
    let painter;
    if (!this._isDrawing) {
      if (process.env.READ_ONLY) return;
      const isEditable = this._module.getters.isEditable();
      if (!isEditable) return;
      const selectedROINumber = this._module.getters.selectedROINumber();
      if (!_.isNumber(selectedROINumber)) return;

      const eventData = evt.detail;
      const { element } = eventData;
      painter = this._switchPainterMode('polygon', element);
      if (!painter) return;
      const uid = this._module.getters.selectedStructureSetUID();
      if (uid !== painter._structureSetSeriesInstanceUid) return;
      
      const ROIContour = this._module.getters.ROIContour(
        uid,
        selectedROINumber
      );
      const { ROIGenerationDescription } = ROIContour;
      if (ROIGenerationDescription === 'SearchRegion') {
        const toolState = cornerstoneTools.getToolState(element, 'stack');
        if (!toolState) return;
        const { imageIds } = toolState.data[0];
        for (const id of imageIds) {
          const previousPainter = this._module.getters.peekPainter(id);
          const previousData = previousPainter?.getState().data;
          const data = previousData.filter(
            (x) => x.ROINumber !== selectedROINumber
          );
          const painter = this._module.setters.createPainter('dummy', id, uid, {
            data,
            SeriesInstanceUID: uid,
          });
          painter.commit();
        }
        this._module.setters.updateROI(uid, {
          ...ROIContour,
          ROIGenerationDescription: '',
        });
        refreshViewport();
        const event = new CustomEvent('refresh_roi_contours', {});
        this._module.dispatchEvent(event);
        return;
      }

      this._isDrawing = true;
    } else {
      const element = evt.detail.element;
      painter = this.getPainter(element);
    }

    if (!painter || !painter.update(evt)) {
      painter.commit(evt);
      refreshViewport();
      this._isDrawing = false;
    }
  }

  passiveCallback(element) {
    if (this._isDrawing) {
      const imageId = cornerstone.getEnabledElement(element).image.imageId;
      this._module.setters.popPainter(imageId);
      refreshViewport();
      this._isDrawing = false;
    }
  }

  _paint(evt) {
    const element = evt.detail.element;
    const painter = this.getPainter(element);
    const changed = painter && painter.update(evt);
    if (changed) {
      refreshViewport();
    }
    return changed;
  }

  getPainter(element) {
    const imageId = cornerstone.getEnabledElement(element).image.imageId;
    return this._module.getters.peekPainter(imageId);
  }
}
