import getHotkeys from './getHotkeys';
import getPrefetcher from './getPrefetcher';

function getDICOMwebUrl() {
  const host = process.env.BACKEND_URL;
  const params =
    window.location.search && new URLSearchParams(window.location.search);
  const site_id = (params && params.get('site')) || '';
  const trial_id = (params && params.get('trial')) || '';
  return `${host}/api/v1/site/${site_id}/trial/${trial_id}/dicom-web`;
}

export default function config() {
  const root = getDICOMwebUrl();
  return {
    routerBasename: process.env.BASE_URL,
    homepageUrl: process.env.HOMEPAGE_URL,
    redirectUrl: process.env.REDIRECT_URL,
    firebaseConfig: {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      projectId: process.env.FIREBASE_PROJECT_ID,
      appId: process.env.FIREBASE_APP_ID,
      autoLogout: true,
    },
    servers: {
      dicomWeb: [
        {
          name: 'Google Healthcare API',
          imageRendering: 'wadors',
          thumbnailRendering: 'wadors',
          type: 'dicomWeb',
          active: true,
          wadoUriRoot: root,
          qidoRoot: root,
          wadoRoot: root,
          supportsFuzzyMatching: false,
          qidoSupportsIncludeField: false,
        },
      ],
    },
    studyPrefetcher: getPrefetcher({
      enabled: true,
      displaySetCount: 3,
      maxNumPrefetchRequests: 30,
    }),
    maxConcurrentMetadataRequests: 5,
    maxCacheSizeInBytes: 2 * 1024 * 1024 * 1024,
    hotkeys: getHotkeys(),
  };
}
