import { createContext } from 'react';
import { Firebase } from '@platform/core';

export class API {
  constructor() {
    this.host = process.env.BACKEND_URL;
  }

  async getIdToken() {
    const user = Firebase.getCurrentUser();
    const token = await user.getIdToken();
    return token;
  }

  async getResponseData(response) {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return await response.json();
    } else {
      return null;
    }
  }

  async handleError(res) {
    if (res.status >= 400 && res.status <= 500) {
      const data = await this.getResponseData(res);
      if (data?.detail) {
        throw new Error(data.detail);
      } else {
        if (res.status === 401) {
          throw new Error('Unauthorized');
        } else if (res.status === 403) {
          throw new Error('Forbidden');
        } else {
          throw new Error('Internal Server Error');
        }
      }
    }
  }

  async log(data) {
    const url = `${this.host}/api/v1/log`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
  }

  isEnabled() {
    return true;
  }

  _uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4();
  }

  async getAuth() {
    const url = `${this.host}/account/auth`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async createSignature() {
    const url = `${this.host}/account/signature`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async getUsers() {
    const url = `${this.host}/account/users`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async getUser(user_id) {
    const url = `${this.host}/account/user/${user_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async createUser(data) {
    const url = `${this.host}/account/user`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async updateUser(user_id, data) {
    const url = `${this.host}/account/user/${user_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteUser(user_id, data) {
    const url = `${this.host}/account/user/${user_id}/status`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getSites() {
    const url = `${this.host}/api/v1/sites`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async getSite(site_id) {
    const url = `${this.host}/api/v1/site/${site_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async createSite(data) {
    const url = `${this.host}/api/v1/site`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async updateSite(site_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteSite(site_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/status`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getTrials(site_id) {
    const url = site_id
      ? `${this.host}/api/v1/site/${site_id}/trials`
      : `${this.host}/api/v1/trials`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async getTrial(site_id, trial_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async createTrial(site_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async updateTrial(site_id, trial_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteTrial(site_id, trial_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/status`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getTrialUsers(trial_id) {
    const url = `${this.host}/account/trial/${trial_id}/users`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async createTrialUser(user_id, trial_id, data) {
    const url = `${this.host}/account/trial/${trial_id}/user/${user_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async updateTrialUser(user_id, trial_id, data) {
    const url = `${this.host}/account/trial/${trial_id}/user/${user_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteTrialUser(user_id, trial_id) {
    const url = `${this.host}/account/trial/${trial_id}/user/${user_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    return true;
  }

  async getSubjects(site_id, trial_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/subjects`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async getSubject(site_id, trial_id, subject_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/subject/${subject_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async getSubjectSeries(site_id, trial_id, subject_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/subject/${subject_id}/series`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async updateSeries(site_id, trial_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/series`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteSeries(site_id, trial_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/series`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async createTimepoint(site_id, trial_id, subject_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/subject/${subject_id}/timepoint`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteTimepoint(site_id, trial_id, subject_id, timepoint_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/subject/${subject_id}/timepoint/${timepoint_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    return true;
  }

  async downloadReports(trial_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/readers/${reader_id}/reports`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const filename = res.headers.get('Content-Disposition').split('"')[1];
    const blob = await res.blob();
    const _url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = _url;
    a.download = `${filename}.zip`;
    try {
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      a.remove();
    }
    return `${filename}.zip`;
  }

  async downloadSubjectReports(trial_id, reader_id, subject_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/readers/${reader_id}/subjects/${subject_id}/reports`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const filename = res.headers.get('Content-Disposition').split('"')[1];
    const blob = await res.blob();
    const _url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = _url;
    a.download = `${filename}.zip`;
    try {
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      a.remove();
    }
    return `${filename}.zip`;
  }

  async getReadingSubjects(trial_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/readers/${reader_id}/subjects`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async getReadingSubject(trial_id, subject_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/series`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async getReadingSubjectOfReader(trial_id, subject_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}/series`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async confirmReadingSubjectInfo(trial_id, subject_id, reader_id, info_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}/information/${info_id}/confirm`;
    const body = JSON.stringify({});
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getReadingTimepoint(trial_id, subject_id, timepoint_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/series`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async getReadingTimepointOfReader(
    trial_id,
    subject_id,
    timepoint_id,
    reader_id
  ) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/readers/${reader_id}/series`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async updateReadingTimepoint(
    trial_id,
    subject_id,
    timepoint_id,
    reader_id,
    data
  ) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/readers/${reader_id}/update_status`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async ConfirmReadingTimepoint(trial_id, subject_id, timepoint_id, data) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/confirm`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getReviews(trial_id, subject_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}/review`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async createReview(trial_id, subject_id, timepoint_id, data) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/review`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData._id;
  }

  async getReadingSnapshots(trial_id, subject_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}/snapshot`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async getApprovedSnapshots(trial_id, subject_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}/snapshot`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records.filter(
      (s) => s.modality === 'RTSTRUCT' && s.status === 'APPROVED'
    );
  }

  async updateReadingSnapshot(
    trial_id,
    subject_id,
    timepoint_id,
    series_uid,
    data
  ) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/snapshot/${series_uid}/update`;
    const query = `?status=${data.status}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url + query, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  sortLinks(links) {
    const customOrder = ['TARGET', 'NON-TARGET', 'NEW'];
    const orderMap = new Map(customOrder.map((item, index) => [item, index]));
    links.sort((a, b) => {
      const orderA =
        orderMap.get(a.type) !== undefined ? orderMap.get(a.type) : Infinity;
      const orderB =
        orderMap.get(b.type) !== undefined ? orderMap.get(b.type) : Infinity;
      return orderA - orderB;
    });
  }

  async getReaderLinks(trial_id, subject_id, reader_id) {
    const url = `${this.host}/api/v1/link/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    const links = resData.records;
    this.sortLinks(links);
    return links;
  }

  async createReaderLink(trial_id, subject_id, data) {
    const url = `${this.host}/api/v1/link/trials/${trial_id}/subjects/${subject_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData._id;
  }

  async updateReaderLink(trial_id, subject_id, link_id, data) {
    const url = `${this.host}/api/v1/link/trials/${trial_id}/subjects/${subject_id}/links/${link_id}`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async updateReaderLinkTimepointStatus(
    trial_id,
    subject_id,
    timepoint_id,
    data
  ) {
    const url = `${this.host}/api/v1/link/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/status`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getReaderAssessments(trial_id, subject_id, reader_id) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/readers/${reader_id}/assessment`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records;
  }

  async updateReaderAssessment(trial_id, subject_id, timepoint_id, data) {
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/subjects/${subject_id}/timepoints/${timepoint_id}/update_assessment`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async getWorkflows(page, site_id, trial_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/workflows?cursor=${page}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData;
  }

  async createWorkflow(site_id, trial_id, data) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/workflow`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async deleteWorkflow(site_id, trial_id, workflow_id) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/workflow/${workflow_id}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    return true;
  }

  async getTasks(site_id, trial_id) {
    const url = `${this.host}/api/v1/upload/sites/${site_id}/trials/${trial_id}/list`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.records.reverse();
  }

  async uploadFile(site_id, trial_id, file) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.host}/api/v1/upload/sites/${site_id}/trials/${trial_id}/zip`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
      body: formData,
    });
    await this.handleError(res);
    return true;
  }

  async uploadCsv(site_id, trial_id, file) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.host}/api/v1/upload/sites/${site_id}/trials/${trial_id}/csv`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
      body: formData,
    });
    await this.handleError(res);
    return true;
  }

  async uploadRTSTRUCT(trial_id, file) {
    const formData = new FormData();
    formData.append('rtstruct_file', file);
    const url = `${this.host}/api/v1/reading/trials/${trial_id}/rtstruct`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
      body: formData,
    });
    await this.handleError(res);
    const resData = await res.json();
    return resData.snapshot_id;
  }

  async approveRTSTRUCT(
    trial_id,
    subject_id,
    timepoint_id,
    series_uid,
    snapshot_id,
    reader_id
  ) {
    const reading = await this.getReadingSubjectOfReader(
      trial_id,
      subject_id,
      reader_id
    );
    const series = Object.values(reading).reduce((series, t) => {
      const _series = t.series || [];
      return [...series, ..._series];
    }, []);
    const snapshots = await this.getApprovedSnapshots(
      trial_id,
      subject_id,
      reader_id
    );
    for (const s of series) {
      if (s.modality === 'RTSTRUCT' && s.referenced_series_uid === series_uid) {
        const _snapshot = snapshots.find(
          (rt) => rt.series_uid === s.series_uid
        );
        if (_snapshot?._id) {
          await this.updateReadingSnapshot(
            trial_id,
            subject_id,
            _snapshot.timepoint_id,
            _snapshot._id,
            { status: 'UNAPPROVED' }
          );
        }
      }
    }
    if (snapshot_id) {
      await this.updateReadingSnapshot(
        trial_id,
        subject_id,
        timepoint_id,
        snapshot_id,
        { status: 'APPROVED' }
      );
    }
  }

  async uploadPostProcess(site_id, trial_id, upload_id) {
    const url = `${this.host}/api/v1/upload/sites/${site_id}/trials/${trial_id}/uploads/${upload_id}/task`;
    const body = JSON.stringify({});
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    await this.handleError(res);
    return true;
  }

  async downloadResult(site_id, trial_id, upload_id, filename) {
    const url = `${this.host}/api/v1/upload/sites/${site_id}/trials/${trial_id}/uploads/${upload_id}/results`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'x-token': `${token}`,
      },
    });
    await this.handleError(res);
    const blob = await res.blob();
    const _url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = _url;
    a.download = `upload_result_${filename}`
      .replace('.zip', '.json')
      .replace('.csv', '.json');
    try {
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      a.remove();
    }
  }

  async getSeries(site_id, trial_id, naturalized = true) {
    const url = `${this.host}/api/v1/site/${site_id}/trial/${trial_id}/dicom-web/series?limit=5000&offset=0&fuzzymatching=false&includefield=all`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'x-token': `${token}`,
      },
    });
    const series = await res.json();
    if (!series) return [];
    if (!naturalized) return series;
    const naturalizedSeries = series.map((s) => ({
      StudyDescription: _.get(s, ['00081030', 'Value', 0], ''),
      SeriesDescription: _.get(s, ['0008103E', 'Value', 0], ''),
      StudyInstanceUID: _.get(s, ['0020000D', 'Value', 0], ''),
      SeriesInstanceUID: _.get(s, ['0020000E', 'Value', 0], ''),
      SpecificCharacterSet: _.get(s, ['00080005', 'Value', 0], ''),
      StudyDate: _.get(s, ['00080020', 'Value', 0], ''),
      SeriesDate: _.get(s, ['00080021', 'Value', 0], ''),
      StudyTime: _.get(s, ['00080030', 'Value', 0], '').split('.')[0],
      SeriesTime: _.get(s, ['00080031', 'Value', 0], '').split('.')[0],
      TimezoneOffsetFromUTC: _.get(s, ['00080201', 'Value', 0], ''),
      AccessionNumber: _.get(s, ['00080050', 'Value', 0], ''),
      Modality: _.get(s, ['00080060', 'Value', 0], ''),
      PatientName: _.get(s, ['00100010', 'Value', 0, 'Alphabetic'], ''),
      PatientID: _.get(s, ['00100020', 'Value', 0], ''),
      PatientBirthDate: _.get(s, ['00100030', 'Value', 0], ''),
      PatientSex: _.get(s, ['00100040', 'Value', 0], ''),
      StudyID: _.get(s, ['00200010', 'Value', 0], ''),
      SeriesNumber: _.get(s, ['00200011', 'Value', 0], ''),
    }));
    return naturalizedSeries;
  }

  async segment(data) {
    const url = `${this.host}/api/v1/semi-automatic/contour`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'x-token': `${token}`,
      },
      body: body,
    });
    this.handleError(res);
    const resData = await this.getResponseData(res);
    const { mask_contour } = resData;
    return mask_contour;
  }
}

export const APIContext = createContext();
