import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
  Typography,
  Divider,
  Modal,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  H6,
} from '@platform/ui';

export function NewLinksForm({ links, onSubmit, disabled }) {
  const [open, setOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsProcessing(true);
      await onSubmit(true);
      setOpen(false);
    } catch (err) {
      window.alert(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: '5px',
          width: '100%',
        }}
      >
        <Button
          onClick={() => onSubmit(false)}
          color='secondary'
          variant='contained'
          fullWidth
        >
          Cancel
        </Button>
        <Button
          onClick={() => setOpen(true)}
          color='secondary'
          variant='contained'
          fullWidth
          disabled={links.length === 0}
        >
          <Icon
            className='plus-icon'
            name='plus'
            width='20px'
            height='20px'
            style={{
              color: disabled ? '#5A5A5A' : '#000000',
              background: 'transparent',
            }}
          />
          New Links
        </Button>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {'New Links'}
        </Typography>
        <Divider></Divider>
        <div style={{ width: '100%', height: '70%', overflowY: 'scroll' }}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow index={-1}>
                {[
                  ['Location', '30%'],
                  ['Type', '30%'],
                  ['Description', '40%'],
                ].map(([text, width], i) => (
                  <TableCell key={i} index={i} width={width} header={1}>
                    <H6>{text}</H6>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {links.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {[item.location, item.type, item.description].map((text, i) => (
                      <TableCell key={i} index={i}>
                        <H6>{text}</H6>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Button
            onClick={() => handleSubmit(true)}
            color='primary'
            variant='outlined'
            fullWidth
            disabled={isProcessing}
          >
            Add
          </Button>
        </div>
      </Modal>
    </>
  );
}
NewLinksForm.propTypes = {
  onFinished: PropTypes.func,
};
